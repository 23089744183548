<template>
  <div v-if="bus.activeProfile">


    <!-- I use here bus and activeProfile args because this component is reusable -->
    <entities-nav
      v-if="bus.activeProfile.is_owner"
      :profiles="profiles"
      :activeProfile="bus.activeProfile"
      class="inverted"
      @switch-profile="profile => navigateIdentity(profile, 'deeds')"
    />

        <!--- TODO output @handle as class:  -->
    <div v-if="bus.activeProfile.is_owner" class="identityWrapper" >
      <div :class="bus.activeProfile.name">




      <profile-main-bar :edit="edit" :profile-type="profileType" :bio="bio" />

      <div id="profileContainer" class="">
        <profile-nav
          :active-tab="activeTab"
          :is-owner="bus.activeProfile.is_owner"
        />
        <social-tab v-if="activeTab == 'social'" />
        <deeds-tab v-if="activeTab == 'deeds'" :profile="bus.activeProfile" />
        <words-tab v-if="activeTab == 'words' && wordSingle === null" />
        <word-single-display
          v-else-if="wordSingle !== null"
          :word-single="wordSingle"
        />
        <collateral-tab v-if="activeTab == 'collateral'" />
        <stats-tab v-if="activeTab == 'stats'" />
        <gigs-tab v-if="activeTab == 'gigs'" />
        <subscription-tab v-if="activeTab == 'subscription'" />
      </div>
      <!-- profileContainer ends -->

      <identity-tiers />

      <profile-edit
        :profile="bus.activeProfile"
        ref="profileEditModal"
        @logo-updated="getProfiles"
        v-if="profileEditModalActive"
      />
      <add-business-entity-modal
        ref="businessEntityModal"
        @context-created="getProfiles"
        v-if="false"
      />
    </div>
    </div>
<!--



  else



-->
    <div v-else>
      <!-- :class="{flex: entityType !== 'media'}"-->
      <div id="profileContainer">
        <profile-main-bar :edit="edit" :profile-type="profileType" :bio="bio" />
        <div class="right-col">
          <profile-nav :activeTab="activeTab" :entityType="entityType" />
            <!--<div class="empire" v-if="bus.activeProfile.meta_community == 'EMPIRE'">EMPIRE</div>
            <div class="republic" v-if="bus.activeProfile.meta_community == 'REPUBLIC'">REPUBLIC</div>
            <div class="city" v-if="bus.activeProfile.meta_community == 'CITY'">CITY</div>-->
          <social-tab v-if="activeTab == 'social'" />
          <deeds-tab v-if="activeTab == 'deeds'" :profile="bus.activeProfile" />
          <words-tab v-if="activeTab == 'words'" />
          <gigs-tab v-if="activeTab == 'gigs'" />
        </div>
      </div>
    </div>

    </div>
  </div>
</template>

<script>
import addBusinessEntityModal from '@/global/addBusinessEntityModal';
import deedsTab from './2deeds/deedsTab';
import entitiesNav from '../global/entitiesNav.vue';
import identityTiers from './_includes/identityTiers';
import profileNav from './_includes/profileNav';
import profileMainBar from './_profile/profileMainBar';
import profileEdit from './_profile/profileEdit';
import socialTab from './3social/socialTab';
import subscriptionTab from './_profile/subscription/subscriptionTab';
import wordsTab from './1words/wordsTab.vue';
import gigsTab from './6gigs/gigsUI.vue';
import collateralTab from './4collateral/collateralUi.vue';
import statsTab from './5stats/statsUi.vue';
import wordSingleDisplay from './1words/wordSingleDisplay';

import {identityBus} from './bus';
import Vue from 'vue';

export default {
  name: 'identityNode',
  components: {
    addBusinessEntityModal,
    profileNav,
    profileEdit,
    entitiesNav,
    profileMainBar,
    subscriptionTab,
    socialTab,
    deedsTab,
    wordsTab,
    collateralTab,
    statsTab,
    identityTiers,
    wordSingleDisplay,
    gigsTab
  },
  data() {
    return {
      activeTab: 'deeds',
      defaultState: 'deeds',
      bio: '',
      profileEditModalActive: false,
      edit: false,
      loader: null,
      profiles: [],
      profileType: '',
      balance_data: {
        paid_account: false,
        paid_by: '@greentender',
        currency: 'USD',
        subscription_title: 'free',
        period_pay: 20,
        period: 30,
      },
      bus: identityBus,
      wordSingle: null,  // @TODO: check if we really need it
      checkWordSingle: window.is_word_single
    };
  },
  computed: {
    /**@returns {String} */
    entityType() {
      return window.entity_type;
    },
    isWordSingle: {
      get: function() {
        return this.checkWordSingle
      },
      set: function() {
        return false;
      },
    },
  },
  watch: {
    $route: {
      handler() {
        this.getCurrentProfile();
      },
    },
  },
  mounted() {
    // listen for profile tab switching events
    this.bus.$on('navigate-identity', this.navigateIdentity);
    this.bus.$on('edit-profile', this.openProfileEditModal);

    // listen for account info (username, userpic etc) changes
    this.bus.$on('user-details-change', this.setUserDetails);
    this.bus.$on('show-entity-modal', this.showBusinessEntityModal);
    this.$root.$on('close-modals', this.closeModals);
    // call backend for the user's profiles (including entities)
    // switch to the default state on success
    this.getCurrentProfile().then(() => {
      if (this.bus.activeProfile.is_owner) {
        this.getProfiles();
      }
    });
    if (this.isWordSingle) {
      this.activeTab = 'words';
      this.wordSingle = window.word_single;
    }
  },
  methods: {
    openProfileEditModal() {
      // this.$refs.profileEditModal?.$el.classList.add('active');
      this.profileEditModalActive = true;
    },
    closeModals() {
      this.$refs.businessEntityModal?.$el.classList.remove('active');

      if (this.profileEditModalActive) {
        this.$refs.profileEditModal?.$el.classList.remove('active');
        this.profileEditModalActive = false;
      }
    },
    showBusinessEntityModal() {
      this.$refs.businessEntityModal?.$el.classList.add('active');
    },
    navigateIdentity(profile, tab = this.activeTab) {
      this.isWordSingle = false;
      this.wordSingle = null;
      if (typeof profile === 'undefined' && this.profiles.indexOf(profile) == -1) {
        profile = this.profiles.find(p => p.id == profile.id);
      }
      if (typeof profile !== 'undefined') {
        this.activeTab = tab;
        this.bus.activeProfile = profile;
        // set active tab, change browser address string accordingly
        const url = `/${profile.slug}`;
        if (window.location.pathname !== url) {
          this.$router.push(url);
        }
      }
    },
    getProfiles() {
      // call backend, ask for profiles associated with the given user

      return new Promise((resolve, reject) => {
        fetch(`${window.location.origin}/api/accounts/profiles/`)
          .then(res => {
            if (res.ok) return res.json();

            throw res;
          })
          .then(res => {
            if (!res) return reject();

            this.profiles = res.map(p => Vue.observable(p)).reverse();

            resolve();
          });
      });
    },
    getCurrentProfile() {
      return fetch(`/api/accounts/profiles/${this.$route.params.entitySlug}/`)
        .then(res => {
          if (res.ok) {
            return res.json();
          }
          throw res;
        })
        .then(res => {
          this.bus.activeProfile = res;
        });
    },
  },
};
</script>
