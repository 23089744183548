<template>
  <div>
    <div>
      <div id="time-tracker-wrap" style="margin:0 2rem">
        <a
          id="timeOptions"
          class="hm button core options mrgn0"
          @click.prevent="showTimeOptionsModal = true"
          >⌥ options</a
        >

        <div
          id="timeOptionsModal"
          class="hm modal options active"
          v-if="showTimeOptionsModal"
        >
          <div class="content" v-on-clickaway="closeModals">
            <h2 class="title">time options</h2>

            <div class="flex">
              <div class="">
                <p class="crimson">#context switcher</p>
              </div>
              <div class="w50">
                <h2>people tempo</h2>

                <div class="forms__radios">
                  <div class="form__radio active">
                    <label for="krType-1">5/2 office hours [legacy]</label>
                    <input id="krType-1" name="type" type="radio" value="" />
                  </div>
                  <div class="form__radio">
                    <label for="krType-1">async.</label>
                    <input id="krType-1" name="type" type="radio" value="" />
                  </div>
                  <div class="form__radio">
                    <label for="krType-1">dailies</label>
                    <input id="krType-1" name="type" type="radio" value="" />
                  </div>

                  <div class="form__radio">
                    <label for="krType-1">???</label>
                    <input id="krType-1" name="type" type="radio" value="" />
                  </div>
                </div>
                <!-- radio buttons end -->
              </div>

              <div class="w50">
                <h2>entity tempo</h2>

                <div class="forms__radios">
                  <div class="form__radio active">
                    <label for="krType-1">none</label>
                    <input id="krType-1" name="type" type="radio" value="" />
                  </div>
                  <div class="form__radio">
                    <label for="krType-1">product</label>
                    <input id="krType-1" name="type" type="radio" value="" />
                  </div>
                  <div class="form__radio">
                    <label for="krType-1">agency</label>
                    <input id="krType-1" name="type" type="radio" value="" />
                  </div>
                  <div class="form__radio">
                    <label for="krType-1">projects</label>
                    <input id="krType-1" name="type" type="radio" value="" />
                  </div>
                </div>
                <!-- radio buttons end -->
              </div>
            </div>
            <!-- flex ends -->
          </div>
          <!-- content ends -->
        </div>

        <div id="timeMenu" class="hm menu">
          <a
            v-for="tab in tabs"
            :key="tab.name"
            :class="[
              'item',
              {active: activeTab == tab.name, disabled: tab.disabled},
            ]"
            @click.prevent="
              () => {
                activeTab = tab.disabled ? activeTab : tab.name;
              }
            "
            ><i v-show="tab.disabled" class="clock outline icon"></i
            >{{ tab.name }}</a
          >

          <!--
        <a class="item active" href="/time">journal</a>
        <a class="item disabled"><i class="clock outline icon"></i>workload</a>
        <a class="item">economics</a>  rates, stats, etc
        --></div>

        <div
          class="time__container"
          style="margin-top: -1rem;margin-bottom: 4rem"
        >
          <div class="time__line"></div>
          <div class="time__line"></div>
          <div class="time__line"></div>
          <div class="time__line"></div>
          <div class="time__line"></div>
          <div class="time__line"></div>
          <div class="time__line"></div>
          <div class="time__line"></div>
          <div class="time__circle-container">
            <div class="time__circle"></div>
            <div class="time__circle"></div>
            <div class="time__circle"></div>
            <div class="time__center"></div>
          </div>
        </div>

        <div
          v-if="activeTab === 'journal'"
          id="timeJournalPeople"
          class="hm menu people"
        >
          <a
            :class="['item', {active: user.user_id == activeUser.user_id}]"
            v-for="user in employees"
            :key="user.user_id"
            @click="
              () => {
                activeUser = user;
              }
            "
          >
            <img :src="user.userpic" />
            <div class="vaHack">
              {{ user.user_id == me.user_id ? 'personal' : user.display_name }}
            </div>
          </a>
        </div>

        <a
          id="timeShareButton"
          v-if="activeTab === 'journal'"
          class="hm core button grin"
          @click.prevent="showShareTimeJurnalModal = true"
          >share &amp; invoice</a
        >

        <component
          :work-periods="workPeriodEntries"
          :is-loaded="isLoaded"
          :is="tabs.find(t => t.name == activeTab).component"
        />

        <div
          id="shareTimeJurnalModal"
          class="hm modal active"
          v-if="showShareTimeJurnalModal"
        >
          <div class="content" v-on-clickaway="closeModals">
            <h2 class="title">share &amp; invoice time journal</h2>

            <table class="hm table plain" style="margin:0 0 4rem 0">
              <thead>
                <tr>
                  <td class="title">commitment</td>
                  <td class="num">paid</td>
                  <td class="num">tracked</td>
                  <td class="num">invoiced</td>
                  <td class=""><h2>roster</h2></td>
                </tr>
              </thead>

              <tbody>
                <tr
                  v-for="commitment in commitments.accepted"
                  :key="commitment.id"
                >
                  <td>
                    <a
                      class="ui basic button"
                      @click="
                        event => {
                          copyLink(event, commitment.shared_link);
                        }
                      "
                      >copy link</a
                    >
                    <a class="hm link" :href="commitment.shared_link">
                      {{ commitment.title }}
                    </a>
                  </td>
                  <td>00h 00m</td>
                  <td v-text="commitment.duration_hrs.display"></td>
                  <td v-text="commitment.invoiced_hrs.display"></td>
                  <td>
                    <a
                      v-if="commitment.duration_hrs.value > 0"
                      class="hm button core moneta"
                      @click="openCombInvoice(commitment)"
                      >comb $XXX invoice</a
                    >
                  </td>
                </tr>
              </tbody>
            </table>

            <div style="border-top: 25px solid #23272B"></div>

            <table class="hm table plain" style="margin:0 0 4rem 0">
              <thead>
                <tr>
                  <td class="title">
                    <h2>
                      fulfilled <br />
                      commitments
                    </h2>
                  </td>
                  <td class="num">paid</td>
                  <td class="num">tracked</td>
                  <td class="num">invoiced</td>
                  <td class=""></td>
                </tr>
              </thead>
              <p v-if="!commitments.fulfilled.length">
                no fulfilled commitments yet
              </p>

              <tbody>
                <tr
                  v-for="commitment in commitments.fulfilled"
                  :key="commitment.id"
                >
                  <td>
                    <a
                      class="hm link"
                      href="/user-handle/time/2r892r8f0ucdscoj-hash"
                    >
                      {{ commitment.title }}
                    </a>
                  </td>
                  <td>XX hrs</td>
                  <td v-text="commitment.duration_hrs + ' hrs'"></td>
                  <td>XX hrs</td>
                  <td><a class="ui basic button">comb invoice</a></td>
                </tr>
              </tbody>
            </table>

            <div class="flex">
              <h2
                style="border-right: 30px solid #23272B;padding-right: 30px;margin-right: 30px"
              >
                archived <br />
                commitments
              </h2>
              <table class="hm table plain">
                <thead>
                  <tr>
                    <td class="title"></td>
                    <td class="num">paid</td>
                  </tr>
                </thead>
                <p v-if="!commitments.archived.length">
                  no archived commitments yet
                </p>

                <tbody>
                  <tr
                    v-for="commitment in commitments.archived"
                    :key="commitment.id"
                  >
                    <td>
                      <a
                        class="hm link"
                        href="/user-handle/time/2r892r8f0ucdscoj-hash"
                      >
                        {{ commitment.title }}
                      </a>
                    </td>
                    <td>XX hrs</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div id="comb-invoice" class="hm modal active" v-if="showCombInvoiceModal">
          <comb-invoice
            v-if="invoice_items.length"
            :rate="payment_value"
            :items.sync="invoice_items"
            :item="{
              id: offer_id,
              payment_value_currency: payment_value_currency,
              billed_from: billed_from,
              rate: payment_value,
            }"
          >
          </comb-invoice>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import timeTrackerJournal from './timeJournal';
import timeTrackerWorkload from './timeTrackerWorkload';
import timeTrackerReports from './timeTrackerReports';
import commitmentsCreateInvoice from '@/2relations/invoices/createInvoiceModal';

export default {
  name: 'timeTrackerNode',

  components: {
    journal: timeTrackerJournal,
    workload: timeTrackerWorkload,
    reports: timeTrackerReports,
    'comb-invoice': commitmentsCreateInvoice,
  },

  props: {},

  data() {
    return {
      workPeriodEntries: [],
      employees: [],
      activeUser: {},
      commitments: {accepted: [], fulfilled: [], archived: []},
      payment_value_currency: 'USD',
      payment_value: 100,
      invoice_items: [],
      offer_id: null,
      billed_from: {},
      me: {user_id: window.user_id, userpic: window.userpic},
      tabs: [
        {name: 'journal', component: timeTrackerJournal, disabled: false},
        {name: 'workload', component: timeTrackerWorkload, disabled: false},
        {name: 'reports', component: timeTrackerReports, disabled: false},
        // {name : 'economics', component: null, disabled : true},
        // { name: 'jobs to be filled', disabled: true },
        // { name: 'address book', disabled: true },
        // { name: 'my people', component: MyPeopleComponent },
        // { name: 'payroll & invoices', component: LayersPayrollComponent },
        // { name: 'jobs to be filled', component: JobsToBeFilledComponent },
      ],
      activeTab: 'journal',
      isLoaded: false,
      showTimeOptionsModal: false,
      showShareTimeJurnalModal: false,
      showCombInvoiceModal: false,
    };
  },
  computed: {
    apiUrl: function() {
      var url = window.apiRoot;
      url += '/api/';
      return url;
    },
  },
  watch: {
    activeUser: {
      handler() {
        this.getWorkPeriodEntries(this.activeUser.user_id);
      },
    },
  },
  mounted() {
    this.initComponent();
    this.$root.$on('close-modals', this.closeModals);
  },

  methods: {
    closeModals() {
      this.showTimeOptionsModal = false;
      this.showShareTimeJurnalModal = false;
      this.showCombInvoiceModal = false;
    },
    initComponent() {
      let url = this.apiUrl;
      url += 'relations/commitments/all-employees/timer/';
      this.getWorkPeriodEntries();
      this.getCommitments();
      this.employees.push((this.activeUser = this.me));
      fetch(url)
        .then(res => {
          if (res.ok) {
            return res.json();
          } else {
            throw res;
          }
        })
        .then(res => {
          if (!res) return;
          res.forEach(u => {
            if (!this.employees.find(_u => _u.user_id == u.user_id))
              this.employees.push(u);
          });
        });
    },
    getCommitments() {
      let url = this.apiUrl;
      url += 'relations/commitments/tracked';
      this.$http.get(url).then(res => {
        this.commitments = res.data;
      });
    },
    copyLink(e, link) {
      e.preventDefault();
      navigator.clipboard.writeText(window.location.origin + link).then(
        function() {
          console.log('Async: Copying to clipboard was successful!');
        },
        function(err) {
          console.error('Async: Could not copy text: ', err);
        },
      );
    },
    getWorkPeriodEntries: function(userId = null) {
      let url = this.apiUrl;
      url += 'timer/work-periods';
      this.$http
        .get(url + (userId ? `?user_id=${userId}` : ''))
        .then(response => {
          this.$forceUpdate();
          this.$nextTick(async () => {
            this.isLoaded = true;
            this.workPeriodEntries = response.data;
          });
        })
        .catch(error => {
          console.error('Timer api is unavailable at the moment', error);
        });
    },
    openCombInvoice(commitment) {
      this.offer_id = commitment.id;
      this.$forceUpdate();
      this.payment_value_currency = commitment.payment_value_currency;
      this.billed_from = commitment.billed_to;
      this.invoice_items = commitment.invoice_items;
      this.payment_value = commitment.payment_value;
      this.showCombInvoiceModal = true;
    },
  },
};
</script>
