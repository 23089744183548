<template>
  <div>
    <div
      class="hm modal big active"
      @click="() => ($parent.editInvoice = null)"
    >
      <div class="createInvoice content" @click.stop="" style="padding-left: 70px">
        <div id="monetaSidebarInvoice" style="">
          <div id="monetaLogo" class="flex start">
            <img
              src="/static/img/moneta2.png"
              width="30px"
              style="z-index:100"
            />
            <div class="circle"></div>
          </div>
        </div>

        <h2 class="title">Create invoice</h2>

        <div class="hm menu">
                  <a href="#" class="item active">Plain</a>
                  <a href="#" class="item">Time & Materials</a>
                  <a href="#" class="item disabled"><i class="clock outline icon"></i>Subscription</a>
                  <a href="#" class="item disabled"><i class="clock outline icon"></i>Products & Services</a>
        </div>

        <!-- <div class="section">
          <h3 class="muted">bill from</h3>

          if it's the first invoice: show textarea, otherwise just prev logged info
          <textarea v-model="invoice.billed_from_info" style="width:100%"></textarea>
          <p class="muted" style="padding: 0.3rem 0">
            We'll ask the person paying the invoice to fill out "bill to" with
            proper info, no worries.
          </p>
        </div>-->


        <!--<div class="section"></div>  section ends -->


        <!-- first grid ends -->

        <!---->

        <!-- this table illustrates how to showcase added items
              i.e. it's not visible initially-->

        <div class="section flex">


          <div class="sayWhat">
            <h3>what</h3>

            <div id="deedCover">

            </div>

            <a class="ui button mrgn0">upload cover</a>
          </div>

          <div style="width:100%; padding:2rem; border: 2px solid crimson">
            <table
              id="invoiceCreate"
              class="hm ui table grand"
              style="margin-bottom:2rem"
            >
              <thead>
                <tr>
                  <td>Item</td>
                  <td>Quantity</td>
                  <td>Rate</td>
                  <td>Amount</td>
                </tr>
              </thead>
              <tr v-for="(item, i) in invoice.items" :key="i">
                <td>
                  {{ item.name }} \
                  <a class="hm link" @click.stop.prevent="() => deleteItem(i)"
                    >delete this item</a
                  >
                </td>
                <td
                  v-text="!item.count_display ? item.count : item.count_display"
                ></td>
                <td>{{ item.rate ? item.rate : item.money }} {{ currency }}</td>
                <td v-if="item.rate">
                  {{ item.rate * item.count }} {{ currency }}
                </td>
                <td v-else>{{ item.money * item.count }} {{ currency }}</td>
              </tr>
              <!--<tr>
                      <td colspan="3" style="text-align:right"><a class="hm link">add tax</a></td>
                    </tr>-->
              <tr>
                <td colspan="4" style="text-align:right">
                  <h5>total: {{ totalCost }} {{ currency }}</h5>
                </td>
              </tr>
            </table>

            <div class="flex">
              <div>
                <input
                  type="text"
                  placeholder="Item name"
                  v-model="newItem.name"
                />
              </div>
              <div>
                <input
                  type="number"
                  placeholder="Qty"
                  v-model.number="newItem.count"
                  min="1"
                />
              </div>
              <div>
                <input
                  type="number"
                  placeholder="Rate"
                  v-model.number="newItem.money"
                />
              </div>
              <div>
                <button
                  :disabled="!newItem.name || typeof newItem.money != 'number'"
                  @click.prevent="addItem"
                  class="ui basic button"
                >
                  +
                </button>
              </div>
            </div>
          </div>
        </div>

        <!--<div class="section flex">

          <div class="sayWhat">
            <h3>delivery method</h3>
          </div>

          <div style="width:100%">
            link \ ray \ native? \ offline logistics
          </div>
        </div>-->



        <div id="createInvoiceSidebar">
          <!--<h3 class="muted">Due date</h3>

          <DatePicker
            v-model="invoice.due_at"
            :disabled-date="date => date <= new Date()"
            :append-to-body="false"
          ></DatePicker>

          <input
            id="invoiceDue"
            type="checkbox"
            v-model="invoice.dont_accept_after_due"
          />
          <label for="invoiceDue"
            >Don't accept payment after due date</label
          >-->

          <select class="ui fluid dropdown" v-model="currency">
            <option value="USD" selected>$$ US</option>
            <option value="RUB" selected>RUB</option>
          </select>

          <input type="checkbox"/>
          <label for="invoiceDue">n+1 sales</label>

          <input type="checkbox"/>
          <label for="invoiceDue">add cover</label>

          <input type="checkbox"/>
          <label for="invoiceDue">offline logistics</label>

          <input type="checkbox"/>
          <label for="invoiceDue">Help me with ads :D</label>

          <input type="checkbox"/>
          <label for="invoiceDue">Feature on my profile</label>

          <input type="checkbox"/>
          <label for="invoiceDue">Add alternative payment method</label>
        </div>

        <button
          class="hm button core"
          style="width:100%;margin-top:30px"
          v-on:click.prevent="storeInvoice"
          :disabled="!canCreate"
        >
          Create invoice
        </button>

        <h2 class="muted">
          here be dragons</h2>

        <p class="muted">Might be comming soon: embeds</p>

        <div class="section">
          <div class="vaHack">
            <p class="mrgn0">Trigger webhook on successful payment:</p>
          </div>
          <input type="text" placeholder="https://...." style="width:100%">
        </div>



        <!--<h2>memo</h2>

          <textarea name="name" rows="8" cols="80"></textarea>-->

        <!-- ok, so later on we'll feature email, SMS as well,
          but to save time let's do just direct link now -->

        <!--<ul>
            <li
              v-for="el in item"
              v-bind:key="el"
            >
              <strong>{{ Object.keys(item).find(key => item[key] === el) }}:</strong> {{ el }}
            </li>
          </ul>-->
      </div>
    </div>
  </div>
</template>
<script>
//import DatePicker from 'vue2-datepicker';

export default {
  name: 'commitmentsCreateInvoice',
  components: {
    //DatePicker,
  },
  props: ['item', 'items', 'money'],
  data() {
    return {
      commitmentsList: [],

      invoice: {
        offer: this.item,
        billed_from_info: '',
        items: this.items ? this.items : [],
        status: 'outstanding',
        due_at: new Date(new Date().getTime() + 7 * 24 * 60 * 60 * 1000),
        paid_at: '',
        archived_at: '',
        dont_accept_after_due: true,
        pub_date: '',
      },
      currency: this.item.payment_value_currency,
      newItem: {
        name: '',
        count: 1,
        money: this.money ? this.money : 100,
        money_currency: this.item.payment_value_currency,
      },
    };
  },
  computed: {
    csrfToken: function() {
      return window.csrftoken;
    },
    apiUrl: function() {
      var url = window.apiRoot;
      url += '/api/relations/invoices';
      return url;
    },
    totalCost() {
      return this.invoice.items.reduce((a, c) => a + c.money * c.count, 0);
    },
    canCreate() {
      for (let prop of ['billed_from_info', 'due_at', 'items']) {
        if (!this.invoice[prop] || this.invoice[prop].length === 0)
          return false;
      }

      return true;
    },
  },
  methods: {
    storeInvoice: function() {
      this.$http.defaults.headers.common['X-CSRFToken'] = this.csrfToken;
      const data = Object.assign({}, this.invoice);

      for (let idField of ['offer']) {
        data[idField + '_id'] = data[idField] && data[idField].id;
      }

      data.due_at = new Date(data.due_at).toISOString();

      const url = this.apiUrl;
      this.$http
        .post(url, data)
        .then(response => {
          this.$nextTick(async () => {
            if (response) {
              // @todo: switch to the events, will ya?
              this.$parent.editInvoice = null;
              window.location.href = '/relations/invoices/' + response.data.id;
              this.$forceUpdate();
            }
          });
        })
        .catch(error => {
          console.log('Invoices api is unavailable at the moment');
          console.log(error);
        });
    },
    deleteItem(index) {
      this.invoice.items.splice(index, 1);
    },
    addItem() {
      this.invoice.items.push(Object.assign({}, this.newItem));
      this.newItem = {
        name: '',
        count: 1,
        money: 100,
        money_currency: this.currency,
      };
    },
  },
  mounted() {
    fetch(`/api/relations/contexts/${this.item.billed_from.id}/billing-info`)
      .then(res => {
        if (!res.ok) {
          throw res;
        } else {
          return res.json();
        }
      })
      .then(res => {
        if (!res) return;

        this.invoice.billed_from_info = res.text;
      });
  },
};
</script>
<style scoped>
.modal {
  display: flex !important;
  position: fixed !important;
}
</style>
