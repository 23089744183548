<template>
  <div class="content">
      <h2 class="title">post</h2>
      <input
          type="text"
          placeholder="title"
          v-model="messageTitle"
      >
      <textarea
          name="name"
          style="min-height: 20vh; margin:2rem 0"
          placeholder="details"
          v-model="messageDescription"
      >
      </textarea>
      <a
          class="hm button core"
          style="float:right"
          @click.prevent="rayMercury"
      >
        ok. post
      </a>
  </div>

</template>
<script>
import Vue from 'vue';
import Loading from 'vue-loading-overlay';

Vue.use(Loading, { zIndex: 9999, })

export default {
  name: "phemeBuyModal",
  data() {
    return {
      messageTitle: '',
      messageDescription: '',
      mercuryId: '7lo9aKE',
    }
  },
  computed: {
    apiUrl: function () {
        var url = window.apiRoot
            + '/api/rays/direct/send/'
        return url
    }
  },
  methods: {
    rayMercury () {
      if(this.messageTitle == '') {
        return
      }
      this.loader = this.$loading.show()
      var data = {
        'user_to': this.mercuryId,
        'subject': this.messageTitle + ' @GPC',
        'body': this.messageDescription
      }
      this.$http.post(this.apiUrl, data).then(() => {
        document.querySelector('#GPCpostModal').classList.toggle('active')
        this.loader.hide()
      }).catch(error => {
        console.log(['Rays api is unavailable at the moment', error])
        document.querySelector('#GPCpostModal').classList.toggle('active')
        this.loader.hide()
      });

      this.messageTitle = ''
      this.messageDescription = ''
      this.requestCategory = 'media buying'
    }
  }
}
</script>
