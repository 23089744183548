<template>
  <div v-if="item.type==='card'" @click="setTaskId(item.id)"
    class="flex">

    <div>{{ item.name }}</div>
    <div class="vaHack">
      <span class="ticket">ticket @workspaces</span>
    </div>

  </div>
  <div v-else @click="setTaskId(null)" class="flex">
    <div>{{item.name}}</div>
    <div class="vaHack">
      <span class="entry">previous entry</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "searchItemTemplate",

  props: {
    item: {required: true},
    searchText: {required: true},
  },

  methods: {
    setTaskId(task_id){
      window.bus.cardTaskId = task_id
    }
  }
}
</script>
