import Vue from 'vue'

window.bus = new Vue({
    data: function () {
        return {
            timerTicking: false,
            cardTaskName: '',
            cardTaskId: null,
            currentTaskTime: null
        }
    }
});
