var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"sidebar"}},[_c('div',{attrs:{"id":"myGoodSelf"}},[(_vm.impersonate_accounts !== false)?_c('div',{staticClass:"ui dropdown icon item",attrs:{"id":"personificationsHandle"},on:{"click":function($event){$event.preventDefault();return _vm.toggleImpersonationMenu.apply(null, arguments)}}},[_c('i',{staticClass:"icon-user"})]):_vm._e(),(_vm.showImpersonationMenu)?_c('div',{staticClass:"menu",attrs:{"id":"personificationsMenu"}},[_vm._l((_vm.impersonate_accounts),function(item){return _c('div',{key:item.phone_number,staticClass:"item"},[_c('a',{attrs:{"href":'/profile/impersonate/' + item.phone_number + '?redirect_to=' + _vm.redirectTo}},[_vm._v(" "+_vm._s(item.username)+" ")])])}),_vm._m(0)],2):_vm._e(),_c('router-link',{attrs:{"to":'/'+_vm.$handle,"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
return [_c('a',{attrs:{"href":'/'+_vm.$handle},on:{"click":navigate}},[_c('img',{staticClass:"identity__img",attrs:{"src":_vm.$userpicScaled}})])]}}])}),_c('router-link',{attrs:{"to":'/'+_vm.$handle,"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
return [_c('a',{staticClass:"account__status",class:_vm.$accountStatus,attrs:{"href":'/'+_vm.$handle},on:{"click":navigate}},[_vm._v(" "+_vm._s(_vm.$accountStatus)+" ")])]}}])})],1),_c('div',{attrs:{"id":"items"}},[_c('router-link',{attrs:{"to":"/rays","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('a',{attrs:{"href":"/rays"}},[_c('div',{staticClass:"item",class:[ isActive && 'active', isExactActive && 'active'],attrs:{"id":"tabRays"},on:{"click":navigate}},[_c('span',{staticClass:"hot-key"},[_vm._v("or press 1")]),_c('div',{staticClass:"notifications-count"},[_vm._v(_vm._s(_vm.rays_count))]),_c('del',[_vm._v("rays")])])])]}}])}),_c('router-link',{attrs:{"to":"/teleport","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('a',{attrs:{"href":"/teleport"}},[_c('div',{staticClass:"item",class:[ isActive && 'active', isExactActive && 'active'],on:{"click":navigate}},[_c('span',{staticClass:"hot-key"},[_vm._v("2")]),(_vm.$root.teleportBus)?_c('div',{staticClass:"notifications-count",attrs:{"id":"teleport-notification"}},[_vm._v(" "+_vm._s(_vm.$root.teleportBus.rooms.filter(function (r) { return r.unreadCount; }).length)+" ")]):_vm._e(),_vm._v(" teleport ")]),_c('audio',{staticStyle:{"position":"absolute","opacity":"0"},attrs:{"id":"new-teleport-message","src":"/static/app/audio/message.wav"}})])]}}])}),_c('router-link',{attrs:{"to":"/mail","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('a',{attrs:{"href":"/mail"}},[_c('div',{staticClass:"item",class:[ isActive && 'active', isExactActive && 'active'],on:{"click":navigate}},[_c('span',{staticClass:"hot-key"},[_vm._v("3")]),_c('div',{staticClass:"notifications-count"},[_vm._v("99")]),_c('del',[_vm._v("mail")])])])]}}])}),_c('router-link',{attrs:{"to":"/schedule","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('a',{attrs:{"href":"/schedule"}},[_c('div',{staticClass:"item",class:[ isActive && 'active', isExactActive && 'active'],on:{"click":navigate}},[_c('span',{staticClass:"hot-key"},[_vm._v("4")]),_c('div',{staticClass:"notifications-count"},[_vm._v(_vm._s(_vm.meets_count))]),_c('del',[_vm._v("schedule")])])])]}}])}),_vm._m(1),_c('a',{staticClass:"sidebarAlso button",on:{"click":function($event){$event.preventDefault();return _vm.toggleHowToPlayModal()}}},[_vm._v(" how to play")])],1),_c('div',{attrs:{"id":"timeTracker"}},[_c('time-tracker-sidebar'),_c('time-tracker-modal')],1),_c('div',{attrs:{"id":"pagination"},on:{"mouseenter":function () { _vm.showCursor = true },"mouseleave":function () { _vm.showCursor = false },"mousemove":_vm.followCursor}},_vm._l((_vm.arrowsDots),function(ad,i){return _c('router-link',{key:i,attrs:{"to":ad.link,"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
return [_c('a',{class:[{ horizontal: ad.horizontal }, {active: _vm.activeArrowDotIndex === i }, 'flex'],attrs:{"href":ad.link}},[_c('button',{class:{ active: _vm.activeArrowDotIndex == i },attrs:{"id":ad.id,"role":"link"},on:{"click":navigate}}),_c('span',{staticClass:"arrow-dot-label"},[_vm._v(_vm._s(ad.label || ad.id))])])]}}],null,true)})}),1),(_vm.showCursor)?_c('div',{style:({ left: _vm.cursorCoords.left + 'px', top: _vm.cursorCoords.top + 'px' }),attrs:{"id":"pagination-cursor"}}):_vm._e()])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"item"},[_c('a',{attrs:{"href":"/logout"}},[_vm._v("[logout]")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a',{attrs:{"id":"magic1_start_link"}},[_c('div',{staticClass:"item"},[_c('span',{staticClass:"hot-key"},[_vm._v("5")]),_c('del',[_vm._v("magic1")])])])}]

export { render, staticRenderFns }