<template>
  <div v-if="showModal == true">
    <div class="hm modal active" id="ttrackerModal">
      <div id="timetracker-modal">
        <div class="content" v-on-clickaway="closeModals">
          <h2 class="title">log time entry</h2>
          <div id="trackerEntry">
            <div class="flex">
              <div class="">
                <div
                  class="form__radios horizontal"
                  style="margin-bottom: 1rem"
                >
                  <div
                    class="form__radio"
                    v-for="item in contextsList"
                    :key="item.id"
                    :value="item"
                  >
                    <label
                      :for="'item-' + item.id"
                      v-html="item.foreign.name"
                    ></label>
                    <input
                      name="type"
                      type="radio"
                      :id="'item-' + item.id"
                      :value="item.id"
                      v-model="selectedContext"
                    />
                  </div>
                </div>
                <div
                  class="form__radios horizontal"
                  style="margin-bottom: 1rem"
                >
                  <div
                    class="form__radio"
                    v-for="item in commitmentsList.filter(cm =>
                      filterCommitment(cm),
                    )"
                    :key="item.id"
                  >
                    <!-- <div -->
                    <!--   class="form__radio" -->
                    <!--   v-for="item in commitmentsList.filter(cm => -->
                    <!--     filterCommitment(cm), -->
                    <!--   )" -->
                    <!--   :key="item.id" -->
                    <!-- > -->
                    <label :for="'item-' + item.id" v-html="item.title"></label>
                    <input
                      :id="'item-' + item.id"
                      :value="item.id"
                      name="type"
                      type="radio"
                      v-model="selectedCommitment"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div class="flex">
              <div class="vaHack" style="padding-right: 10px; width: 5rem">
                <strong v-html="currentTaskTime"></strong>
              </div>
              <div class="ui input vaHack" style="width:100%">
                <!-- TODO it's super important to cache and live search previous entries -->
                <!-- <input
                class="title"
                type="text"
                v-model="timeEntryTitleStr"
                > -->
                <Autocomplete
                  :component-item="template"
                  :get-label="getLabel"
                  :items="cards_to_search"
                  :min-len="0"
                  :value.sync="item_value"
                  :wait="100"
                  @change="
                    e => {
                      this.item_value = e;
                    }
                  "
                  @update-items="updateCommentsToSelect"
                  auto-select-one-item
                  v-model="item"
                ></Autocomplete>
              </div>
              <div class="vaHack" style="min-width: 150px;padding-left: 10px">
                <div class="form__radios">
                  <div class="form__radio">
                    <label for="date-sort">billable</label>
                    <input
                      type="radio"
                      tabindex="0"
                      name="billable"
                      checked="checked"
                      v-bind:value="true"
                      v-model="isBillable"
                    />
                  </div>
                  <div class="form__radio">
                    <label for="group-sort">non billable</label>
                    <input
                      type="radio"
                      name="billable"
                      tabindex="0"
                      v-bind:value="false"
                      v-model="isBillable"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div class="flex">
              <div>
                <a class="ui basic button" v-on:click="doNotLogTime"
                  >nevermind. don't log this one</a
                >
              </div>
              <div>
                <a
                  class="hm button core grin"
                  id="timeTrackerSubmit"
                  v-on:click="logTime"
                  :disabled="!canSave"
                  >ok. log</a
                >
              </div>
            </div>
          </div>

          <!--<div class="ui secondary menu secondary-navigation" style="padding-left:0;padding-right: 0">
            <a class="item" style="padding-left: 0 !important;margin-left:0"
            href="/time">journal</a>
            <a class="item">the schedule</a>


            <div class="right menu">

              <a class="item" target="_blank"
              href="https://odyssey.holons.me/en/nodes/fairchild-layers/time-tracker">
              <i class="square full icon"></i>the why</a>
            </div>
        </div>

        <h2>you worked <span v-html="timeSpentTodayUpdated"></span> hours today</h2>
        <p>seems like you are ahead\behind the schedule</p>-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import Loading from 'vue-loading-overlay';

import Autocomplete from 'v-autocomplete';
import 'v-autocomplete/dist/v-autocomplete.css';
import searchItemTemplate from './_searchItemTemplate';

Vue.use(Loading, {
  zIndex: 9999,
});

export default {
  name: 'timeTrackerModal',

  components: {
    Autocomplete,
    // eslint-disable-next-line
    searchItemTemplate,
  },

  props: {},

  data() {
    return {
      contextsList: [],
      commitmentsList: [
        {
          title: 'Create commitments via Relations/Offers',
        },
      ],
      selectedContext: 0,
      selectedCommitment: null,
      timeEntryStatus: 'billed',
      isBillable: false,
      showModal: false,
      timeSpentTodayUpdated: '',
      timeSpentCurrentTaskUpdated: '',
      timeEntryTitleStr: '',
      selectedTimeEntryTitleStr: '',
      cachedComments: [],
      commentsToSelect: [],
      template: searchItemTemplate,
      cards: [],
      cards_to_search: [],
      item: {name: '', value: ''},
      item_value: '',
      last_comment: '',
    };
  },
  watch: {
    selectedContext: {
      handler() {
        if (this.selectedContext && this.selectedContext.id)
          this.selectedCommitment = this.currentContextCommitmentsList[0]
            ? this.currentContextCommitmentsList[0].id
            : null;
      },
    },
  },
  computed: {
    apiUrl: function() {
      var url = window.apiRoot;
      url += '/api/';
      return url;
    },
    cardTaskName: {
      get: function() {
        return window.bus.cardTaskName;
      },

      set: function(val) {
        window.bus.cardTaskName = val;
      },
    },
    timeEntryTitle: function() {
      return window.time_entry_title;
    },
    currentTaskTime: function() {
      return window.bus.currentTaskTime;
    },
    timeSpentToday: function() {
      return window.user_timer;
    },
    userHandle: function() {
      return window.handle;
    },
    currentContextCommitmentsList() {
      return this.commitmentsList.filter(
        c =>
          c.sender &&
          this.selectedContext &&
          c.sender.id === this.selectedContext.id,
      );
    },
    canSave() {
      return (
        this.selectedCommitment &&
        this.selectedContext &&
        this.selectedContext.id
      );
    },
  },
  mounted() {
    window.bus.$on('log-timer-entry', () => this.logTime());
    window.bus.$on('show-timer-modal', timeEntryTitleStr =>
      this.showTimerModal(timeEntryTitleStr),
    );
    window.bus.$on('update-timer-value', (tsCurrentTask, tsTodayTotal) =>
      this.updateTimeSpentToday(tsCurrentTask, tsTodayTotal),
    );
    this.$root.$on('close-modals', this.closeModals)
    // this.getCommitments();
    // this.getBusinessEntities()
  },

  methods: {
    closeModals() {
      this.showModal = false;
    },
    filterCommitment(commitment) {
      console.log(commitment);
      console.log('this.selectedContext', this.selectedContext);
      if (this.selectedContext != 0) {
        var localContext = this.contextsList.filter(context => {
          console.log(
            'context',
            context,
            'this.selectedContext',
            this.selectedContext,
          );
          return context.id == this.selectedContext;
        });
        console.log('this.selectedContext', this.selectedContext);
        return commitment.billed_to.foreign.id == localContext[0].foreign.id;
      }
      return false;
    },
    getLabel(item) {
      return item ? item.name : this.item_value;
    },
    searchCards() {
      this.$http.get(this.apiUrl + 'timer/cards/').then(response => {
        this.$forceUpdate();
        this.cards = this.cards_to_search = response.data.items;
        this.item_value = response.data.last_comment;
        this.item = {name: response.data.last_comment};
      });
    },
    showTimerModal(timeEntryTitleStr = '') {
      this.getCommitments();
      this.searchCards();
      if (timeEntryTitleStr === '') {
        this.timeEntryTitleStr = this.timeEntryTitle;
      } else {
        this.selectedTimeEntryTitleStr = this.cardTaskName;
      }
      this.showModal = true;
      this.$forceUpdate();
    },
    getCommitments() {
      // Yes, we don't give a flying fuck about DRY principle
      // @todo: import this thingie from relations UI
      this.$http
        .get(this.apiUrl + 'relations/commitments/accepted')
        .then(response => {
          // this.$forceUpdate();
          this.$nextTick(async () => {
            // this.$forceUpdate();
            this.commitmentsList = response.data.data.filter(
              c => c.is_accepted,
            );
            console.log('this.commitmentsList', this.commitmentsList);

            for (let commitment of this.commitmentsList) {
              if (commitment.contract_type === 'for_owner') {
                commitment.title = 'work for yourself';
              }

              if (
                commitment.sender &&
                !this.contextsList.find(c => c.id === commitment.sender.id)
              )
                this.contextsList.push(commitment.sender);
            }

            if (!this.selectedContext || !this.selectedContext.id)
              this.selectedContext =
                this.contextsList.find(c => c.foreign.name === window.handle)
                  .id || {};
          });
        })
        .then(() => {
          this.$forceUpdate();
        })
        .catch(error => {
          console.error('Commitments api is unavailable at the moment', error);
        });
    },

    logTime() {
      let data = {
        context_id: this.selectedContext,
        commitment: this.selectedCommitment,
        comment: this.item ? this.item.name : this.item_value,
        is_billable: this.isBillable,
        card_id: window.bus.cardTaskId,
      };
      this.$http
        .post(this.apiUrl + 'timer/stop', data)
        .then(response => {
          this.item = {};
          this.$forceUpdate();
          this.$nextTick(async () => {
            window.bus.$emit('switch-timer-indicator-off', response.data.data);
            this.cardTaskName = '';
            window.bus.cardTaskId = null;
          });
        })
        .catch(error => {
          console.error('Timer api is unavailable at the moment', error);
        });
      if (!this.item_value.length) {
        // @TODO: rewrite it using Vue
        document
          .querySelector('.v-autocomplete-input')
          .setCustomValidity('Input is required');
        document.querySelector('.v-autocomplete-input').reportValidity();
        document.querySelector('.v-autocomplete-input').setCustomValidity('');
      } else this.closeModals();
    },
    doNotLogTime() {
      this.$http
        .delete(this.apiUrl + 'timer/cancel')
        .then(response => {
          this.$forceUpdate();
          this.$nextTick(async () => {
            window.bus.$emit('switch-timer-indicator-off', response.data.data);
          });
        })
        .catch(error => {
          console.error('Timer api is unavailable at the moment', error);
        });
      this.closeModals();
    },
    updateTimeSpentToday(tsCurrentTask, tsTodayTotal) {
      this.timeSpentCurrentTaskUpdated = tsCurrentTask;
      this.timeSpentTodayUpdated = tsTodayTotal;
    },
    updateCommentsToSelect(text) {
      this.cards_to_search = this.cards.filter(u => u.name.includes(text));
    },
  },
};
</script>

<style lang="css">
.v-autocomplete {
  width: 100%;
}

.v-autocomplete-input {
  width: 100% !important;
  margin: 0;
  max-width: 100%;
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  outline: 0;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  text-align: left;
  line-height: 1.21428571em;
  font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
  padding: 0.67857143em 1em;
  background: #fff;
  border: 1px solid rgba(34, 36, 38, 0.15);
  color: rgba(0, 0, 0, 0.87);
  border-radius: 0.28571429rem;
  -webkit-transition: border-color 0.1s ease, -webkit-box-shadow 0.1s ease;
  transition: border-color 0.1s ease, -webkit-box-shadow 0.1s ease;
  transition: box-shadow 0.1s ease, border-color 0.1s ease;
  transition: box-shadow 0.1s ease, border-color 0.1s ease,
    -webkit-box-shadow 0.1s ease;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.v-autocomplete-list {
  background-color: #fff !important;
  z-index: 10 !important;
}
</style>
