<template id="">
  <div id="context" class="hm menu">
    <div class="vaHack">
      <a
        class="ui button"
        v-if="editable"
        @click.stop="() => bus.$emit('show-entity-modal')"
      >
        + new entity
      </a>
    </div>
    <div
      class="item flex start"
      :class="[
        { active: (byContexts ? getProfileForContext(activeContext) : activeProfile).slug === profile.slug },
      ]"
      v-for="profile in filteredProfiles"
      v-bind:key="profile.id"
      @click="(evt) => emitSwitch(profile, evt)"
    >

      <img
        v-if="profile && profile.logo != ''"
        :src="profile.logo"
      />

      <div class="vaHack" v-if="profile.logo == ''">
        <a
          v-if="profile.slug === $handle"
          >my profile</a>
        <a
          v-else
          v-html="profile.name"
        ></a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'entitiesNav',
  props: {
    profiles: {
      type: Array,
      /**@returns {Array} */
      default: () => []
    },
    contexts: {
      type: Array,
      /**@returns {Array} */
      default: () => []
    },
    bus: {
      type: Object,
      /**@returns {Object} */
      default: function(){ return this }
    },
    activeProfile: {
      type: Object,
      /**@returns {Object} */
      default: () => ({})
    },
    activeContext: {
      type: Object,
      /**@returns {Object} */
      default: () => ({})
    },
    editable: {
      type: Boolean,
      default: true
    },
    byContexts: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    filteredProfiles: {
      /**@returns {Array} */
      get(){
        return this.byContexts ? this.profiles.filter(
          p => this.contexts.find(c => c.id == p.context_id)
        ) : this.profiles
      },
      // cache: false
    }
  },
  methods: {
    getProfiles(){
      fetch('/api/accounts/profiles/')
        .then(res => { if(res.ok){ return res.json() } throw res })
        .then(res => {
          if(!res) return

          this.profiles.push(...res)
        })
    },

    emitSwitch(profile,){
      this.bus.$emit(
        'switch-profile',
        this.byContexts ? this.getContextForProfile(profile) : profile
      )
    },

    getProfileForContext(context){
      return this.profiles.find(p => p.context_id == context.id)
    },
    getContextForProfile(profile){
      return this.contexts.find(c => profile.context_id == c.id)
    }
  },
  mounted() {
    if(this.byContexts){
      this.getProfiles()
    }
  },
};
</script>
