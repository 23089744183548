import Vue from "vue";

// TODO: move here all business logic stuff  
export const identityBus = new Vue({
    data(){
        return {
            activeProfile: null,
            activeTab: 'deeds'
        }
    },
})