<template>
  <div id="ratesPreview" class="drawer" :class="{'is-open': showBrokerDrawer}">
    <div class="wrapper">
      <!--<a data-drawer="#ratesPreview"
          class="hm link">Close</a>-->

      <div class="flex start">
        <fora-animation />

        <div class="vaHack">
          <div class="hm menu">
            <a class="item active">New Order</a>
            <a class="item classic">Rates &amp; Pricing Discovery</a>
            <a class="item classic">Assets</a>
            <!--
                  including Equity's Portfolio, Atoms Exchange -->
            <a class="item gold">Supply &amp; Demand Series</a>
            <a class="item classic">atoms markets</a>
          </div>
        </div>
      </div>

      <div class="" style="margin-left: 5rem">
        <table id="bIsForBroker" class="hm table plain">
          <tbody>
            <tr>
              <td>
                <h2>Talent Augmentation Broker</h2>
              </td>
              <td>
                <a
                  @click.prevent="showJobModal = true"
                  class="hm button core w100"
                  >recruit</a
                >
                <!--<a onclick="document.querySelector('#sellDummyModal').classList.toggle('active')"
                    class="ui basic button">sell</a>-->
              </td>
            </tr>

            <tr>
              <td>
                <h2>Pheme</h2>
                <p class="muted mrgn0">Attention marketplace.</p>
              </td>
              <td class="flex">
                <div class="vaHack">
                  <a
                    class="hm button core w50 pheme-buy-button"
                    @click.prevent="showPhemeModal = true"
                    >buy</a
                  >
                </div>
                <div class="vaHack">
                  <a
                    class="hm button core rayme w50 pheme-sell-button"
                    @click.prevent="showPhemeSellModal = true"
                    >sell</a
                  >
                </div>
              </td>
            </tr>

            <tr>
              <td>
                <h2>
                  MDP Datapoints Broker
                </h2>
                <p class="muted mrgn0">Cambridge Analytica started here too.</p>
              </td>
              <td>
                <a
                  class="hm button core rayme white w100 mrgn0"
                  @click.prevent="showEarlyAccessModal = true"
                  >request early access</a
                >
              </td>
            </tr>
            <tr>
              <td>
                <h2>coin</h2>
                <p class="muted mrgn0">Redesigned liquidity solutions</p>
              </td>
              <td>
                <i class="circle icon gold"></i>gold &amp;
                <i class="circle outline icon gold"></i>coin Exlusive
              </td>
            </tr>

            <tr id="blackBoxesPlacement">
              <td><h2>Black Boxes</h2></td>
              <td>
                <a class="ui basic button inverted w50" style="float:right"
                  >explore</a
                >
              </td>
            </tr>

            <tr>
              <td>
                <h2>
                  General Purpose Classifieds
                </h2>
                <p class="muted mrgn0">Try this one for every other use case</p>
              </td>
              <td>
                <a
                  class="ui basic button w100"
                  @click.prevent="showGPCpostModal = true"
                  >post</a
                >
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <!-- pheme buy -->
      <div
        id="buyPhemeModal"
        class="hm modal broker active"
        v-if="showPhemeModal"
      >
        <pheme-buy-modal />
      </div>
      <!-- General Purpose Classifieds (GPC) -->
      <div
        id="GPCpostModal"
        class="hm modal broker active"
        v-if="showGPCpostModal"
      >
        <mercury-modal />
      </div>

      <jobs-to-be-filled-add-new-modal
        :is-modal-active="true"
        :is-bare="true"
        v-if="showJobModal"
      />


    </div>
  </div>

</template>
<script>
import jobsToBeFilledAddNewModal from '@/3layers/talent/gigs/gigAddNewModal'
import foraAnimation from './foraAnimation'
import phemeBuyModal from './phemeBuyModal'
import mercuryModal from './mercuryModal'

export default {
  name: "broker",
  components: {
    jobsToBeFilledAddNewModal,
    foraAnimation,
    phemeBuyModal,
    mercuryModal,
  },
  data() {
    return {
      showJobModal: false,
      showPhemeModal: false,
      showPhemeSellModal: false,
      showEarlyAccessModal: false,
      showGPCpostModal: false,
      showBrokerDrawer: false,
    }
  },
  mounted() {
    this.$root.$on('close-modals', this.closeModals)
    this.$root.$on('toggle-broker-drawer', this.toggleBrokerDrawer)
  },
  methods: {
    closeModals() {
      this.showJobModal = false;
      this.showPhemeModal = false;
      this.showPhemeSellModal = false;
      this.showEarlyAccessModal = false;
      this.showGPCpostModal = false;
    },
    toggleBrokerDrawer() {
      this.showBrokerDrawer = !this.showBrokerDrawer;
    }
  }
}

</script>

<style scoped>
    .pheme-sell-button,
    .pheme-buy-button {
        width: 100px;
    }
</style>
