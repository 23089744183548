//
// basic imports
//
import Vue from 'vue';
import VueRouter from 'vue-router';
import * as Sentry from '@sentry/vue';
import {BrowserTracing} from '@sentry/tracing';

import axios from 'axios';
import SemanticUI from 'semantic-ui-vue';
import screenfull from 'screenfull';
import vueCountryRegionSelect from 'vue-country-region-select';
import TextareaAutosize from 'vue-textarea-autosize';
import {directive as onClickaway} from 'vue-clickaway';

import wysiwyg from 'vue-wysiwyg';
import 'vue-wysiwyg/dist/vueWysiwyg.css';
import Loading from 'vue-loading-overlay';
// Routing
import routes from './routes';
import app from '@/global/layout/app';

// Vue and environment settings
Vue.config.devtools = true;
Vue.config.productionTip = false;
Vue.use(TextareaAutosize);
Vue.use(VueRouter);
Vue.use(SemanticUI);
Vue.use(vueCountryRegionSelect);

Vue.use(require('vue-shortkey'), {
  prevent: ['input', 'textarea', '.editr--content', '.custom-input'],
});

Vue.use(wysiwyg, {
  hideModules: {
    image: true,
    code: true,
    table: true,
    headings: true,
    removeFormat: true,
    separator: true,
  },
});

Vue.use(Loading, {
  zIndex: 9999,
});

// Set csrftoken
if (window.csrftoken) {
  axios.defaults.headers.common['X-CSRFToken'] = window.csrftoken;
} else console.log('No csrftoken');

window.screenfull = screenfull;

Vue.prototype.$http = axios;

// get APP-level vars/constants
Vue.prototype.$csrf = window.csrftoken;
Vue.prototype.$username = window.username;

// :TODO: cleanup username everywhere to use $legalName in future
Vue.prototype.$legalName = window.username;
Vue.prototype.$userId = window.user_id;
Vue.prototype.$userIsAnonymous = window.user_is_anonymous;
Vue.prototype.$handle = window.handle == 'anonymous' ? 'helios' : window.handle;
Vue.prototype.$userpic = window.userpic;
Vue.prototype.$userpicScaled = window.userpic_scaled;
Vue.prototype.$accountStatus = window.account_status;
Vue.prototype.$accountIsPaid = window.paid_account;
Vue.prototype.$dataLoadedStatus = Object();

var router = new VueRouter({
  routes,
  linkActiveClass: 'router-link-active active',
  base: '/',
  mode: 'history',
});

Vue.directive('on-clickaway', onClickaway)
if (document.getElementById('app')) {
  new Vue({
    router,
    render: h => h(app),
    // directives: {
    //     onClickaway: onClickaway,
    // },
    data(){
      return {
        teleportBus: null
      }
    },
    mounted() {
      this.$root.$on('data-loaded', this.updateDataLoadedStatus);

      import('./nodes/mercuryCommunication/02teleport/bus').then(teleportBusModule => {
        this.teleportBus = teleportBusModule.teleportBus
      })
    },
    methods: {
      updateDataLoadedStatus(dataKey, dataLoaded) {
        this.$dataLoadedStatus[dataKey] = dataLoaded;
        this.$forceUpdate();
      },
      formatDate(date) {
        if (!date) return '';

        if (typeof date != 'object') date = new Date(date);

        const formater = Intl.DateTimeFormat('en', {
          day: '2-digit',
          month: 'long',
        });

        return formater.format(date);
      },
    },
  }).$mount('#app');
}

Sentry.init({
  Vue,
  dsn: 'https://8e22d663587d4a31979f19b670e95cf1@sentry.holons.me/3',
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      // tracingOrigins: ["holons.me", "boards.holons.me", /^\//], // this would not work :(
      tracingOrigins: ['*'], // guys from stackowerflow, say it'll fix CORS errors
    }),
  ],
  tracesSampleRate: 1.0,
  environment: process.env.VUE_APP_ENVIRONMENT,
});
// @TODO: remove commented directivaes after all modals are based on vue-clickavay
// Vue.directive('on-modal-close', {
//   bind(modal, binding) {
//     const observer = new MutationObserver(mutations => {
//       for (const m of mutations) {
//         const newValue = m.target.getAttribute(m.attributeName);
//         if (!newValue.includes('active') && m.oldValue.includes('active'))
//           observer.disconnect();
//         binding.value();
//       }
//     });
//
//     observer.observe(modal, {
//       attributes: true,
//       attributeOldValue: true,
//       attributeFilter: ['class'],
//     });
//   },
// });
// Vue.directive('click-outside', {
//   bind(el, binding, vnode) {
//     el.clickOutsideEvent = function(event) {
//       // @TODO: add event for global <ESC> key handler and global closeModal
//       // @TODO: also invent a global classname to prevent clickoutside on the el, test in nested items like deeds clickable header/block
//       // here I check that click was outside the el and his children
//       if (!(el == event.target || el.contains(event.target))) {
//         if (
//           event.target.classList.value.indexOf('button') == -1 &&
//           event.target.classList.value.indexOf('noclickout') == -1
//         ) {
//           // and if it did, call method provided in attribute value
//           vnode.context[binding.expression](event);
//         }
//       }
//     };
//     document.body.addEventListener('click', el.clickOutsideEvent);
//   },
//   unbind(el) {
//     document.body.removeEventListener('click', el.clickOutsideEvent);
//   },
// });
