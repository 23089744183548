<template>
  <div style="width: 100%" class="flex">

    <!--<p class="crimson">sup with experiences?</p>-->


      <div style="position: sticky; top: 0">
        <div v-if="bus.activeProfile.meta_community == 'EMPIRE'"
        id="empire" class="bannerLord">
          <div class="vaHack" style="justify-content: end">
            <h2 style="margin: -2rem">
              empire <br>
              crimson
            </h2>
          </div>
        </div>

        <div v-if="bus.activeProfile.meta_community == 'REPUBLIC'"
        id="republic" class="bannerLord">
          <div class="vaHack">
            <h2>
              the <br>
              republic
            </h2>
          </div>
        </div>

        <div v-if="bus.activeProfile.meta_community == 'CITY'"
        id="union" class="bannerLord">
          <div class="">
            <h2>
              city <br>
              states <br>
              union 🤙
            </h2>
          </div>
        </div>
      </div>

    <div style="width: 100%">


          <div v-if="bus.activeProfile.meta_community == ''"
          class="baseRepPoint flex">
            <p class="mrgn0">status</p>
            <p class="mrgn0">core</p>
          </div>

          <div v-if="bus.activeProfile.meta_community == ''"
          class="baseRepPoint flex">
            <div class="vaHack">
              <p class="mrgn0">membership @ well-known communities</p>
            </div>
            <div class="vaHack">
              <a href="/odyssey/about/references/thesaurus/idiot">none</a>
            </div>
          </div>

          <div v-if="bus.activeProfile.meta_community == ''"
          class="baseRepPoint flex">
            <p class="mrgn0">notable social life</p>
            <p class="mrgn0">none</p>
          </div>

          <div v-else
          class="baseRepPoint flex">
            <p class="mrgn0">notable social life</p>
            <p class="mrgn0">of course</p>
          </div>




        <div class="flex" style="margin: 4rem 2rem">

            <div class="vaHack w50">
              <div class="">

                <h2>Reputation</h2>
                <p>a sum of things to help judge you.</p>

                  <!--<a class="ui basic button">
                    import reputation from elsewhere</a>-->

                <p class="muted">
                  commitments delivered: <br>
                  nothing on our records yet</p>

              </div>
            </div>


          <div id="justiceStatus" class="w50">
            <h2>Integrity</h2>
            <p>is almost binary – cheat
            anyone or the system and you'll be out of the game.</p>
            <ul>
              <li>justice: no open issues</li>
              <li>integrity: ok</li>
            </ul>
          </div>


        </div> <!-- rep & integrity ends -->


         <!--

         classic\gold rep

       -->

            <div class="flex">


                  <div class="man-item classic w50 flex" style="height: 40vh;  margin-right: 2rem;">
                    <div class="vaHack">
                      <h2 style="color: #F7F8FA">Ranks</h2></div>
                      <div class="vaHack">
                        <div class="label">
                          requires <br>
                          classic</div>
                        </div>
                  </div>

                  <div class="man-item gold w50 flex" style="height: 40vh;background: #14101B">
                    <div class="vaHack">
                      <h2 style="color: #F7F8FA">Cursus Honorum</h2></div>
                      <div class="vaHack">
                        <div class="label">
                          gold <br>
                          exclusive </div>
                        </div>
                  </div>

            </div>

    </div>

  </div>
</template>


<script>
import {identityBus} from '../bus';
export default {
  name: 'socialTabOwn',
  data() {
    return {
      bus: identityBus,
    }
  },
};
</script>
