<template>
  <div id="holonsHeader" class="flex">

      <div class="flex">
          <div class="vaHack">
            <router-link
                class="item"
                id="agendaMainTab"
                to="/agenda"
                exact>
              Agenda
            </router-link>
          </div>
          <div class="vaHack">
            <router-link
                class="item"
                id="relationsMainTab"
                to="/relations"
                >
              <del>Relations</del>
            </router-link>
          </div>
          <div class="vaHack">
            <router-link
                class="item"
                to="/layers"
                id="layersHeader"
                ref="layersHeader">
              <span>Layers</span>

                    <h1 id="flTitle">
                      Fairchild Layers
                      <span style="font-size: 1.24rem">v001</span></h1>
            </router-link>
          </div>
          <div class="vaHack">
            <router-link
                class="item"
                to="/moneta"
                id="monetaHeader"
                exact>
              <del>Moneta</del>
            </router-link>
          </div>
          <div class="vaHack">
            <router-link
                class="item"
                to="/purpose">
              Purpose
            </router-link>
          </div>
      </div>

  </div>

</template>
<script>
export default {
   name: 'topNavigation',
}
</script>
