// Our custom components used in ROUTING
import loginForm from '@/global/auth/loginForm';
import timeNode from '@/3layers/time/timeNode';
import timeTrackerJournal from '@/3layers/time/timeJournal';
import theGame from '@/x_foraPublic/theGame';
import mapComponent from '@/5purpose/map/map';
import identityComponent from '@/02identity/identityNode';

const homepage = () => import('@/x_homepage/index');
//
// Top nav
const agenda = () => import('@/1agenda/agendaUi');
const monetaNode = () => import('@/4moneta/monetaNode');
const purposeNode = () => import('@/5purpose/purposeNode');
const workSpaces = () => import('@/3layers/workspaces/workSpaces');
const relationsNode = () => import('@/2relations/relationsNode');
const teleportUi = () => import('@/mercuryCommunication/02teleport/index.vue');

// Right sidebar
const raysUi = () => import('@/mercuryCommunication/raysUi/raysUi');
const mailNode = () => import('@/mercuryCommunication/3mail/mailNode');
const scheduleMeetUi = () =>
  import('@/mercuryCommunication/scheduleCalendar/scheduleMeetUi');
const platosFlyWheel = () =>
  import('@/mercuryCommunication/platosFlyWheelUi/platosFlyWheelUi');
const subscriptionPlato = () =>
  import('@/02identity/legacy/subscription/subscriptionPlato');
const hearSay = () => import('@/mercuryCommunication/platosFlyWheelUi/hearSay');

// Third level
const talentNode = () => import('@/3layers/talent/talentNode');
const bookUi = () => import('@/mercuryCommunication/bookUi/index');
const digestUi = () => import('@/mercuryCommunication/digestUi/digestUi');
const platosEngine = () =>
  import('@/mercuryCommunication/platosFlyWheelUi/platosEngine');
const platosConcessions = () =>
  import('@/mercuryCommunication/platosFlyWheelUi/platosConcessions');
const platosAds = () =>
  import('@/mercuryCommunication/platosFlyWheelUi/platosAds');
const beastModeCarousel = () =>
  import('@/mercuryCommunication/platosFlyWheelUi/beastModeCarousel');

// Single templates
const offerDisplay = () => import('@/2relations/offers/_displayOffer');
const invoicesDisplay = () => import('@/2relations/invoices/invoiceDisplay');
const campaignsUi = () =>
  import('@/mercuryCommunication/platosFlyWheelUi/campaignsUI/campaignsUi');
// const displayCannedRay = () => import('@/global/routing/displayCannedRay'); // Display canned ray in the SPA
const foraUi = () => import('@/x_foraPublic/foraNode');
// const foraMarkets = () => import('@/x_foraPublic/foraMarkets');
const faithVc = () => import('@/x_faithVC/faithVc');
// const directoryMain = () => import('@/x_foraPublic/directoryMain');
const directoryCat = () => import('@/x_foraPublic/directoryCat');
const bookingWidget = () =>
  import('@/mercuryCommunication/scheduleCalendar/bookingWidget.vue');
// eslint-disable-next-line
const digest = () => import('@/x_faithVC/media/digest.vue');

// const communities = () => import('@/x_foraPublic/communities.vue');

// dark
const dark = () => import('@/x_dark/dark');
// atoms
const atoms = () => import('@/x_atoms/atoms');
// bits
const bits = () => import('@/x_bits/bits');
// node
const node = () => import('@/x_node/node');

// root
const runbook = () => import('@/x_root/runbook');
const rootCreate = () => import('@/x_root/rootCreate/rootCreate.vue');
const rootSearch = () => import('@/x_root/rootSearch');
const rootEngines = () => import('@/x_root/rootEngines');

// Route between components
export default [
  {
    path: '/homepage',
    component: homepage,
    name: 'HomepageStatic',
    meta: {
      holonsNodeGroup: 'home',
    },
  },
  // top menu
  {
    path: '/',
    component: bookUi,
    name: 'Homepage',
    meta: {
      holonsNodeGroup: 'book',
    },
  },
  {
    path: '/digest/:component?',
    component: digestUi,
    name: 'DigestUi',
    meta: {
      holonsNodeGroup: 'digest',
    },
  },
  {
    path: '/agenda/:story?',
    component: agenda,
    name: 'Agenda',
    meta: {
      holonsNodeGroup: 'corex',
    },
  },
  {
    path: '/auth',
    component: loginForm,
    name: 'Auth',
  },
  {
    path: '/moneta',
    component: monetaNode,
    name: 'Moneta',
    meta: {
      holonsNodeGroup: 'corex',
    },
  },
  {
    path: '/purpose',
    component: purposeNode,
    name: 'Purpose',
    meta: {
      holonsNodeGroup: 'corex',
    },
  },
  {
    path: '/purpose/game',
    component: theGame,
    name: 'TheGame',
    meta: {
      holonsNodeGroup: 'corex',
    },
  },
  {
    path: '/purpose/map',
    component: mapComponent,
    name: 'Map',
    meta: {
      holonsNodeGroup: 'corex',
    },
  },
  {
    path: '/relations/:component?',
    component: relationsNode,
    name: 'Relations',
    meta: {
      holonsNodeGroup: 'corex',
    },
  },
  // right sidebar
  {
    path: '/rays',
    component: raysUi,
    name: 'Rays',
    meta: {
      holonsNodeGroup: 'corex',
    },
  },
  {
    path: '/teleport',
    component: teleportUi,
    name: 'Teleport',
    meta: {
      holonsNodeGroup: 'corex',
    },
  },
  {
    path: '/mail',
    component: mailNode,
    name: 'mail',
    meta: {
      holonsNodeGroup: 'corex',
    },
  },
  {
    path: '/schedule',
    component: scheduleMeetUi,
    name: 'Schedule',
    meta: {
      holonsNodeGroup: 'corex',
    },
  },
  {
    path: '/schedule/meet/',
    component: scheduleMeetUi,
    name: 'ScheduleMeet',
    meta: {
      holonsNodeGroup: 'corex',
    },
  },
  {
    path: '/platos-flywheel/legacy',
    component: platosFlyWheel,
    name: 'PlatosFlyWheel',
    meta: {
      holonsNodeGroup: 'platos',
    },
  },
  {
    path: '/campaigns',
    component: campaignsUi,
    name: 'CampaignsUi',
    meta: {
      holonsNodeGroup: 'corex',
    },
  },
  {
    path: '/platos-flywheel/subscription',
    component: subscriptionPlato,
    name: 'SubscriptionPlato',
    meta: {
      holonsNodeGroup: 'platos',
    },
  },
  {
    path: '/platos-flywheel/engine',
    component: platosEngine,
    name: 'PlatosEngine',
    meta: {
      holonsNodeGroup: 'platos',
    },
  },
  {
    path: '/platos-flywheel/concessions',
    component: platosConcessions,
    name: 'PlatosConcessions',
    meta: {
      holonsNodeGroup: 'platos',
    },
  },
  {
    path: '/platos-flywheel/ads',
    component: platosAds,
    name: 'PlatosAds',
    meta: {
      holonsNodeGroup: 'platos',
    },
  },
  {
    path: '/platos-flywheel',
    component: beastModeCarousel,
    name: 'BeastModeCarousel',
    meta: {
      holonsNodeGroup: 'platos',
    },
  },
  {
    path: '/platos-flywheel/hearsay',
    component: hearSay,
    name: 'HearSay',
    meta: {
      holonsNodeGroup: 'platos',
    },
  },

  // misc
  {
    path: '/layers/time',
    component: timeNode,
    name: 'TimeTracker',
    meta: {
      holonsNodeGroup: 'corex',
    },
  },
  {
    path: '/layers/talent',
    component: talentNode,
    name: 'Talent',
    meta: {
      holonsNodeGroup: 'corex',
    },
  },
  {
    path: '/layers/talent/payroll',
    component: talentNode,
    name: 'TalentPayroll',
    meta: {
      holonsNodeGroup: 'corex',
    },
  },
  {
    path: '/layers/talent/gigs',
    component: talentNode,
    name: 'TalentGigs',
    meta: {
      holonsNodeGroup: 'corex',
    },
  },
  {
    path: '/layers/:cardOrProject?',
    component: workSpaces,
    name: 'Layers',
    meta: {
      holonsNodeGroup: 'corex',
    },
  },

  // single
  {
    path: '/relations/invoices/:id',
    component: invoicesDisplay,
    name: 'InvoceDisplay',
    meta: {
      holonsNodeGroup: 'corex',
    },
  },
  {
    path: '/relations/commitment/journal/:id',
    component: timeTrackerJournal,
    name: 'JournalDisplay',
    meta: {
      holonsNodeGroup: 'corex',
    },
  },
  {
    path: '/relations/offers/:id',
    component: offerDisplay,
    name: 'OfferDisplay',
    meta: {
      holonsNodeGroup: 'corex',
    },
  },
  {
    path: '/meet/:handle',
    component: bookingWidget,
    name: 'BookingWidget',
    meta: {
      holonsNodeGroup: 'corex',
    },
  },
  {
    path: '/faith-VC/:component?',
    component: faithVc,
    name: 'FaithVc',
    meta: {
      holonsNodeGroup: 'faith',
    },
  },

  //fora
  {
    path: '/fora/:component?',
    component: foraUi,
    name: 'foraUi',
    meta: {
      holonsNodeGroup: 'fora',
    },
  },
  {
    path: '/fora/directory/:skill',
    component: directoryCat,
    name: 'directoryCat',
    meta: {
      holonsNodeGroup: 'fora',
    },
  },
  {
    path: '/fora/directory/:skill/:country',
    component: directoryCat,
    name: 'directoryCatCountry',
    meta: {
      holonsNodeGroup: 'fora',
    },
  },
  {
    path: '/fora/directory/:skill/:country/:city',
    component: directoryCat,
    name: 'directoryCatCity',
    meta: {
      holonsNodeGroup: 'fora',
    },
  },

  {
    path: '/dark',
    component: dark,
    name: 'Dark',
    meta: {
      holonsNodeGroup: 'dark',
    },
  },
  {
    path: '/atoms',
    component: atoms,
    name: 'Atoms',
    meta: {
      holonsNodeGroup: 'atoms',
    },
  },
  {
    path: '/bits',
    component: bits,
    name: 'Bits',
    meta: {
      holonsNodeGroup: 'bits',
    },
  },
  {
    path: '/node',
    component: node,
    name: 'node',
    meta: {
      holonsNodeGroup: 'node',
    },
  },

  // root
  {
    path: '/root',
    component: runbook,
    name: 'Root',
    meta: {
      holonsNodeGroup: 'root',
    },
  },
  {
    path: '/root/runbook',
    component: runbook,
    name: 'Runbook',
    meta: {
      holonsNodeGroup: 'root',
    },
  },
  {
    path: '/root/create',
    component: rootCreate,
    name: 'RootCreate',
    meta: {
      holonsNodeGroup: 'root',
    },
  },
  {
    path: '/root/search',
    component: rootSearch,
    name: 'RootSearch',
    meta: {
      holonsNodeGroup: 'root',
    },
  },
  {
    path: '/root/engines',
    component: rootEngines,
    name: 'RootEngines',
    meta: {
      holonsNodeGroup: 'root',
    },
  },

  // else
  {
    path: '/:entitySlug?/:activeItem?',
    component: identityComponent,
    name: 'Identity',
    meta: {
      holonsNodeGroup: 'identity',
    },
  },
  // {path: '/:id', component: displayCannedRay, name: 'DisplayCannedRay'},
  // {path: '/:handle', component: identityComponent, name: 'identityComponent'},
];
