<template>
  <div style="max-width: 720px">

    <p class="muted">//this tab is visible only to you</p>

    <p>Would be cool to have some stats stuff like profile views, it's ranking in the Directory (if any), inbounds leads and whatnot.</p>

    <p>None of these is coming soon, but I have a dream.</p>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>
