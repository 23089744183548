var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{attrs:{"id":"journal-holder"}},[_vm._l((_vm.filteredWps),function(year){return _c('div',{key:year.year,staticClass:"year"},[(!_vm.checkCurrentYear(year.year))?_c('h1',{staticClass:"wp-title__year"},[_vm._v(" "+_vm._s(year.year)+" ")]):_vm._e(),_c('div',{staticClass:"months"},_vm._l((year.months),function(month){return _c('div',{key:month.month_title,staticClass:"month-holder"},[_c('table',{staticClass:"hm table plain journal"},[_c('thead',[_c('tr',[_c('td'),_c('td',[_vm._v("entity")]),_c('td',[_vm._v("commitment")]),_c('td',[(!_vm.checkCurrentMonth(year.year, month.month))?_c('h2',{staticClass:"wp-title__month"},[_vm._v(" "+_vm._s(month.month_title)+" ")]):_vm._e()])])]),_vm._l((month.wps),function(wp,i){return _c('tr',{key:i},[(
                  !month.wps.find(
                    function (_wp, checkI) { return checkI < i && _wp.week == wp.week; }
                  )
                )?_c('td',{attrs:{"rowspan":month.wps.filter(function (_wp) { return _wp.week == wp.week; }).length}},[_c('h4',[_vm._v("week #"+_vm._s(wp.weekObj.week))]),_c('p',{staticClass:"mrgn0"},[_vm._v(" "+_vm._s(wp.weekObj.week_start)+" - "+_vm._s(wp.weekObj.week_end)+" ")]),_c('p',{staticClass:"week-total muted mrgn0"},[_vm._v(" "+_vm._s(wp.weekObj.week_duration)+" total ")])]):_vm._e(),(
                  !month.wps.find(
                    function (_wp, checkI) { return checkI < i && _wp.week == wp.week && _wp.context; }
                  )
                )?_c('td',{attrs:{"rowspan":month.wps.filter(function (_wp) { return _wp.week == wp.week && _wp.context; })
                    .length}},[_vm._v(" "+_vm._s(wp.context)+" ")]):_vm._e(),(
                  !month.wps.find(
                    function (_wp, checkI) { return checkI < i &&
                      _wp.week == wp.week &&
                      _wp.context &&
                      _wp.commitment == wp.commitment; }
                  )
                )?_c('td',{attrs:{"rowspan":month.wps.filter(
                    function (_wp) { return _wp.week == wp.week &&
                      _wp.context &&
                      _wp.commitment == wp.commitment; }
                  ).length}},[_vm._v(" "+_vm._s(wp.commitment)+" ")]):_vm._e(),_c('td',{staticClass:"entry"},[(wp.billableCount)?[_c('i',{staticClass:"dollar sign icon",staticStyle:{"color":"#7b8f50"}}),_vm._v(" "+_vm._s(wp.billableHours)+" = "+_vm._s(wp.comment)+" "),_c('span',{staticClass:"muted",staticStyle:{"font-size":"10px"}},[_vm._v("x "+_vm._s(wp.billableCount))])]:_vm._e(),(wp.nonbillableCount)?[_vm._v(" "+_vm._s(wp.nonbillableHours)+" = "+_vm._s(wp.comment)+" "),_c('span',{staticClass:"muted",staticStyle:{"font-size":"10px"}},[_vm._v("x "+_vm._s(wp.nonbillableCount))])]:_vm._e()],2)])})],2)])}),0)])}),(!_vm.isLoaded)?_c('div',[_vm._v("loading")]):_vm._e(),(_vm.isLoaded && !_vm.workPeriods.length)?_c('div',[_vm._v("no time tracked yet")]):_vm._e()],2)])}
var staticRenderFns = []

export { render, staticRenderFns }