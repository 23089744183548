<template>
  <div>
    <table class="hm table plain" v-if="!gigsIsLoading && gigs.length"
      style="max-width: 600px; margin: 5rem auto">
      <tr v-for="gig in gigs" :key="gig.id"><td>{{ gig.name }}</td> <td>{{ gig.location }}</td></tr>
    </table>
    <div v-if="!gigs.length && !gigsIsLoading">No gigs</div>
    <div v-if="gigsIsLoading">Loading</div>
  </div>
</template>

<script>
import { identityBus } from '../bus'
export default {
  data(){
    return {
      gigs: [],
      gigsIsLoading: true,

      bus: identityBus
    }
  },
  methods: {
    loadGigs(){
      fetch(`/api/layers/jobs?context=${this.bus.activeProfile.context_id}`).then(async res => {
        if(!res.ok) return

        this.gigs = (await res.json()).jobs
        this.gigsIsLoading = false
      })
    }
  },
  mounted(){
    this.loadGigs()
  }
}
</script>

<style>

</style>
