<template>
  <div>
      <div id="workload">

        <!--<div class="hm menu">
          <a class="item">journals</a>
          <a class="item active">workload</a>
          <a class="item">timesheets</a>
        </div>-->

        <div class="hm menu">
          <a class="item active">workspaces</a>
          <a class="item">people</a>
        </div>


        <div class="flex workload-holder">
          <div>
            <div class="wl__item">
              <!-- due date -->
              <div class="deadline soft deadline-soft">
                soft due date
              </div>
              <div class="deadline hard deadline-hard">
                hard due date
              </div>
              <!-- first row -->
              <div class="wl__item--header">
                <h2>
                  <!--

                  pattern: (workspace) at (commitment)

                  -->
                  1st iteration <br>
                  at <a class="ui basic button">link commitment</a> </h2>
                  87 tickets = 300 hours in total
              </div>
              <!-- n+1 row -->
              <div class="worker flex workload-worker-row">
                  <div class="wl__identity flex workload-worker-identity">
                      <div class="vaHack">
                        <img
                            src="https://holons.me/scaled/resize/100x100//usg/usg/soulspics/fNii1u_phsk.jpg"
                            width="22px"
                            class="workload-worker-icon"
                        >
                      </div>
                      <div class="vaHack">
                        <h5>Oleg</h5>
                      </div>
                  </div>
                  <div class="wl__item--persons-load">100 hrs assigned – 20 hrs done</div>
                  <div class="row workload-person-summary-holder">
                    <div class="summary flex">
                      <div class="vaHack">
                        =
                      </div>
                      <div class="vaHack">
                        <div>
                          <div class="workload-hours-remain">
                            (80 hrs remains + 30 hrs buffer)
                          </div>
                          <div>
                            40 hrs weekly capacity
                          </div>
                        </div>
                      </div>
                      <div class="vaHack">
                        = ready in X weeks
                      </div>
                    </div>
                  </div>
              </div>

              <div class="worker flex workload-worker-identity">
                  <div class="wl__identity flex workload-worker-identity">
                      <div class="vaHack">
                        <img
                            src="https://holons.me/scaled/resize/100x100//usg/usg/soulspics/fNii1u_phsk.jpg"
                            width="22px"
                            class="workload-worker-icon"
                        >
                      </div>
                      <div class="vaHack">
                        <h5>Oleg</h5>
                      </div>

                  </div>
                  <div class="wl__item--persons-load">100 hrs assigned – 20 hrs done</div>
                  <div class="row workload-person-summary-holder-done">
                    <div class="summary flex">
                      <div class="vaHack">
                        =
                      </div>
                      <div class="vaHack">
                        <div>
                          <div class="workload-person-hours-remain">
                            (80 hrs remains + 30 hrs buffer)
                          </div>
                          <div>
                            40 hrs weekly capacity
                          </div>
                        </div>
                      </div>
                      <div class="vaHack">
                        = ready in X weeks
                      </div>
                    </div>
                  </div>
              </div>

              <div class="worker flex workload-worker-identity">
                  <div class="wl__identity flex workload-worker-identity">
                      <div class="vaHack">
                        <img
                                src="https://holons.me/scaled/resize/100x100//usg/usg/soulspics/fNii1u_phsk.jpg"
                                width="22px"
                                class="workload-worker-icon"
                        >
                      </div>
                      <div class="vaHack">
                        <h5>Oleg</h5>
                      </div>

                  </div>
                  <div class="wl__item--persons-load">100 hrs assigned – 20 hrs done</div>
                  <div class="row workload-person-summary-holder-done-1">
                    <div class="summary flex">
                      <div class="vaHack">
                        =
                      </div>
                      <div class="vaHack">
                        <div>
                          <div class="workload-person-hours-remain">
                            (80 hrs remains + 30 hrs buffer)
                          </div>
                          <div>
                            40 hrs weekly capacity
                          </div>
                        </div>
                      </div>
                      <div class="vaHack">
                        = ready in X weeks
                      </div>
                    </div>
                  </div>
              </div>
              <!-- last row -->
              <div class="wl__item--footer">
                20 hrs unassigned <br>
                11 ticket w/ no estimates
              </div>
            </div>


          </div>
          <div>
            <div class="gantt-wrapper">
              <div class="gantt">
                <div class="gantt__row gantt__row--months">
                  <span>Jan</span><span>Feb</span><span>Mar</span>
                  <span>Apr</span><span>May</span><span>Jun</span>
                  <span>Jul</span><span>Aug</span><span>Sep</span>
                  <span>Oct</span><span>Nov</span><span>Dec</span>
                </div>
                <div class="gantt__row gantt__row--lines" data-month="5">
                  <span></span><span></span><span></span>
                  <span></span><span></span><span></span>
                  <span></span><span class="marker"></span><span></span>
                  <span></span><span></span><span></span>
                </div>
                <div class="gantt__row workload-gantt__row"> </div>
            </div>
          </div>
        </div>
      </div>
      <h2 class="workload-untracked-header"style="">untracked</h2>

      <p>//list of untracked, nested workspaces w/ <a class="ui basic button">track workload</a> </p>

    </div>

  </div>
</template>

<script>
export default {
    name:'timeTrackerWorkload',
    data:function (){
        return {}
    },

}
</script>
