<template>
  <div>
    <div class="">
      quick recap: <a href="/odyssey/cronos-1" class="hm link crub">cronos</a>
      <span> › </span>
      <a href="/" class="hm link crub">holons</a>
      <span> › </span>
      <span v-for="(item, idx) in breadCrumbs" :key="idx">
        <a
          v-if="idx != breadCrumbs.length - 1"
          :href="item.slug"
          class="hm link crub"
          v-html="item.title"
        ></a>
        <!--<span v-else v-html="item.title"></span>-->
        <span v-if="idx != breadCrumbs.length - 2"> › </span>
      </span>
      <div id="word-container">
        <h1>
          {{ wordSingle.title }}
        </h1>

        <p class="excerpt" v-html="wordSingle.excerpt"></p>
        <div class="main" v-html="wordBody"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'wordSingleDisplay',
  props: ['word-single'],
  data() {
    return {};
  },
  computed: {
    mediaClasses() {
      return this.wordSingle.published_at.slug;
    },
    breadCrumbs() {
      return window.word_single.breadcrumbs;
    },
    breadcrumbsClassNames() {
      return `${window.word_single.breadcrumbs.slice(-1)[0].classnames}`;
    },
    title() {
      return this.wordSingle.title;
    },
    excerpt() {
      return this.wordSingle.excerpt;
    },
    wordBody() {
      return this.wordSingle.body;
    },
  },
  methods: {},
  mounted() {},
};
</script>
