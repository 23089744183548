<template>
  <div>
    <div id="profileProfile" class="flex">
      <div class="flex start">
        <img :src="bus.activeProfile.logo" class="profile__userpic" />

        <div class="bio">
          <div class="flex">
            <div class="vaHack">
              <h1 v-html="bus.activeProfile.name"></h1>
            </div>

            <div class="vaHack">
              <span class="identityVerified">
                <icon name="verified-user" />
              </span>
            </div>
          </div>

          <p>I build things online 🤙</p>

        </div>
      </div>

      <div class="">
        <div class="ctas">
          <!--<a class="hm button core rayme">message</a>-->

          <a class="hm button core teleport" @click="openTeleportChat">chat</a>

          <a
            v-if="bus.activeProfile.is_owner"
            id="editProfileBtn"
            @click.stop="bus.$emit('edit-profile', bus.activeProfile)"
            class="ui basic button"
            >tune identity</a
          >

          <a class="ui basic button mrgn0"
              v-if="bus.activeProfile.is_owner"
            onclick="document.querySelector('#verifyIdentityModal').classList.toggle('active')"
                  >verify identity</a>
          <!--<a class="hm button core meet">meet</a>-->
        </div>
      </div>

      <div id="verifyIdentityModal" class="hm modal paper">
        <div class="content">
          <h2 class="title">verify identity</h2>

          <p>Identity verification adds credibility and is required for some  <del>banhammers</del> features to work.</p>

          <p>Send @pheme on teleport the following:</p>

          <ul>
            <li>photo of yours next to the passport</li>
            <li>photo of yours next to the credit card with your name on it</li>
          </ul>

          <p>Mind the photos' quality, etc.</p>
        </div>
      </div>



      <!--<a class="hm button core teleport">chat</a>
          <a class="hm button core moneta">$100 book a session</a>-->

      <!-- Bio -->
      <!-- <div
        class="bio"
        v-if="!edit && profileType !== 'entity_profile'"
        v-html="bio !== '' ? bio : user.bio"
      ></div>
      <div class="bio" v-else v-html="profileDescription"></div>
      <wysiwyg v-if="edit" v-model="user.bio" />
      <a
        class="ui basic button"
        style="width:100%"
        v-if="!edit && bus.activeProfile.is_owner"
        @click.prevent="edit = !edit"
      >
        edit description
      </a>
      <div v-if="maxLengthError" class="ui error maxlength">
        Maximum bio length exceeded. Brevity is virtue...
      </div>
      <a
        class="ui basic button"
        style="width:100%"
        :class="{disabled: maxLengthError}"
        v-if="edit"
        @click.prevent="saveProfile"
      >
        save description
      </a> -->
      <!-- bio ends

      <div class="profile__convos">
        <h2>
          conversation <br />
          starters
        </h2>

        //list

        <div style="text-align:center; margin:2rem 0">
          <a class="hm button core meet">schedule a call</a>
        </div>
      </div>
    --></div>
  </div>
</template>
<script>
import iconsStore from '@/global/includes/icons';

import {identityBus} from '../bus';

export default {
  name: 'profileMainBar',
  props: ['edit', 'profileType'],
  components: {
    icon: iconsStore,
  },
  data() {
    return {
      defaultName: '',
      defaultLogo: '',
      bio: '',
      maxLengthError: '',
      user: {
        bio: '',
      },

      bus: identityBus,
    };
  },
  mounted() {
    this.bus.$on('user-details-change', this.setUserDetails);
  },
  methods: {
    openTeleportChat() {
      this.$http
        .post('/api/teleport/rooms/', {
          is_direct: true,
          members: [this.bus.activeProfile.context_id],
          name: '',
        })
        .then(res => {
          if (!res) return;

          localStorage.setItem('teleportActiveRoom', res.data.id);
          this.$router.push('/teleport');
        });
    },
    setUserDetails(userDetails = {}) {
      if (userDetails.display_name) {
        this.defaultName = userDetails.display_name;
      } else {
        this.defaultName = '';
      }
      if (userDetails.logo) {
        this.defaultLogo = userDetails.logo;
      } else {
        this.defaultLogo = '';
      }
    },
  },
};
</script>
