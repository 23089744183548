<template>
  <div 
    id="editor-tooltip" 
    v-on-clickaway="hide"
    :style="{ left: coords.left + 'px', top: (coords.top + coords.height) + 'px' }"
    v-show="isShowed"
  >
    <a v-if="preview" :href="preview">{{ preview }}</a>
    <input type="text" v-model="link" @keydown.enter.stop.prevent="submitLink">
    <button class="hm button core" @click="submitLink">submit</button>
    <button class="hm button core" @click="deleteLink" v-if="preview">delete</button>
  </div>
</template>

<script>
import Quill from 'quill';
const LinkBlot = Quill.import('formats/link');
import { Range } from 'quill/core/selection';

// There will be livetyping and other cool features
export default {
  props: ['editor'],
  data(){
    return {
      link: '',
      linkRange: null,
      preview: null,

      isShowed: false,
      coords: {}
    }
  },
  methods: {
    submitLink(){
      if(!this.link) return

      if(this.link.match(/.+\..+/) && !this.link.match(/^http:\/\//)){
        this.link = 'http://' + this.link
      }
      if(this.linkRange){
        this.editor.formatText(this.linkRange, 'link', this.link);
      }else{
        this.editor.format('link', this.link);
      }

      this.hide()
    },
    deleteLink(){
      if(!this.linkRange) return
      this.editor.formatText(this.linkRange, 'link', false)
      this.hide()
    },
    
    hide(){
      this.isShowed = false
      this.linkRange = this.preview = null
    },
    show(){
      this.isShowed = true
    },
    createLink(){
      let range = this.editor.getSelection();
      if (range == null || range.length == 0) return;

      this.show()
      this.coords = this.editor.getBounds(range)
    }

  },
  mounted(){
    this.editor.on('selection-change', (range, oldRange, source) => {
      if (range == null) return

      if (range.length === 0 && source === 'user') {
        let [link, offset] = this.editor.scroll.descendant(LinkBlot, range.index);

        if (link != null) {
          this.linkRange = new Range(range.index - offset, link.length());
          console.log(this.linkRange);
          this.preview = link.domNode.href;
          
          this.show();
          this.coords = this.editor.getBounds(this.linkRange)
          
          return
        }
      } else {
        delete this.linkRange;
      }
      this.hide();
    });

    this.$on('create-link', this.createLink)
  }
}
</script>

<style>
#editor-tooltip{
  position: absolute;
}
</style>