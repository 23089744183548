<template>
  <div style="max-width: 720px">
    <p class="muted">//this tab is visible only to you</p>

    <p>Would be cool to keep a branding collateral in one place.</p>

    <p>Things like logos, color palettes, fonts and what not.</p>

    <p>None of these is coming soon, but I have a dream.</p>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>
