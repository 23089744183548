<template>
  <div
    id="add-new-entity-modal"
    class="hm modal"
  >
    <div class="content"
      v-on-clickaway="closeModals"
      @click.stop=""
    >
      <h2 class="title">add new entity</h2>

      <p>
        Entity represents an org other than your account (or
        <a
          class="hm link"
          target="_blank"
          href="https://odyssey.holons.me/meta/value/personal-brands"
          >personal brand</a
        >).
      </p>
      
      <div class="ui input" style="width:100%">
        <img :src="avatarUrl" height="100px" alt="">
        <avatar-cropper
          v-model="showCropper"
          :upload-file-handler="() => {}"
          @changed="changeAvatar"
        />
        <button
          type="button"
          class="ui basic button"
          @click="showCropper = true"
        >choose avatar</button>
      </div>
      <div class="ui input" style="width:100%">
        <input
          id="enterEntityName"
          type="text"
          name="business_entity_name"
          :placeholder="businessEntityPlaceholder"
          v-model="businessEntityName"
        />
      </div>

      <br /><br /><br />

      <div
        style="width:100%"
        class="ui basic button"
        v-on:click.prevent="createBusinessEntity"
      >
        + create
      </div>

      <br /><br /><br />

      <div class="ui grid">
        <div class="twelve wide column">
          <h5>Use cases &amp; examples</h5>

          <ul class="hm ul">
            <li>
              An entity has its own P&amp;L
              <a
                class="hm link"
                target="_blank"
                href="https://odyssey.holons.me/nodes/moneta"
                >@moneta</a
              >
            </li>
            <li>
              It's possible to
              <a
                class="hm link"
                target="_blank"
                href="https://odyssey.holons.me/nodes/prudentia-justice/equity"
                >manage equity and profit rule</a
              >
            </li>
            <li>
              An entity might have a public profile at
              <a
                class="hm link"
                target="_blank"
                href="https://odyssey.holons.me/nodes/FORA"
                >FORA Public</a
              >
              IF at least one owner has Holons Classic or Gold
              <a
                class="hm link"
                target="_blank"
                href="https://odyssey.holons.me/nodes/prudentia-justice/identity"
                >account</a
              >
            </li>
            <li>
              It is used in both
              <a
                class="hm link"
                target="_blank"
                href="https://odyssey.holons.me/nodes/fairchild-layers/talent"
                >supply</a
              >
              and
              <a
                class="hm link"
                target="_blank"
                href="https://odyssey.holons.me/nodes/prudentia-justice/relations"
                >demand relations</a
              >
              similar to a company name
            </li>
            <li>
              At
              <a
                class="hm link"
                target="_blank"
                href="https://odyssey.holons.me/nodes/purpose"
                >@purpose</a
              >
              an entity has its own space to run seasons (OKR framework
              implementation)
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mixin as clickaway } from 'vue-clickaway';
import AvatarCropper from 'vue-avatar-cropper';

export default {
  name: 'businessEntityModal',
  components: {
    AvatarCropper
  },
  props: [ "businessEntity" ],
  mixins: [ clickaway ],
  data() {
    return {
      businessEntityName: '',
      showCropper: false,
      avatar: null,
      avatarUrl: ''
    }
  },
  computed: {
    apiUrl: function () {
        var url = window.apiRoot
        url += '/api/moneta/business-entities'
        return url
    },
    businessEntityPlaceholder: function () {
        if (this.businessEntity && this.businessEntity.isMedia) {
            return 'Enter media entity name'
        }
        return 'Enter business entity name'
    }
  },
  mounted() {
    this.$root.$on('close-modals', () => {
      this.closeModal()
    })
  },
  methods: {
    refreshOnSave: function () {
      // this.$parent.getContexts()
      this.$emit('context-created')
    },
    createBusinessEntity: function () {
      const formData = new FormData()
      formData.append('name', this.businessEntityName)
      if(this.avatar) formData.append('logo', this.avatar, this.avatar.name)

      if (this.businessEntity && this.businessEntity.isMedia) {
        formData.append('isMedia', this.businessEntity.isMedia)
      }

      this.$http.post(this.apiUrl, formData).then(response => {
        this.$forceUpdate()
        if(response.data){
          this.closeModal()
        }
        this.$nextTick(async () => {
          await this.refreshOnSave()
        })
      }).catch(error => {
          console.log('Business Entity api is unavailable at the moment', error)
          this.closeModal()
      });
    },

    changeAvatar({ file }){
      this.avatar = file
      
      const reader = new FileReader()
      reader.addEventListener('load', () => {
        this.avatarUrl = reader.result
      })
      reader.readAsDataURL(file)
    },

    closeModal: function () {
      this.$el.classList.remove('active')
    },
    closeModals(){
      this.$root.$emit('close-modals')
    },
  }
}
</script>
