<template>
  <div class="hm modal active">
    <div
      class="content"
      v-on-clickaway="
        () => {
          $root.$emit('close-modals');
        }
      "
    >
      <div id="monetaSidebarInvoice" style="">
        <div id="monetaLogo" class="flex start">
          <img src="/static/img/moneta2.png" width="30px" style="z-index:100" />
          <div class="circle"></div>
        </div>
      </div>

      <h2 class="title">Create invoice</h2>

      <div class="ui grid">
        <div class="eight wide column">
          <div class="flex">
            {{ tipMeta }}
            <h3 class="muted">bill to</h3>
            {{ invoice.billed_to_info.userId}}
            <textarea
              v-model="invoice.billed_to_info.userProfile.display_name"
              style="width:100%"
            ></textarea>
          </div>
        </div>
        <div class="eight wide column">
          <div class="flex">
            <h3 class="muted">bill from</h3>

            {{ invoice.billed_from_info.userId}}
            <textarea
              v-model="invoice.billed_from_info.userProfile.display_name"
              style="width:100%"
            ></textarea>
          </div>
        </div>

        <div class="eight wide column">
          <div class="flex"></div>

          <div v-if="!currency">
            <select class="ui fluid dropdown" v-model="currency">
              <option value="USD" selected>$$ US</option>
              <option value="RUB" selected>RUB</option>
            </select>
          </div>
        </div>
      </div>
      <!-- first grid ends -->

      <div class="flex">
        <h3
          style="border-right:30px solid #23272B;margin-right:30px;padding-right: 30px"
        >
          what
        </h3>

        <div style="width:100%">
          <table
            id="invoiceCreate"
            class="hm ui table grand"
            style="margin-bottom:2rem"
          >
            <thead>
              <tr>
                <td>Item</td>
                <td>Quantity</td>
                <td>Rate</td>
                <td>Amount</td>
              </tr>
            </thead>
            <tr v-for="(item, i) in invoice.items" :key="i">
              <td>
                {{ item.name }} \
                <a class="hm link" @click.stop.prevent="() => deleteItem(i)"
                  >delete this item</a
                >
              </td>
              <td
                v-text="!item.count_display ? item.count : item.count_display"
              ></td>
              <td>{{ item.rate ? item.rate : item.money }} {{ currency }}</td>
              <td v-if="item.rate">
                {{ item.rate * item.count }} {{ currency }}
              </td>
              <td v-else>{{ item.money * item.count }} {{ currency }}</td>
            </tr>
            <!--<tr>
                      <td colspan="3" style="text-align:right"><a class="hm link">add tax</a></td>
                    </tr>-->
            <tr>
              <td colspan="4" style="text-align:right">
                <h5>total: {{ totalCost }} {{ currency }}</h5>
              </td>
            </tr>
          </table>

          <div class="flex">
            <div>
              <input
                type="text"
                placeholder="Item name"
                v-model="newItem.name"
              />
            </div>
            <div>
              <input
                type="number"
                placeholder="Qty"
                v-model.number="newItem.count"
                min="1"
              />
            </div>
            <div>
              <input
                type="number"
                placeholder="Rate"
                v-model.number="newItem.money"
              />
            </div>
            <div>
              <button
                :disabled="!newItem.name || typeof newItem.money != 'number'"
                @click.prevent="addItem"
                class="ui basic button"
              >
                +
              </button>
            </div>
          </div>
        </div>
      </div>

      <button
        class="hm button core"
        style="width:100%;margin-top:30px"
        v-on:click.prevent="storeInvoice"
        :disabled="!canCreate"
      >
        Create invoice
      </button>

      <!--<h2>memo</h2>

          <textarea name="name" rows="8" cols="80"></textarea>-->

      <!-- ok, so later on we'll feature email, SMS as well,
          but to save time let's do just direct link now -->

      <!--<ul>
            <li
              v-for="el in item"
              v-bind:key="el"
            >
              <strong>{{ Object.keys(item).find(key => item[key] === el) }}:</strong> {{ el }}
            </li>
          </ul>-->
    </div>
  </div>
</template>

<script>

export default {
  name: 'invoiceModal',
  props: ['item', 'items', 'money', 'tip-meta'],
  data() {
    return {
      commitmentsList: [],

      invoice: {
        offer: {},
        billed_to_info: {
          userId: window.user_profile.user_id,
          userProfile: window.user_profile,
        },
        billed_from_info: {
          userId: window.user_id,
          userProfile: {
            display_name: window.username,
          }
        },
        items: [],
        status: 'outstanding',
        due_at: new Date(new Date().getTime() + 7 * 24 * 60 * 60 * 1000),
        paid_at: '',
        archived_at: '',
        dont_accept_after_due: true,
        pub_date: '',
      },
      currency: {},
      totalCost: 0,
      canCreate: false,
      newItem: {
        name: '',
        count: 1,
        money: 100,
        money_currency: '',
      },
    };
  },
  mounted() {
  }
};
</script>
