var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.navVisible)?_c('div',[_c('div',{staticClass:"flex start",attrs:{"id":"layersNavigation"}},[_c('router-link',{attrs:{"to":{name: 'Talent'},"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var route = ref.route;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('div',{class:[
          true && 'layers__tabs--single talent',
          isActive && 'router-link-active active',
          isExactActive && 'router-link-exact-active active' ],attrs:{"role":"link"},on:{"click":navigate}},[_c('a',{staticClass:"link-el",attrs:{"id":"talentTabLink"}},[_vm._v("talent")])])]}}],null,false,1503155203)}),_c('h2',{staticClass:"divider muted"},[_vm._v("/")]),_c('router-link',{attrs:{"to":_vm.layersRoute,"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var href = ref.href;
        var route = ref.route;
        var navigate = ref.navigate;
        var isActive = ref.isActive;
        var isExactActive = ref.isExactActive;
return [_c('div',{class:[
          true && 'layers__tabs--single ws',
          isActive && 'router-link-active',
          isExactActive && 'router-link-exact-active active' ],attrs:{"role":"link"},on:{"click":navigate}},[_c('a',{staticClass:"link-el"},[_vm._v("workspaces")])])]}}],null,false,3681975595)}),_c('h2',{staticClass:"divider muted"},[_vm._v("/")]),_c('router-link',{attrs:{"to":{name: 'TimeTracker'},"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var href = ref.href;
        var route = ref.route;
        var navigate = ref.navigate;
        var isActive = ref.isActive;
        var isExactActive = ref.isExactActive;
return [_c('div',{class:[
          true && 'layers__tabs--single time',
          isActive && 'router-link-active active',
          isExactActive && 'router-link-exact-active' ],attrs:{"role":"link"},on:{"click":navigate}},[_c('a',{staticClass:"link-el",attrs:{"id":"timeTabLink"}},[_vm._v("time")])])]}}],null,false,4194787836)})],1)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }