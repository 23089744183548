var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',{directives:[{name:"shortkey",rawName:"v-shortkey",value:({
    closeModals: ['esc'],
    agenda: ['a'],
    agendaCyr: ['ф'],
    relations: ['r'],
    relationsCyr: ['к'],
    layers: ['l'],
    layersCyr: ['д'],
    moneta: ['m'],
    monetaCyr: ['ь'],
    identity: ['i'],
    identityCyr: ['ш'],
    purpose: ['p'],
    purposeCyr: ['з'],
    rays: ['1'],
    teleport: ['2'],
    mail: ['3'],
    schedule: ['4'],
    magic: ['5'],
    digest: ['6'],
    platos: ['7'],
    forac: ['0'],
    rootCreate: ['§'],
    rootCreateCyr: ['ё'],
    fullscreen: ['f'],
    fullscreenCyr: ['а'],
    startTimer: ['t'],
    startTimerCyr: ['е'],
    logTimerEntry: ['k'],
    logTimerEntryCyr: ['л'],

    directory: ['d'],
    directoryCyr: ['в'],

    connections: ['c'],
    connectionsCyr: ['с'],

    fora: ['o'],
    foraCyr: ['щ'],
    faith: ['v'],
    faithCyr: ['м'],

    broker: ['b'],
    brokerCyr: ['и'],
    functions: ['/'],
    functionsCyr: ['.'],
    //say: ['s'],
    //sayCyr: ['ы'],
    arrowUp: ['arrowup'],
    arrowDown: ['arrowdown'],
    arrowRight: ['arrowright'],
    arrowLeft: ['arrowleft'],
  }),expression:"{\n    closeModals: ['esc'],\n    agenda: ['a'],\n    agendaCyr: ['ф'],\n    relations: ['r'],\n    relationsCyr: ['к'],\n    layers: ['l'],\n    layersCyr: ['д'],\n    moneta: ['m'],\n    monetaCyr: ['ь'],\n    identity: ['i'],\n    identityCyr: ['ш'],\n    purpose: ['p'],\n    purposeCyr: ['з'],\n    rays: ['1'],\n    teleport: ['2'],\n    mail: ['3'],\n    schedule: ['4'],\n    magic: ['5'],\n    digest: ['6'],\n    platos: ['7'],\n    forac: ['0'],\n    rootCreate: ['§'],\n    rootCreateCyr: ['ё'],\n    fullscreen: ['f'],\n    fullscreenCyr: ['а'],\n    startTimer: ['t'],\n    startTimerCyr: ['е'],\n    logTimerEntry: ['k'],\n    logTimerEntryCyr: ['л'],\n\n    directory: ['d'],\n    directoryCyr: ['в'],\n\n    connections: ['c'],\n    connectionsCyr: ['с'],\n\n    fora: ['o'],\n    foraCyr: ['щ'],\n    faith: ['v'],\n    faithCyr: ['м'],\n\n    broker: ['b'],\n    brokerCyr: ['и'],\n    functions: ['/'],\n    functionsCyr: ['.'],\n    //say: ['s'],\n    //sayCyr: ['ы'],\n    arrowUp: ['arrowup'],\n    arrowDown: ['arrowdown'],\n    arrowRight: ['arrowright'],\n    arrowLeft: ['arrowleft'],\n  }"}],staticClass:"hotkeys-handler",on:{"shortkey":_vm.shortkeyGoTo}})}
var staticRenderFns = []

export { render, staticRenderFns }