<template>
  <div id="JTBFmodal" class="hm modal active">
    <div
      class="content"
      v-on-clickaway="closeModals"
      style="border-color: #7b8f50"
    >
      <h2 class="title">log new job to be filled</h2>

      <!--<div class="hm menu border">
            <a class="item active">job</a>
            <a class="item">gig</a>
            <a class="item">GPC</a>
            <a class="item classic">n+1</a>
            <a class="item gold">consession</a>
          </div>-->

      <div id="jobs__type" class="flex section">
        <div class="form__radios horizontal pdng0">
          <div class="form__radio active">
            <label for="group-sort">full-time</label>
            <input
              type="radio"
              name="billable"
              tabindex="0"
              value="full_time"
              v-model="jobMeta.jobType"
            />
          </div>
          <div class="form__radio">
            <label for="date-sort">contractor: ongoing</label>
            <input
              type="radio"
              tabindex="0"
              name="billable"
              checked="checked"
              value="contractor_ongoing"
              v-model="jobMeta.jobType"
            />
          </div>
          <div class="form__radio">
            <label for="date-sort">contractor: one-time gig</label>
            <input
              type="radio"
              tabindex="0"
              name="billable"
              checked="checked"
              value="contractor_one_time_gig"
              v-model="jobMeta.jobType"
            />
          </div>
          <div class="form__radio">
            <label for="date-sort">intern</label>
            <input
              type="radio"
              tabindex="0"
              name="billable"
              checked="checked"
              value="intern"
              v-model="jobMeta.jobType"
            />
          </div>
          <div class="form__radio">
            <label for="date-sort">officer</label>
            <input
              type="radio"
              tabindex="0"
              name="billable"
              checked="checked"
              value="officer"
              v-model="jobMeta.jobType"
            />
          </div>
        </div>
        <div class="flex">
          <div class="vaHack">
            <h2 style="margin: 0 20px">@</h2>
          </div>
          <div class="vaHack">
            <select id="pet-select" v-model="jobMeta.jobContext">
              <option value="" disabled>Select an context</option>
              <option
                v-for="(context, index) in contexts"
                :value="context"
                :key="index"
              >
                {{ context.foreign.name }}
              </option>
            </select>
          </div>
        </div>
      </div>

      <div class="section">
        <div class="textsTyping">
          <input
            class="plain"
            type="text"
            placeholder="title, e.g. Full-stack senior dev for agency: Python\Vue.js"
            v-model="jobMeta.jobName"
          />

          <div style="padding: 0 3rem">
            <wysiwyg
              v-model="jobMeta.jobDescription"
              placeholder="description"
            ></wysiwyg>

            <div class="flex start">
              <div class="vaHack">
                <h5>rythm</h5>
              </div>
              <div class="vaHack">
                <div class="form__radios horizontal">
                  <div class="form__radio active">
                    <label for="group-sort">one-time hire</label>
                    <input
                      type="radio"
                      name="billable"
                      tabindex="0"
                      value="one_time"
                      v-model="jobMeta.jobRhythm"
                    />
                  </div>
                  <div class="form__radio">
                    <label for="date-sort">ongoing hiring (n+1)</label>
                    <input
                      type="radio"
                      tabindex="0"
                      name="billable"
                      checked="checked"
                      value="ongoing"
                      v-model="jobMeta.jobRhythm"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="section" style="border-color: crimson">
        <h2 style="text-align: right; color: #7b8f50">
          incentives
        </h2>

        <div class="flex">
          <div class="">
            <h5>coin</h5>
            <input
              class="plain"
              type="text"
              placeholder="e.g. $1X,XXX or $1X \ hour"
              v-model.number="jobMeta.jobBudget"
            />
            <p class="muted">
              Obfuscate some numbers
            </p>
          </div>

          <div class="">
            <h5>ownership</h5>

            <input
              id="OwnershipEquity"
              type="checkbox"
              checked="checked"
              v-model="jobMeta.jobOwnershipEquity"
            />
            <label for="OwnershipEquity">equity</label>

            <input
              id="ownershipProfitSharing"
              type="checkbox"
              checked="checked"
              v-model="jobMeta.jobOwnershipProfitSharing"
            />
            <label for="ownershipProfitSharing">profit sharing</label>
          </div>

          <div class="">
            <h5>fame</h5>

            <input
              id="NDA"
              type="checkbox"
              checked="checked"
              v-model="jobMeta.jobNDA"
            />
            <label for="NDA">NDA</label>

            <input
              id="contributorsFame"
              type="checkbox"
              checked="checked"
              v-model="jobMeta.jobContributorsFame"
            />
            <label for="contributorsFame">@contributor's fame</label>
          </div>
        </div>
      </div>

      <div class="section">
        <h2 style="text-align: center">
          census
        </h2>

        <div class="flex">
          <div class="w50">
            <wysiwyg
              v-model="jobMeta.jobCensus"
              placeholder="any discrimination criteria? e.g. citizenship, education, experience, etc"
            ></wysiwyg>
          </div>

          <div style="max-width: 300px">
            <h5>location</h5>
            <div class="form__radios">
              <div class="form__radio active">
                <label for="group-sort">remote</label>
                <input
                  type="radio"
                  name="location"
                  tabindex="0"
                  value="remote"
                  v-model="jobMeta.jobLocation"
                />
              </div>
              <div class="form__radio">
                <label for="date-sort">specific city</label>
                <input
                  type="radio"
                  tabindex="0"
                  name="location"
                  checked="checked"
                  value="specific_city"
                  v-model="jobMeta.jobLocation"
                />
              </div>
              <div v-if="jobMeta.jobLocation == 'specific_city'">
                <input
                  type="text"
                  placeholder="What city?"
                  v-model="jobMeta.jobCity"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="source">
        <h3 class="jobs__section">
          source
        </h3>

        <div class="">
          <div class="">
            <input
              type="checkbox"
              name="pt"
              id="pt3"
              v-model="jobMeta.jobAskNetworkIntro"
            />
            <label for="pt3">Ask network for the intro</label>

            <div v-if="jobMeta.jobAskNetworkIntro">
              <p>As a thank you:</p>
              <div class="form__radios">
                <div class="form__radio active">
                  <label for="group-sort">hme yearly subscription</label>
                  <input
                    type="radio"
                    value="yearly"
                    v-model="jobMeta.jobNetworkIntroType"
                  />
                </div>
                <div class="form__radio">
                  <label for="date-sort">first month salary</label>
                  <input
                    type="radio"
                    value="first_month"
                    v-model="jobMeta.jobNetworkIntroType"
                  />
                </div>
                <div class="form__radio">
                  <label for="date-sort">hard cash: XXX</label>
                  <input
                    type="radio"
                    value="hard_cash"
                    v-model="jobMeta.jobNetworkIntroType"
                  />
                </div>
                <div class="form__radio">
                  <label for="date-sort">apollo's art shop item</label>
                  <input
                    type="radio"
                    value="art_shop_item"
                    v-model="jobMeta.jobNetworkIntroType"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="">
            <div class="vaHack">
              <input
                type="checkbox"
                name="pt"
                id="pt2"
                checked="checked"
                v-model="jobMeta.jobSendToPlato"
              />
              <label for="pt2">Post to Plato's Flywheel</label>
            </div>

            <div style="margin-top: 1rem">
              <h5 class="muted">coming soon:</h5>

              <div class="vaHack">
                <input type="checkbox" name="sg" id="sg1" disabled />
                <label for="sg1">Publish to Indeed</label>
              </div>

              <div class="vaHack">
                <input type="checkbox" name="sg" id="sg2" disabled />
                <label for="sg2">Publish to 30+ Remote Job Boards</label>
              </div>

              <div class="vaHack">
                <input type="checkbox" name="sg" id="sg3" disabled />
                <label for="sg3">Publish to Upwork</label>
              </div>

              <div class="vaHack">
                <input type="checkbox" name="sg" id="sg4" disabled />
                <label for="sg4">Publish to LinkedIn</label>
              </div>
            </div>

            <!--<p>find english speaking local talent</p>-->
          </div>

          <div class="">
            <h4>done-for-you</h4>

            <div class="vaHack">
              <input
                type="checkbox"
                v-model="jobMeta.jobTalentAugmentation"
                name="cb"
                id="taAug"
                checked="checked"
              />
              <label for="taAug">Try talent augmentation broker</label>
            </div>

            <!-- TODO -->

            <input
              type="text"
              v-model="jobMeta.jobTalentBounty"
              placeholder="what's the cash $$ bounty?"
            />

            <br /><br />

            <p>I'm ready to consider:</p>

            <div class="vaHack">
              <input
                type="checkbox"
                name="cb"
                id="cb1"
                checked
                v-model="jobMeta.jobTalentOutstaffing"
              />
              <label for="cb1">outstaffing</label>
            </div>

            <div class="vaHack">
              <input
                type="checkbox"
                name="cb"
                id="cb2"
                checked
                v-model="jobMeta.jobTalentAgencies"
              />
              <label for="cb2">agencies</label>
            </div>
          </div>
        </div>
      </div>


        <div id="postGigSummary" class="flex">
          <div>assign inhouse owner</div>
          <a
            class="hm button core moneta grin mrgn0"
            @click.prevent="saveJob()"
          >
            ok. take my $19 and send for review</a
          >
        </div>

    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import wysiwyg from '@/_editr/wysiwyg';

import jobsToBeFilledUtils from './jobsToBeFilledUtils';

export default {
  name: 'jobsToBeFilledAddNewModal',
  props: {
    isBare: {
      type: Boolean,
      default: false,
    },
    contexts: {
      type: Array(),
      default: () => {
        return [];
      },
    },
    jobMeta: {
      type: Object,
      default: () => {
        return jobsToBeFilledUtils.createJobMeta();
      },
      required: false,
    },
  },
  components: {
    wysiwyg,
  },
  data() {
    return {
      isModalModify: false,
      layersApiErrorMsg: 'Layers api is unavailable at the moment',
      raysApiErrorMsg: 'Rays api is unavailable at the moment',
    };
  },
  computed: {
    apiUrl: {
      get: function() {
        var url = window.apiRoot + '/api/layers/';
        return url;
      },
      set: function(newVal) {
        var url = window.apiRoot + '/api/layers/' + newVal;
        return url;
      },
    },
  },
  mounted() {},
  methods: {
    clearModal() {
      jobsToBeFilledUtils.clearJobMeta(this.jobMeta);
    },
    openModal() {},
    closeModals() {
      this.closeModal(); // not sure if we really need closeModal function
      if (this.$parent.$options.name != 'broker') {
        this.$root.$emit('close-modals');
      } else {
        // we had to reset this visibility flag manually cause there's a conflict between two modals
        this.$parent.isJobModalActive = false;
      }
    },
    closeModal() {
      if (this.isBare) {
        // @TODO: this is cryptic, test it and get rid of classes toggle
        document.querySelector('#recruitModal').classList.toggle('active');
      }
    },
    modifyJob(jobId) {
      let url = (this.apiUrl += 'jobs/' + jobId);
      this.$http
        .get(url)
        .then(response => {
          jobsToBeFilledUtils.deserializeJobMeta(
            response.data.job,
            this.jobMeta,
          );
          this.$parent.openModal();
          this.isModalModify = true;
        })
        .catch(error => {
          console.log([this.layersApiErrorMsg, error]);
        });
    },
    saveJob() {
      let data = jobsToBeFilledUtils.serializeJobMeta(this.jobMeta);
      if (this.isModalModify) {
        let url = (this.apiUrl += 'jobs/' + this.jobMeta.jobId);
        this.$http
          .patch(url, data)
          .then(() => {
            this.$forceUpdate();
            if (!this.isBare) {
              this.$parent.closeModal();
              this.$parent.getJobs();
            }
          })
          .catch(error => {
            console.log([this.layersApiErrorMsg, error]);
          });
      } else {
        let url = (this.apiUrl += 'jobs');
        this.$http
          .post(url, data)
          .then(() => {
            let moderatorId = 'Pl5qoln';
            let messageUrl = url.replace('layers/jobs', 'rays/direct/message');
            if (data.sendToPlato) {
              let messageBody =
                `Name is ${data.jobName}, ` +
                `description:\n${data.jobDescription}`;
              let messageData = {
                subject: 'New job on plato request!',
                body: messageBody,
                user_to: moderatorId,
              };
              this.$http
                .post(messageUrl, messageData)
                .then(res => {
                  this.closeModals();
                  this.clearJobMeta();
                  this.$parent.getJobs();
                  console.log(res);
                })
                .catch(error => {
                  console.log([
                    this.raysApiErrorMsg + ' (archive message)',
                    error,
                  ]);
                });
            }
            if (data.talentAugmentation) {
              let messageBody =
                `Name is ${data.jobName},\n` +
                `Bounty ${data.talentBounty}\n` +
                `Augmentation ${data.talentAugmentation}\n` +
                `Outstaffing ${data.talentOutstaffing}\n` +
                `Agencies ${data.talentAgencies}\n`;

              let messageData = {
                subject: 'New Talent Augmentation request!',
                body: messageBody,
                user_to: moderatorId,
              };

              this.$http
                .post(messageUrl, messageData)
                .then(res => {
                  this.closeModals();
                  this.clearModal();
                  this.$parent.getJobs();
                  console.log(res);
                })
                .catch(error => {
                  console.log([
                    this.raysApiErrorMsg + ' (archive message)',
                    error,
                  ]);
                });
            }
            this.$forceUpdate();
            if (!this.isBare) {
              this.closeModals();
              this.clearModal();
              this.$parent.getJobs();
            }
          })
          .catch(error => {
            console.log([this.layersApiErrorMsg, error]);
          });
      }
      this.closeModal();
      this.clearModal();
    },
  },
};
</script>
