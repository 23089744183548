<template>
  <div :class="!isBare && ['hm', 'modal', 'paper', 'profile-modal', 'active']">
    <div
      class="content"
      style="background: #F7F8FA;width:80%;left: 10%"
      v-on-clickaway="closeModals"
    >
      <h2 class="title">tune identity</h2>

      <avatar-cropper
        @uploading="startImageUpload"
        @completed="handleUploaded"
        @change="changeImage"
        :upload-url="avatarUploadUrl"
        :upload-file-field="avatarUploadName"
        :request-options="{
          headers: {'X-CSRFToken': csrfToken},
          method: 'POST',
        }"
        v-model="showAvatarCropper"
      />

      <div class="tabs" v-if="profile && profile.user">
        <input
          type="radio"
          name="howtoTabs"
          id="tabone1"
          checked="hotkeys"
          value="media buying"
          class="tabHackInput"
        />
        <label for="tabone1" class="tabHackLabel">about</label>
        <div class="tab">
          <div class="flex">
            <div class="w50">
              <div class="flex start">
                <div class="userpic">
                  <img :src="profile.logo" height="100px" />
                  <button
                    style="display: block"
                    type="button"
                    class="ui basic button"
                    @click="showAvatarCropper = !showAvatarCropper"
                    v-if="profile.is_owner"
                  >
                    upload <br />
                    userpic
                  </button>
                </div>

                <div class="column">
                  <h5>legal name</h5>
                  <input
                    class="plain"
                    type="text"
                    @blur="saveProfile"
                    v-model="userInfo.legalName"
                    v-if="profile.is_owner"
                  />
                  <div v-else>{{ userInfo.legalName }}</div>

                  <div class="flex" style="margin: 1rem 0">
                    <h5>display name:</h5>
                    <div class="vaHack">
                      <p class="mrgn0">{{ displayName }}</p>
                    </div>
                  </div>

                </div>
              </div>
              <!-- info ends -->


                  <h5>@handle</h5>
                  <input
                    class="plain"
                    type="text"
                    v-model="userInfo.handle"
                    @blur="saveProfile"
                    v-if="profile.is_owner"
                  />
                  <div v-else>{{ userInfo.handle }}</div>

                  <div id="identityLink">
                    <a
                      v-if="userInfo.handle !== ''"
                      v-bind:href="'https://holons.me/' + userInfo.handle"
                      class="hm link"
                      target="_blank"
                    >
                      holons.me/{{ userInfo.handle }}
                    </a>

                    <p class="muted">
                      core accounts have numbers in the URL
                    </p>
                  </div>

              <div style="border: 1px dashed red; margin: 1rem; padding: 1rem">
                // punchline
              </div>
            </div>

            <div class="toggles w50">
              <p class="crimson">exp engine entry point attachment</p>
              <p class="crimson">community engine entry point attachment</p>

              <h2 style="text-align: right; margin: 2rem">buttons</h2>

              <div class="flex">
                <div class="vaHack">
                  <a class="hm button core teleport">chat</a>
                </div>
                <p class="muted mrgn0">Enabled by default. <br>
                  Manage it through privacy tab</p>
              </div>

              <div class="flex">
                <div class="vaHack">
                  <a class="hm button core rayme">message</a>
                </div>
                <div class="vaHack">
                  <p class="crimson mrgn0">conversation starters @rays</p>
                </div>
              </div>

              <div class="flex mrgn0">
                <div class="vaHack">
                  <a class="hm button core meet">meet</a>
                </div>
                <p class="muted mrgn0">
                  <del>appointment scheduling widget</del> <br>
                  might be coming soon</p>
              </div>
            </div>
          </div>

          <div id="identityIntro" v-if="!isBare">
            <p>
              Родом и сердцем из Петербурга, получил образование в Финляндии
              \Швеции, гражданство в Израиле, работал сначала офлайн в
              Барселоне, Панаме, а позже и людьми со всех концов света когда
              пришел в диджитал.
            </p>

            <p>✍️ Руками умею в</p>

            <ul>
              <li>дизайн и продукт (верстаю сразу руками, без макетов),</li>
              <li>
                маркетинг (в основном SEO, paid и всякие нетривиальные штуки –
                например распарсил Редит и строю свою Кембридж Аналитику)
              </li>
              <li>продажи (оч люблю встречи, хоть они теперь и в зуме)</li>
              <li>
                🤙 Диджитал – ван лав – люблю продуктовую разработку и агентский
                бизнес.
              </li>
            </ul>

            <p>
              Сделал агентство GRIN tech и ряд productised сервисов вроде аутрич
              платформы, приуныл что с количеством заказов растет количество
              хаоса, и решил сделать платформу для управления проектами вместо
              закидывания проблемы людьми.
            </p>

            <p>
              Поэтому сейчас занимаюсь собственной продуктовой разработкой,
              работаю проджектом в одном израильском агентстве, делаю несколько
              клиентских проектов и в целом наслаждаюсь нашей индустрией.
            </p>

            <p>За пределами работы:</p>

            <ul>
              <li>👉 КМС по гребле на байдарках,</li>
              <li>
                👉 люблю серф и хорошую литературу (на ум сразу приходят: Смерть
                Артемио Круса, Атлант Расправил Плечи, Сироп Макса Бари и другие
                его книги, Задача Трех Тел Лю Цысиня, Возращение со Звезд Лема)
              </li>
            </ul>

            <p>🤘Рад помочь и делом и советом (:</p>
          </div>
        </div>

        <input
          type="radio"
          name="howtoTabs"
          id="tabtwo2"
          value="support"
          class="tabHackInput"
        />
        <label for="tabtwo2" class="tabHackLabel">pick languages</label>
        <div class="tab">
          <p class="crimson">//lang component</p>
        </div>

        <input
          type="radio"
          name="howtoTabs"
          id="tabtwo3"
          value="support"
          class="tabHackInput"
        />
        <label for="tabtwo3" class="tabHackLabel">upd city</label>
        <div class="tab">
          <p class="crimson">//city component</p>
        </div>

        <input
          type="radio"
          name="howtoTabs"
          id="tabtwo4"
          value="support"
          class="tabHackInput"
        />
        <label for="tabtwo4" class="tabHackLabel">define privacy</label>
        <div class="tab">
          <p>
            My profile must be
            <select id="platformSearch">
              <option value="" selected>searchable</option>
              <option value="">not searchable</option>
            </select>
            on the holons.me platform.
          </p>

          <p>
            My profile must be
            <select id="platformSearch">
              <option value="" selected>visible</option>
              <option value="">not visible</option>
            </select>
            on search engines.
          </p>

          <div class="meet__mc--item">
            <h5>inbox</h5>
            <i class="circle outline icon"></i>
            <select class="hm legal" id="pet-select">
              <option value="" selected
                >everyone w/ core or better account</option
              >
              <option value=""
                >everyone w/ classic or better account</option
              > </select
            >can ray me through
            <span>
              <i class="circle outline icon"></i>
              everyone can start a teleport chat w/ me</span
            >
          </div>
        </div>
      </div>

      <!--
      <div class="" v-if="isBare">
        <div>
          <div
            id="card"
            v-if="!$accountIsPaid"
            style="
                max-width: 280px;
                border: 1px solid #23272B;
                border-radius: 15px;
                min-height: 150px;
                box-shadow: 0px 0px 5px 0px rgb(35 39 43 / 10%), 0px 0px 1px 0px rgb(35 39 43 / 10%);
            "
          >
            <div class="card-holder">
              <i class="circle outline icon"></i> core experience
            </div>

            <p
              class="mrgn0"
              style="
                padding-left: 2rem;
                padding-right: 1rem;
                font-size: 14px;
              "
            >
              //if no subscription
                {{ balance_data.currency }} {{ balance_data.period_pay }} \ {{ balance_data.period }} <br><br>

              your bill is paid by
              <a class="hm link" href="/grintender">@grintender</a> <br />
              untill you start working with others
            </p>
          </div>
        </div>
        <div id="what345" v-if="!$accountIsPaid">
          <h3>$14 / user monthly</h3>
          <div
            style="
                border:2px solid;
                width:100%;height:30px;
                width:100%
            "
          ></div>
          <a class="hm button core">subscribe in 1 click</a>
          <p class="muted mrgn0">//cancel in two :D</p>
        </div>
      </div>-->
      <!-- six wide column ends -->

      <div v-else-if="profile && profile.entity">
        <div class="">
          <div class="avatar-upload" style="position:relative">
            <div class="text-center p-2">
              <label for="avatar">
                <img
                  :src="profile.logo"
                  style="
                           width: 100px;
                           height: auto;margin-right: 15px
                           "
                />
              </label>
            </div>
            <br /><br />
            <div class="flex">
              <button
                type="button"
                class="ui basic button"
                @click="showAvatarCropper = !showAvatarCropper"
                style="
                         position: absolute;
                         bottom: 0;
                         background: #fff !important
                        "
                v-if="profile.is_owner"
              >
                upload
              </button>
            </div>
          </div>
        </div>

        <div class="vaHack" style="width: 100%;">
          <div id="profileBase">
            <div class="item flex">
              <div class="vaHack">
                <h5>Entity title</h5>
              </div>

              <input
                type="text"
                @blur="updateEntityProfile"
                v-model="profile.entity.name"
                v-if="profile.is_owner"
              />
              <div v-else>{{ profile.entity.name }}</div>
            </div>

            <div class="item flex">
              <div class="vaHack">
                <h5>description</h5>
              </div>
              <input
                type="text"
                @blur="updateEntityProfile"
                v-model="profile.entity.description"
                v-if="profile.is_owner"
              />
              <div v-else>{{ profile.entity.description }}</div>
            </div>

            <div class="item flex">
              <div class="vaHack">
                <h5>slug</h5>
              </div>
              <input
                type="text"
                @blur="updateEntityProfile"
                v-model="profile.slug"
                v-if="profile.is_owner"
              />
              <div v-else>{{ profile.slug }}</div>
            </div>

            <a
              v-if="profile.slug !== ''"
              class="hm link"
              :href="`https://holons.me/${profile.slug}`"
              target="_blank"
            >
              holons.me/{{ profile.slug }}
            </a>
          </div>
        </div>
      </div>
    </div>
    <!-- content ends -->
  </div>
  <!-- modal ends -->
</template>

<script>
import {mixin as clickaway} from 'vue-clickaway';

import AvatarCropper from 'vue-avatar-cropper';
import {identityBus} from '../bus';

import {holonsHeaders, getCookie} from '@/utils/globals';
import {createBase64Image} from '../../utils/images';

export default {
  name: 'profileEdit',
  mixins: [clickaway],
  components: {
    AvatarCropper,
  },
  props: {
    profile: {
      type: Object,
    },
    isBare: { default: false },
  },
  data() {
    return {
      showAvatarCropper: false,
      holonsHeaders,

      userInfo: {
        legalName: '',
        displayName: '',
        handle: '',
      },
      defaultUserpic: null,
      bus: identityBus,
    };
  },
  watch: {
    displayName(newVal) {
      if (
        !this.userInfo.handle ||
        this.userInfo.handle.includes('holons_soul')
      ) {
        this.userInfo.handle = newVal
          .replace(' ', '-')
          .replace('.', '')
          .toLowerCase();
      }
    },
  },
  computed: {
    /**@returns {String} */
    displayName() {
      let result = '';
      if (this.userInfo.legalName) {
        result = this.userInfo.legalName.split(' ');

        if (result[result.length - 1] !== '' && result[1]) {
          result = result[0] + ' ' + result[result.length - 1][0] + '.';
        } else if (
          result[result.length - 1] === '' &&
          result[result.length - 2] !== '' &&
          result[1]
        ) {
          result = result[0] + ' ' + result[result.length - 2][0] + '.';
        } else {
          result = result[0];
        }
      }
      return result;
    },
    /**@returns {String} */
    avatarUploadUrl() {
      return this.profile.entity
        ? `/api/accounts/profiles/${this.profile.entity.id}/logo-upload/`
        : '/api/accounts/upload';
    },
    /**@returns {String} */
    avatarUploadName() {
      return this.profile.entity ? 'logo' : 'userpic';
    },

    /**@returns {String} */
    csrfToken: {
      get(){
        return this.getCookie('csrftoken')
      },
      cache: false
    }
  },
  mounted() {
    this.$root.$on('close-modals', this.closeModals);
    this.userInfo.legalName = this.$legalName;
    this.userInfo.handle = this.$handle;
  },
  methods: {
    getCookie,

    closeModals() {
      this.$parent.profileEditModalActive = false;
    },
    saveProfile() {
      let url = `/api/accounts/edit`;
      let data = {
        username: this.userInfo.legalName.trim(),
        handle: this.userInfo.handle,
        display_name: this.displayName,
      };
      this.$http
        .post(url, data)
        .then(response => {
          if (response) {
            this.userInfo.handle = response.data.user.handle;
            if (this.$handle !== response.data.user.handle && !this.isBare) {
              this.$router.push(`/${response.data.user.handle}/`);
            }

            this.profile.slug = this.$handle = response.data.user.handle;
            Object.assign(this.profile.user, response.data);
            this.$legalName = response.data.user.username;
            this.profile.name = response.data.user.display_name;

            this.$emit('user-details-change', this.profile.user)
          }
        })
        .catch(({response}) => {
          if (response?.status == 400) {
            if (response.data.handle) {
              this.userInfo.handle = this.$handle;
            }
          }
        });
    },

    async startImageUpload(evt) {
      this.profile.logo = await createBase64Image(
        evt.form.get(this.avatarUploadName),
      );
    },
    handleUploaded(e) {
      this.profile.logo = e.response.url;
      this.$emit('logo-updated');
    },
    changeImage() {},

    updateEntityProfile() {
      Promise.all([
        this.$http.patch(
          `/api/accounts/profiles/${this.profile.id}/?by_id=True`,
          {
            slug: this.profile.slug,
          },
        ),
        this.$http.patch(
          `/api/moneta/business-entities/${this.profile.entity.id}/`,
          {
            name: this.profile.entity.name,
            description: this.profile.entity.description,
          },
        ),
      ]).then(() => {
        this.profile.name = this.profile.entity.name;

        if(!this.isBare){
          this.$router.push(`/${this.profile.slug}/`);
        }
      });
    },
  },
};
</script>

<style scoped></style>
