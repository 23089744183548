<template>
    <div id="sidebar">
      <div id="myGoodSelf">
        <div
          id="personificationsHandle"
          v-if="impersonate_accounts !== false"
          @click.prevent="toggleImpersonationMenu"
          class="ui dropdown icon item"
        >
          <i class="icon-user"></i>
        </div>
        <div id="personificationsMenu" class="menu" v-if="showImpersonationMenu">
          <div
            v-for="item in impersonate_accounts"
            :key="item.phone_number"
            class="item"
          >
            <a
              :href="'/profile/impersonate/' + item.phone_number + '?redirect_to=' + redirectTo"
            >
              {{ item.username }}
            </a>
          </div>
          <div class="item">
            <a href="/logout">[logout]</a>
          </div>
        </div>
        <router-link
          :to="'/'+$handle"
          v-slot="{ navigate }"
          custom
        >
          <a :href="'/'+$handle" @click="navigate">
            <img :src="$userpicScaled" class="identity__img" />
          </a>
        </router-link>

        <router-link
          :to="'/'+$handle"
          v-slot="{ navigate }"
          custom
        >
          <a
            :href="'/'+$handle"
            @click="navigate"
            class="account__status"
            :class="$accountStatus"
          >
            {{ $accountStatus }}
          </a>
        </router-link>
      </div>

      <div id="items">
        <router-link to="/rays"
          v-slot="{ navigate, isActive, isExactActive }"
          custom>
          <a href="/rays">
            <div class="item" id="tabRays" @click="navigate"
            :class="[ isActive && 'active', isExactActive && 'active']">
              <span class="hot-key">or press 1</span>
              <div class="notifications-count">{{ rays_count }}</div>
              <del>rays</del>
            </div>
          </a>
        </router-link>

        <router-link to="/teleport"
          v-slot="{ navigate, isActive, isExactActive }"
          custom>
          <a href="/teleport">
            <div class="item" @click="navigate"
              :class="[ isActive && 'active', isExactActive && 'active']">
              <span class="hot-key">2</span>
              <div
                v-if="$root.teleportBus"
                class="notifications-count"
                id="teleport-notification"
              >
                {{ $root.teleportBus.rooms.filter(r => r.unreadCount).length }}
              </div>
              teleport
            </div>
            <audio style="position: absolute; opacity: 0" id="new-teleport-message" src="/static/app/audio/message.wav" />
          </a>
        </router-link>

        <router-link to="/mail"
          v-slot="{ navigate, isActive, isExactActive }"
          custom>
          <a href="/mail">
            <div class="item" @click="navigate"
              :class="[ isActive && 'active', isExactActive && 'active']">
              <span class="hot-key">3</span>
              <div class="notifications-count">99</div>
              <del>mail</del>
            </div>
          </a>
        </router-link>

        <router-link to="/schedule"
          v-slot="{ navigate, isActive, isExactActive }"
          custom>
          <a href="/schedule">
            <div class="item" @click="navigate"
              :class="[ isActive && 'active', isExactActive && 'active']">
              <span class="hot-key">4</span>
              <div class="notifications-count">{{ meets_count }}</div>
              <del>schedule</del>
            </div>
          </a>
        </router-link>

        <a id="magic1_start_link">
          <div class="item">
            <span class="hot-key">5</span>
            <del>magic1</del>
          </div></a>

        <a
          @click.prevent="toggleHowToPlayModal()"
          class="sidebarAlso button"
        >
          how to play</a
        >
      </div>

      <div id="timeTracker">
        <time-tracker-sidebar />
        <time-tracker-modal />
      </div>

      <div
        id="pagination"
        @mouseenter="() => { showCursor = true }"
        @mouseleave="() => { showCursor = false }"
        @mousemove="followCursor"
      >
        <router-link
          v-for="ad, i in arrowsDots"
          :key="i"
          :to="ad.link"
          v-slot="{ navigate }"
          custom
        >
          <a
            :href="ad.link"
            :class="[{ horizontal: ad.horizontal }, {active: activeArrowDotIndex === i }, 'flex']"
          >
            <button
              :id="ad.id"
              @click="navigate"
              :class="{ active: activeArrowDotIndex == i }"
              role="link"
            ></button>
            <span class="arrow-dot-label">{{ ad.label || ad.id }}</span>
          </a>
        </router-link>
      </div>
      <div
        id="pagination-cursor"
        v-if="showCursor"
        :style="{ left: cursorCoords.left + 'px', top: cursorCoords.top + 'px' }"
      />

      <!--

          <div id="rengaFeatureSidebar" class="gradient-border vaHack"
          onclick="document.querySelector('#rengaModal').classList.toggle('active')">

          <p class="muted">
            Renga – (連歌, linked verse) <br>
            is collaborative poetry <br>
            in which units are linked in succession <br>
            by multiple poets.</p>

          <b>RENGA nodes</b>

          <p class="muted">– source ownership & collaboration <br>
            for designers, coders and hustlers.
            <a class="hm link inverted" style="position:relative">
              <div id="rengaFeatureDot" class="gradient-border"></div> Explore
            </a>
          </p>

        </div>-->
    </div>


</template>
<script>
import timeTrackerSidebar from '@/3layers/time/_mercurySidebar';
import timeTrackerModal from '@/3layers/time/tracker/logTimeEntryModal';

export default {
  name: 'mercurySidebar',
  components: {
    timeTrackerSidebar: timeTrackerSidebar,
    timeTrackerModal: timeTrackerModal,
  },
  data() {
    return {
      redirectTo: '',
      showImpersonationMenu: false,
      activeArrowDotIndex: 0,

      showCursor: false,
      cursorCoords: { left: 0, top: 0 }
    };
  },
  computed: {
    impersonate_accounts: function() {
      return window.impersonate_accounts;
    },
    rays_count: function() {
      return window.rays_count;
    },
    meets_count: function() {
      return window.meets_count;
    },
    arrowsDots() {
      // dots visible for everyone
      const dots = [
        {id: 'book', link: '/', exact: true, horizontal: true},
        {id: 'platos', link: '/platos-flywheel'},
      ]

      if(
        window.user_id != 'anonymous' &&
        ['core', 'classic', 'gold'].includes(window.account_status)
      ){
        // Auth user minimum with core account eyes only
        dots.push(
          {id: 'identity', link: `/${this.$handle}`},
          {id: 'corex', link: '/agenda', label: 'core'},
          {id: 'fora', link: '/fora', horizontal: true},
          {id: 'digest', link: '/digest', horizontal: true},
          {id: 'faith', link: '/faith-VC', horizontal: true},
          {id: 'atoms', link: '/atoms'},
          {id: 'bits', link: '/bits'},
          {id: 'node', link: '/node'}
        )
      }

      // canvas explainer available for all users but goes last
      // dots.push({id: 'node', link: '/node', horizontal: true})

      if (window.user_id == 'VElJjlr') {
        // prepare classic layer through @grintender's hardcode
        dots.push(

          {id: 'root', link: '/root/create'}
          //{id: 'dark', link: '/dark'}
        )
      }

      return dots
    },
  },
  watch: {
    $route: {
      handler(to) {
        this.activateRoute(to);
      },
      immediate: true,
    },
  },
  mounted() {
    this.$root.$on('arrow-navigate-vert', this.navigateMercury);
  },
  methods: {
    toggleHowToPlayModal() {
      this.$root.$emit('toggle-how-to-play')
    },
    toggleImpersonationMenu() {
      this.showImpersonationMenu = !this.showImpersonationMenu;
    },
    customNavigate(url) {
      window.special = true;
      this.$parent.startPreloader();
      this.$router.push(url);
    },
    activateRoute(to) {
      if (to.meta.holonsNodeGroup) {
        this.redirectTo = to.fullPath;
        this.arrowsDots.forEach(({id, exact}, i) => {
          if (id === to.meta.holonsNodeGroup) {
            this.activeArrowDotIndex = i;
          }
        });
        const corexDot = this.arrowsDots.find(ad => ad.id === 'corex');
        if (this.activeArrowDotIndex == this.arrowsDots.indexOf(corexDot)) {
          corexDot.link = this.redirectTo;
          this.$forceUpdate();
        }
      }
    },
    navigateMercury(direction) {
      const lastDotIndex = this.arrowsDots.length - 1;

      let nextDotIndex = this.activeArrowDotIndex;
      if (direction == 1) {
        if (!this.activeArrowDotIndex) nextDotIndex = lastDotIndex;
        else nextDotIndex--;
      } else if (direction == -1) {
        if (this.activeArrowDotIndex == lastDotIndex) nextDotIndex = 0;
        else nextDotIndex++;
      }

      if (this.activeArrowDotIndex != nextDotIndex)
        this.$router.push(this.arrowsDots[nextDotIndex].link);
    },

    followCursor(e) {
      this.cursorCoords.left = e.clientX
      this.cursorCoords.top = e.clientY
    }
  },
};
</script>
