<template>
  <div>
    <input type="text" placeholder="button's text" v-model="button.text" />

    <input
      type="text"
      placeholder="button's .class"
      v-model="button.class"
    />

    <textarea
      name="name"
      placeholder="button's explainer"
      v-model="button.explainer"
    ></textarea>
  </div>
</template>

<script>
export default {
  props: ['button'],
};
</script>
