<template>
  <div>
    <div>
      <div id="subscriptionExplainer" class="flex">
        <div class="item">
          <h4>Talent</h4>

          <p>4 people x $14 = $52</p>

          <div
            class="people__checks"
            v-for="(user, index) in employeesExceptMe"
            :key="user.id"
          >
            <label :for="'u'+index">
              <img :src="user.userpic" />
              <span>{{ user.username }}</span>
            </label>
          </div>
        </div>

        <div class="vaHack">
          <h1>+</h1>
        </div>

        <div class="item">
          <h4>storage</h4>

          <p>Included: <b>15GB</b></p>
          <p>Used: <b>1GB</b></p>

          <p class="muted">
            If you happen to exceed that quota we'll charge $0.02 per extra GB
          </p>
        </div>

        <div class="vaHack">
          <h1>+</h1>
        </div>

        <div class="item">
          <h4>extra nodes</h4>

          <p>Plato's Flywheel...</p>
        </div>

        <div class="vaHack">
          <h1>=</h1>
        </div>

        <div class="item">
          <h4>$XXX</h4>

          <p v-if="!$accountIsPaid">
            <!-- if no subscription -->
            <!--
            THIS IS UNREACHABLE
            {{ balance_data.currency }} {{ balance_data.period_pay }} \ {{ balance_data.period }}
            <br><br>
            -->
            your bill is paid by
            <a class="hm link" href="/grintender">@grintender</a>
            untill you start working with others
          </p>

          <div v-else>
            <!-- if subscription exists -->
            <!--
                        You're using {{ balance_data.subscription_title }} <br>
                        next payment is on {{ balance_data.next_payment |date:'Y-m-d' }}  <br>

                        <button
                        class="ui basic button"
                        @click="subscriptionControlModalActive = !subscriptionControlModalActive">
                        Subscription details
                        </button>
            -->
            <p>Next bill in X days</p>

            <a id="cancelIdentity" class="ui basic button"> cancel subscription</a>
          </div>
        </div>
      </div>
    </div>
    <!-- subscription tab ends -->

  </div>
</template>
<script>
export default {
  name: 'subscriptionTab',
  data () {
    return {
      employees: [],
    };
  },
  computed: {
    employeesExceptMe() {
      return this.employees.filter(u => u.user_id != this.$userId);
    },
  },
  mounted () {
    this.getEmployees();
  },
  methods: {
    getEmployees() {
      fetch('/api/relations/commitments/all-employees/agenda/')
        .then(res => {
          if (!res.ok) {
            throw res;
          } else {
            return res.json();
          }
        })
        .then(res => {
          if (!res) return;

          this.employees = res;
          return res;
        });
    },
  },
};
</script>
