<template>
  <div class="wysiwyg-editor">
    <div
      ref="editorToolbar"
      class="wysiwyg-toolbar"
      v-show="!disabled && showToolbar"
    >
      <!--
                you can add buttons for any text formatting actions
                check https://quilljs.com/docs/modules/toolbar/#container
            -->

      <!-- all inline text operations -->
      <div v-show="innerToolbarOptions.includes('inline')">
        <button class="ql-bold">
          <!-- you can add any html for buttons -->
          <span>B</span>
        </button>
        <button class="ql-italic">
          <span>I</span>
        </button>
        <button class="ql-strike">
          <span>S</span>
        </button>
      </div>
      <!-- list -->
      <div v-show="innerToolbarOptions.includes('list')">
        <button
          class="ql-list"
          value="ordered"
          style="border-left: 1px solid #23272B"
        ></button>
        <button class="ql-list" value="bullet"></button>
      </div>

      <!-- text align-->
      <div v-show="innerToolbarOptions.includes('align')">
        <button class="ql-align" value=""></button>
        <button class="ql-align" value="center"></button>
        <button class="ql-align" value="right"></button>
      </div>

      <!-- media, image and video pasting -->
      <div v-show="innerToolbarOptions.includes('media')" style="width:100%">
        <button class="ql-link" @click.stop=""></button>
        <button class="ql-image"></button>
        <button class="ql-video"></button>
      </div>

      <!-- header -->
      <div v-show="innerToolbarOptions.includes('header')">
        <select class="ql-header">
          <option selected></option>
          <option value="2"></option>
          <option value="3"></option>
          <option value="4"></option>
          <!--<option value="5"></option>
                  <option value="6"></option>-->
        </select>
      </div>
    </div>
    <div ref="editorRoot"></div>

    <linksTooltip ref="tooltip" :editor="editor" v-if="editor"/>
  </div>
</template>

<script>
import Quill from 'quill';

import linksTooltip from './linksTooltip.vue'

const disableKeysListeners = evt => {
  if (evt.ctrlKey || evt.metaKey) return;

  evt.stopImmediatePropagation();
};

const defaultToolbarOptions = ['header', 'inline', 'list', 'align', 'media'];
const shortToolbarOptions = ['inline', 'list'];

export default {
  components: {
    linksTooltip
  },
  props: {
    value: [String, Object],
    disabled: {type: Boolean, default: false},
    toolbarOptions: {
      type: [String, Array],
      default() {
        return defaultToolbarOptions;
      },
    },
    showToolbar: { default: true },
  },
  data() {
    return {
      editor: null,

      showTooltip: false,
    };
  },
  watch: {
    disabled: {
      handler(val) {
        this.editor.enable(!val);
        if (!val) this.editor.focus();
      },
    },
    value: {
      handler(val) {
        this.setContents(val);
      },
    },
  },  
  computed: {
    /**@returns {Array}*/
    innerToolbarOptions() {
      return this.toolbarOptions == 'short'
        ? shortToolbarOptions
        : this.toolbarOptions;
    },
  },
  methods: {
    setContents(val) {
      if (this.getEditorContent() == val) return;

      let contents = val;
      if (typeof contents == 'string') {
        try {
          contents = JSON.parse(val);
        } catch {
          contents = this.editor.clipboard.convert(val);
        }
      }

      // console.log(contents);

      this.editor.setContents(contents, 'api');
    },

    onSelectionChange(range) {
      this.$emit('selection-change', range);
      if (!range) {
        this.$emit('blur');

        window.removeEventListener('keydown', disableKeysListeners, {
          capture: true,
        });
      } else {
        this.$emit('focus');

        window.addEventListener('keydown', disableKeysListeners, {
          capture: true,
        });
      }
    },
    getEditorContent(returnRaw) {
      const raw = this.editor.getContents();
      const contents = JSON.stringify(raw);
      return returnRaw ? {raw, contents} : contents;
    },
    // eslint-disable-next-line
    onTextChange(newVal, oldVal, source) {
      const {contents, raw: contentsObject} = this.getEditorContent(true);
      if (this.editor.getText().match(/\s+$/))
        this.$emit('change', {contents, contentsObject});

      this.$emit('input', contents);
    },

    parseIcons() {
      const quillIcons = Quill.import('ui/icons');

      this.$refs.editorToolbar.children.forEach(
        /** @param {HTMLDivElement} el*/ el => {
          el.children.forEach(
            /** @param {HTMLButtonElement} btn*/ btn => {
              if (btn.nodeName.toLowerCase() == 'button' && btn.innerHTML) {
                const actionName = btn.classList.item(0).replace('ql-', '');
                const actionSub = btn.value;
                if (actionSub)
                  quillIcons[actionName][actionSub] = btn.innerHTML;
                else quillIcons[actionName] = btn.innerHTML;
              }
            },
          );
        },
      );
    },
    mountQuill() {
      this.parseIcons();

      // HolonsIllustration is used to parse pictures with preloaders/nice blurred backgrounds
      var Embed = Quill.import('blots/embed');
      class HolonsIllustration extends Embed {
        static create(paramValue) {
          let node = super.create();
          node.innerHTML = paramValue;
          //node.setAttribute('contenteditable', 'false');
          //node.addEventListener('click', HolonsIllustration.onClick);
          return node;
        }

        static value(node) {
          return node.innerHTML;
        }
      }

      HolonsIllustration.blotName = 'holons-illustration';
      HolonsIllustration.className = 'holons-illustration';
      HolonsIllustration.tagName = 'holons-illustration';

      Quill.register(HolonsIllustration);

      this.editor = new Quill(this.$refs.editorRoot, {
        modules: {
          toolbar: this.innerToolbarOptions.length && {
            container: this.$refs.editorToolbar,
            handlers: { link: this.linkHandler }
          },
        },
        theme: 'snow',
        readOnly: this.disabled,
      });

      this.setContents(this.value);

      this.editor.on('selection-change', this.onSelectionChange);
      this.editor.on('text-change', this.onTextChange);

      this.loaded = true;
    },

    linkHandler(value){
      this.$refs.tooltip.$emit('create-link')
    },
    
  },
  mounted(){
    this.mountQuill()

    setTimeout(() =>{
      this.$refs.tooltip.$el.remove()
      this.$refs.editorRoot.appendChild(this.$refs.tooltip.$el)
    }, 100)
  },
};
</script>

<style>
.ql-toolbar.ql-snow + .ql-container.ql-snow {
  border-top: 1px solid #ccc !important;
}
.ql-tooltip{
  display: none;
}

/* .wysiwyg-editor{
  position: relative;
} */
</style>
