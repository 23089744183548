<template>
  <div id="heliosBlock" class="tip flex">
    <div class="vaHack">
      <h3 class="title">{{ header }}</h3>
    </div>
    <div class="vaHack" style="width: 100%">
      <div class="flex" style="justify-content: space-evenly">
        <pay-button
          v-for="(bttn, ind) in buttons"
          :key="ind"
          :is-recurrent="bttn.isRecurrent"
          :amount="bttn.amount"
          :currency="bttn.currency"
          :currency-sign="bttn.currencySign"
          :billing-period="bttn.billingPeriod"
          :billing-item="billingItem"
        />
      </div>
    </div>
  </div>
</template>

<script>
import payButton from './payButton';

export default {
  name: 'tipsOnetime',
  props: {
    buttons: {
      type: Array,
    },
    header: {
      type: String,
      default: 'tip',
    },
    billingItem: {
      type: Object,
    },
  },
  components: {
    payButton,
  },
  data() {
    return {};
  },
};
</script>
