<template>
  <div>
    <div id="sibarTimer">
      <div class="flex">
        <div class="vaHack">
          <!-- tracker btn -->
            <a
              id="ttracker-btn"
              class="ttracker-container unactive disabled"
              v-if="disabled === true && timerTicking === false">
              <span class="ttracker-status button"></span>
              <span class="ttracker-status-text"></span>
            </a>
            <a
              id="ttracker-btn"
              class="ttracker-container unactive"
              v-else-if="disabled === false && timerTicking === false">
              <span
                class="ttracker-status button"
                v-on:click.prevent="delayStartTimer"
              ></span>
              <span class="ttracker-status-text"></span>
            </a>
            <a
              id="ttracker-btn"
              class="ttracker-container active"
              v-else
            >
              <span
                class="ttracker-status button"
                v-on:click.prevent="showTimerModal"
              ></span>
              <span class="ttracker-status-text"></span>
            </a>
        </div>
        <div class="vaHack">
          <!-- time -->
            <router-link
                to="/layers/time"
                v-slot="{ href, route, navigate, isActive, isExactActive }"
                custom
                >
              <a
                  @click="navigate"
                  class="account__soul-col--item-container"
                  id="ttracker"
                  role="link">
                <span id ="timeSpentToday"
                 v-html="timeSpentTodayUpdated"
                ></span>
                <span class="ttracker-status-text"></span>
              </a>
            </router-link>
        </div>
      </div>
    </div>

  </div>
</template>


<script>
// eslint-disable-next-line
import bus from '@/utils/bus'

export default {
  name: 'timeTrackerSidebarComponent',

  components: {},

  props: {
  },

  data() {
    return {
      showModal: false,
      timerTicking: false,
      disabled: false,
      timerStatus: 0,
      timeSpentUpdated: '00:00',
      timeSpentTodayUpdated: '00:00'
    }
  },

  computed: {
    apiUrl: function () {
      var url = window.apiRoot
          url += '/api/'
      return url
    },
    csrfToken: function () { return window.csrftoken || this.getCookie('csrftoken') },
    userId: function () {
      return window.user_id
    },
    timerIsActive: function () {
      return window.timer_active
    },
    timeSpentToday: function () {
      return window.user_timer
    },
  },

  mounted() {

    if(window.user_logged_in){
      window.bus.$on("switch-timer-indicator-off", () => this.switchTimerIndicator())
      window.bus.$on("start-timer", (timeEntryTitleStr) => this.startTimer(timeEntryTitleStr))
      if(this.timerIsActive === false) {
          this.checkTimerStatus(true)
          this.timerTicking = false
      } else {
          this.timerTicking = true
          // this.timerStatus = setInterval(this.checkTimerStatus, 1000)
      }
      this.timeSpentUpdated = this.timeSpentToday
    }
  },

  methods: {
    delayStartTimer () {
      this.disabled = true
      this.timeout = setTimeout(() => {
        this.disabled = false
      }, 5000)
      this.startTimer()
    },
    startTimer (timeEntryTitleStr) {
      if (this.timerTicking === true) {
        window.bus.$emit("show-timer-modal", timeEntryTitleStr)
        return
      }
      this.$http.defaults.headers.common['X-CSRFToken'] = this.csrfToken
      let data = { 'user_id': this.userId, 'card_id': window.bus.cardTaskId }
      this.$http.post(this.apiUrl + 'timer/start', data).then(response => {
        this.timerTicking = true

        if(this.timerStatus === 0){
            this.timerStatus = setInterval(this.checkTimerStatus, 1000)
        }
        this.$forceUpdate()
        this.$nextTick(async () => {
          console.log(response.data.data)
        })
      }).catch(error => {
          console.log('Timer api is unavailable at the moment')
          console.log(error)
      });
    },
    switchTimerIndicator () {
      this.timerTicking = !this.timerTicking
    },
    showTimerModal () {
      window.bus.$emit("show-timer-modal")
    },
    beforeDestroy () {
     clearTimeout(this.timeout)
    },
    checkTimerStatus (getOnly=false) {
      if (!this.timerTicking) getOnly = true
      let data = { 'user_id': this.userId, 'get_only': getOnly }
      this.$http.defaults.headers.common['X-CSRFToken'] = this.csrfToken
      this.$http.post(this.apiUrl + 'timer/current-status/', data).then(response => {
          var rd = response.data
          this.timerTicking = rd.timer_ticking
          if(this.timerTicking === true){
            this.timeSpentUpdated = rd.time_spent_current_task.replace(':', '<span class="active-flashing">:</span>')
            this.timeSpentTodayUpdated = rd.time_spent_today_total.replace(':', '<span class="active-flashing">:</span>')
          } else {
            this.timeSpentUpdated = rd.time_spent_current_task
            this.timeSpentTodayUpdated = rd.time_spent_today_total
          }
          window.bus.currentTaskTime = rd.time_spent_current_task
          window.bus.$emit("update-timer-value",
            this.timeSpentUpdated,
            this.timeSpentTodayUpdated
          )
      })
    },
    getCookie(name) {
      let matches = document.cookie.match(new RegExp(
        // eslint-disable-next-line
        "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
      ));
      return matches ? decodeURIComponent(matches[1]) : undefined;
    },
  },
    watch: {
        timerTicking: function () {
            window.bus.timerTicking = this.timerTicking;
        }
    }
}
</script>
