<template>
  <div v-if="navVisible">
    <div id="layersNavigation" class="flex start">
      <router-link
        :to="{name: 'Talent'}"
        v-slot="{href, route, navigate, isActive, isExactActive}"
        custom
      >
        <div
          @click="navigate"
          :class="[
            true && 'layers__tabs--single talent',
            isActive && 'router-link-active active',
            isExactActive && 'router-link-exact-active active',
          ]"
          role="link"
        >
          <a id="talentTabLink" class="link-el">talent</a>
        </div>
      </router-link>

      <h2 class="divider muted">/</h2>

      <router-link
        :to="layersRoute"
        v-slot="{href, route, navigate, isActive, isExactActive}"
        custom
      >
        <div
          @click="navigate"
          :class="[
            true && 'layers__tabs--single ws',
            isActive && 'router-link-active',
            isExactActive && 'router-link-exact-active active',
          ]"
          role="link"
        >
          <a class="link-el">workspaces</a>

          <!--<div
                       class="fl-lines"
                       style="position: absolute;
                              left: -1px;
                              top: 7px;
                              border-left: 30px solid rgb(35, 39, 43);
                              height: 17vh;"
                  ></div>

                  <div
                       class="fl-lines"
                       style="position: absolute;
                              left: 149px;
                              top: 29px;
                              border-left: 30px solid rgb(35, 39, 43);
                              height: 14vh;"
                  ></div>-->
        </div>
      </router-link>

      <h2 class="divider muted">/</h2>

      <router-link
        :to="{name: 'TimeTracker'}"
        v-slot="{href, route, navigate, isActive, isExactActive}"
        custom
      >
        <div
          @click="navigate"
          :class="[
            true && 'layers__tabs--single time',
            isActive && 'router-link-active active',
            isExactActive && 'router-link-exact-active',
          ]"
          role="link"
        >
          <a id="timeTabLink" class="link-el">time</a>
        </div>
      </router-link>
    </div>
    <!-- layersNavigation ends -->
  </div>
</template>

<script>
export default {
  name: 'layersNavigation',
  computed: {
    platoBidsCount: function() {
      return window.platos_bids;
    },
    platoAccountIsPaid: function() {
      return window.plato_account_is_paid;
    },
    platosBidsThisWeek: function() {
      return window.platos_bids_this_week;
    },
    platosRepliesThisWeek: function() {
      return window.platos_replies_this_week;
    },

    layersRoute() {
      return {
        name: 'Layers',
        params: {cardOrProject: this.$route.params.cardOrProject},
      };
    },
  },
  watch: {
    $route(to){
      if(typeof(to.params.cardOrProject) !== 'undefined') {
        this.navVisible = false
      } else {
        this.navVisible = true
      }
    }
  },
  data() {
    return {
      navTimeOut: null,
      navVisible: true,
    };
  },
  mounted() {
    this.$root.$on('arrow-navigate', this.navigateLayers);
    if(typeof(this.$route.params.cardOrProject) !== 'undefined') {
      this.navVisible = false
    }
  },
  methods: {
    navigateLayers(direction) {
      if (direction === 1) {
        switch (this.$router.currentRoute.fullPath) {
          case '/layers/talent':
            this.$router.push('/layers');
            break;
          case '/layers':
            this.$router.push('/layers/time');
            break;
          case '/layers/time':
            this.$router.push('/layers/talent');
            break;
        }
      } else if (direction === -1) {
        switch (this.$router.currentRoute.fullPath) {
          case '/layers/time':
            this.$router.push('/layers');
            break;
          case '/layers':
            this.$router.push('/layers/talent');
            break;
          case '/layers/talent':
            this.$router.push('/layers/time');
            break;
        }
      }
    },
  },
};
</script>
