<template>
  <div>
    <div id="identityTabs" class="flex">

      <div class="flex" style="margin-bottom: -5px">
        <!--<div class="vaHack">
          <h1 v-html="bus.activeProfile.name"></h1>
        </div>

        <div class="vaHack" v-if="bus.activeProfile.user && bus.activeProfile.user.is_verified">
          <span class="identityVerified">
            <icon name="verified-user" />
          </span>
        </div>-->
      </div>

      <div class="vaHack">
        <div class="hm menu">

            <a
              class="item"
              v-if="isOwner"
              @click.prevent="bus.$emit('navigate-identity', bus.activeProfile, 'stats')"
            >stats</a>
            <a
              class="item"
              v-if="isOwner"
              @click.prevent="bus.$emit('navigate-identity', bus.activeProfile, 'collateral')"
            >collateral</a>
            <a
              v-if="$accountIsPaid"
              :class="{'item': true, 'active': $parent.activeTab === 'subscription'}"
              @click.prevent="bus.$emit('navigate-identity', bus.activeProfile, 'subscription')"
            >
              subscription
            </a>
          <a
            class="item"
            :class="{'active': activeTab === 'words'}"
            @click.prevent="bus.$emit('navigate-identity', bus.activeProfile, 'words')"
            >words</a
          >
          <a
            class="item"
            :class="{'active': activeTab === 'deeds'}"
            @click.prevent="bus.$emit('navigate-identity', bus.activeProfile, 'deeds')"
            >deeds</a
          >
          <a
            class="item"
            :class="{'active': activeTab === 'social'}"
            @click.prevent="bus.$emit('navigate-identity', bus.activeProfile, 'social')"
            >social</a
          >
          <a 
            class="item"
            :class="{'active': activeTab === 'gigs'}"
            @click.prevent="bus.$emit('navigate-identity', bus.activeProfile, 'gigs')"
          >gigs</a>

        </div>
      </div>

    </div>
  </div>
</template>

<script>
import { identityBus } from '../bus';
import icon from '@/global/includes/icons'


export default {
  name: 'profileNav',
  props: ['activeTab', 'entityType', 'isOwner'],
  components: { icon },
  data(){
    return {
      bus: identityBus
    }
  }
};
</script>
