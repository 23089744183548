<template>
  <div>
    <div id="purposeWrap">

    <purpose-nav />

      <div id="marketsGame">

            <h1 style="text-align:right;font-size: 4rem">Markets</h1>
            <p class="muted" style="text-align:right;margin-bottom:4rem">
              World's GDP is ~$70 Trillion</p>


            <div class="flex">
              <div class="ok">
                <h2>Industries</h2>
              </div>

              <div style="width:100%; text-align: right">
                <div class="item">Digital</div>
                <div class="item">Atoms</div>
                <div class="item">Finance</div>
                <div class="item">Talent</div>
                <div class="item">Energy</div>
                <div class="item">Trade &amp; Matchmaking</div>
                <div class="item">Food</div>
                <div class="item">Logistics</div>
                <div class="item">Entertainment</div>
                <div class="item">Marketing</div>
                <div class="item">Communications &amp; Insrastructure</div>
              </div>
            </div>

            <div class="flex">
              <div class="ok">
                <h2>Cities</h2>
                <p class="muted">All cities account for ~80% of global GDP.
                  Top 600 cities make ~50%.</p>
              </div>

              <div style="width:100%; text-align: right">
                <div class="item">LA</div>
                <div class="item">Saint-Petersburg</div>
                <div class="item">Петербург</div>
                <div class="item">Buenos Aires</div>
                <div class="item">LA</div>
                <div class="item">Saint-Petersburg</div>
                <div class="item">Петербург</div>
                <div class="item">Buenos Aires</div>
                <div class="item holder">2700+</div>
              </div>
            </div>

            <div class="flex">
              <div class="ok">
                <h2>Technology <br>
                  & Value Chains</h2>
              </div>

              <div style="width:100%; text-align: right">
                <div class="item">chips</div>
                <div class="item">Value chains #1</div>
                <div class="item">Value chains #2</div>
                <div class="item">Value chains #3</div>
                <div class="item">Value chains #4</div>
                <div class="item holder">7700+</div>
              </div>
            </div>

            <div class="flex">
              <div class="ok">
                <h2>regions</h2>
              </div>

              <div style="width:100%; text-align: right">
                <div class="item">English</div>
                <div class="item">Spanish</div>
                <div class="item">Russian</div>

                <h5 style="display:inline-block">et al.</h5>
              </div>
            </div>

            <div id="marketsGameArch" class="man-item classic flex mrgn0 archFame">

                <h5>enroll <br>
                  today</h5>

                    <div class="vaHack">
                        <div class="label">
                          requires <br>
                          classic <br>
                          account
                        </div>
                    </div>
            </div>

          </div>



    </div>
  </div>
</template>

<script>
import purposeNav from '@/5purpose/_purposeNav'
export default {
  name: "theGame",

  components: {
      purposeNav,
  },
  mounted() {
    this.initGame()
  },
  methods: {initGame(){
        const block = document.getElementById('marketsGameArch');

          const pictureSrc ="https://holons.me/static/img/flow2.svg";
          const pictureWidth = 27;
          const pictureHeight = 27;
          let numFlakes = 43;
          const downSpeed = 0.001;
          const lrFlakes = 10;

          if ( typeof( numFlakes ) !== 'number' || Math.round( numFlakes ) !== numFlakes || numFlakes < 1 ) {
          numFlakes = 10;
          }

          for( let x = 0; x < numFlakes; x++ ) {
          block.innerHTML += '<div style="position:absolute; z-index:9999;"id="snFlkDiv'+x+'"><img src="'+pictureSrc+'"height="'+pictureHeight+'" width="'+pictureWidth+'" alt="*"border="0"></div>';
          }

          let xcoords = new Array();
          let ycoords = new Array();
          let snFlkTemp;
          for ( let x = 0; x < numFlakes; x++ ) {
          xcoords[x] = ( x + 1 ) / ( numFlakes + 1 );
          do {
            snFlkTemp = Math.round( ( numFlakes - 1 ) * Math.random() );
          } while ( typeof( ycoords[snFlkTemp] ) === 'number' );
          ycoords[snFlkTemp] = x / numFlakes;
          }

          const flakeFall = () => {
          let scrWidth = block.offsetWidth,
          scrHeight = block.offsetHeight,
          scrollHeight = 0,
          scrollWidth = 0;
          for ( let x = 0; x < numFlakes; x++ ) {
            if ( ycoords[x] * scrHeight > scrHeight - pictureHeight ) {
              ycoords[x] = 0;
            }
            let divRef = document.querySelector('#snFlkDiv' + x);
            if ( !divRef ) {
              return;
            }
            if( divRef.style ) {
              divRef = divRef.style;
            }
            const oPix = block.childNodes ? 'px' : 0;
            divRef.top = ( Math.round( ycoords[x] * scrHeight ) + scrollHeight ) + oPix;
            divRef.left = ( Math.round( ( ( xcoords[x] * scrWidth ) - (pictureWidth / 2 ) ) + ( ( scrWidth / ( ( numFlakes + 1 ) * 4 ) ) * (Math.sin( lrFlakes * ycoords[x] ) - Math.sin( 3 * lrFlakes * ycoords[x]) ) ) ) + scrollWidth ) + oPix;
            ycoords[x] += downSpeed;
          }
          }

          window.setInterval(() => flakeFall(), 100);
    }}
}
</script>
