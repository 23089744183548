<template>
  <div id="viewModal" class="hm item deed view modal active">
    <div class="content" v-on-clickaway="close" @click.stop="" v-if="deed && profile">

      <div id="deed__controls" v-if="isEditable">

          <a class="ui button inverted mrgn0"
          @click="emitEdit">edit</a>

          <br><br>

          <a id="deed-destroy" class="ui button destroy mrgn0"
            @click.prevent="deleteDeed(deed.id)">
            delete
          </a>
      </div>

      <!--<div class="left__col">

        <div v-if="deed.logo">
          <h3 class="collab">x</h3>

          <a href="#">
            <img id="deed_logo"
              class="deed_logo"
              :src="deed.logo"
            />
          </a>
        </div>

        <div id="highlight" v-if="deed.highlight">
          <span class="yo">🤘</span>
          {{ deed.highlight }}
        </div>


        <div v-if="deed.live_link"
        style="text-align: center">

          <a :href="deed.live_link" id="deedLiveLink"
          target="_blank">{{ deed.live_link }}</a>
        </div>

      </div>  left column ends -->

   <!-- <div class="right__col">

        <div class="deed__meta">
          <span class="check">✓</span>
          <span class="verified">verified deed</span>
        </div>

        <div id="contributors__fame">

          <div class="contributor flex start">

          TODO
            <div class="vaHack">
              <a href="#">
                <img src="/usg/usg/soulspics/2021-06-21_14.00.17_dEB3gSQ.jpeg">
              </a>
            </div>
            <div class="vaHack">
              Dmitrii B. – lead developer
            </div>
          </div>

          <p class="muted">legacy:</p>
          {{ deed.contributors_meta }}
        </div>
      </div>    right column ends -->


      <div id="itemSingle" class="deed">
        <img :src="bus.activeProfile.logo" class="fuckYeeah" />
        <h1>{{ deed.title }}</h1>

        <p id="excerpt">{{ deed.excerpt }}</p>

        <!--<div class="hm menu">
          <a class="item active">overview</a>
          <a class="item">process</a>
          <a class="item classic">tech</a>
          <a class="item gold">budget</a>
        </div>-->

        <div class="deed-body" v-html="deed.body_html"></div>
      </div>
      <div id="ctaHolder" class="flex">
        <div class="vaHack">
          {{ deed.button.explainer }}
        </div>

        <div class="vaHack">
          <a :class="['hm', 'button', 'core', 'mrgn0', deed.button.class]">{{ deed.button.text }}</a>
        </div>
      </div>

        
      <tips-block />

        
      <!--
        look like a legacy to me:

      <div class="details">
        <div v-html="deed.description"></div>
      </div>-->

    </div>
  </div>
</template>

<script>
import { mixin as clickaway } from 'vue-clickaway';

import { identityBus } from '../bus';
import tipsBlock from '@/global/moneta/tips/tipsBlock'

export default {
  name: 'deedViewModal',
  mixins: [ clickaway ],
  components: {
    tipsBlock,
  },
  props: {
    deed: Object,
    profile: Object
  },
  data(){
    return {
      bus: identityBus
    }
  },
  computed: {
    /**@returns {String} */
    apiUrl(){
      return '/api/accounts/profiles/';
    },

    /**@returns {Boolean} */
    isEditable(){
      return this.profile.is_owner
    }
  },
  mounted() {},
  methods: {
    deleteDeed() {
      const url = `/api/accounts/profiles/deeds/${this.deed.id}/`;

      this.$http.delete(url)
        .then(response => {
          if(response){
            this.close()
            this.$emit('deed-deleted')
          }
        })
    },
    close() {
      this.$root.$emit('close-modals')
    },
    emitEdit(){
      this.bus.$emit('edit-deed', this.deed)
    }
  },
};
</script>
<style scoped>
#deed-destroy.destroy {
  background-color: crimson !important;
}
.deed_cover,
.deed_logo {
  width: 80px;
  height: auto;
  display: block;
}
</style>
