<template>
<div
  class="auth-wrapper"
  :class="{'is-bare': isBare}"
>

<!--<p id="whatHolonsIs">
  holons.me is <br>
  <del>book store</del> <br>
  private network <br>
  &amp; matchmaking engine <br>
  for people who can read, write & deliver
  for entrepreneurs, talent, attention <br>
  &amp; capital
</p>-->

<!--<p id="smsFallback">sometimes SMS gateway breaks  <br>
  or doesn't work in certain countries ¯\_(ツ)_/¯<br>
  try <a class="hm link" href="https://t.me/grintender">@grintender</a> on tg</p>-->

<!--<p id="cheerUp">
  not sure what's going on? nobody is. <br>
  never lose hope, never give up
</p>-->


    <div class="auth container flex" style="padding: 2rem">

        <div class="w50">
          <div class="vaHack">
            <div class="tos">
              <h1>holons.me</h1>
              is <del>book store</del> <br>
              private network &amp; matchmaking engine <br>
              for people who can read<del>, write & deliver</del>

              <div class="hm menu" style="margin: 2rem">
                <a class="item active">eng</a>
                <a class="item">русский</a>
                <a class="item">español</a>
                <a class="item">中國人</a>
              </div>
              <p>Create account in three easy steps:</p>
              <h5 class="mrgn0">(1) Press 'F' to pay respects</h5>
              <h5>(2) accept terms of service:</h5>

              <p>Use as is. Somewhat existing suppport with <b>long</b> response time. No refunds.</p>

              <div class="flex" style="border-top: 2px solid #23272B">
                <div class="vaHack">
                  <p class="muted mrgn0">Sign here:</p>
                </div>
                <div class="vaHack">
                  <input type="checkbox" name="pt" id="pt2">
                  <label for="pt2">yes, I can read</label>
                </div>
              </div>

              <p>holons.me is a private entity, <br>
                with a <b>completly arbitrary</b> banhammers & rules.</p>

              <p>Neither <b>core accounts</b> have dues, nor they have rights.  <b>Paid core accounts</b> have no rights too. <b>verified core accounts</b> exist. Classic & gold accounts are not purchasable, but have rights. See <a class="awhat">how to play</a> for more.</p>

                  <div class="log">whining illiterate fuks banned</div>
                  <div class="log">gurus, wokism & public investing advice are banned</div>
                  <div class="log">public politics banned</div>
                  <div class="log">babel tower remedy bill passed</div> <!-- set the only four (4) recognised languages-->
                  <div class="log">age #census updated to 20</div>
                  <div class="log">banhammers created</div>
                  <!---<div class="log">lobbying created</div>-->
                  <div class="log">#communities created</div>
                  <!--<div class="log"></div>
                  <div class="log"></div>
                  <div class="log"></div>-->

              <div class="flex" style="border-top: 2px solid #23272B">
                <div class="vaHack">
                  <p class="muted mrgn0">Sign here:</p>
                </div>
                <div class="vaHack">
                  <input type="checkbox" name="pt" id="pt2">
                  <label for="pt2">ok, I understand everything above</label>
                </div>
              </div>

            </div>
          </div>
        </div>

        <p id="authyo">not sure what's going on? nobody is. <br>
        never lose hope, never give up</p>

        <div class="auth box">

            <div class="title" v-if="!loginSuccessful">
              <h1>
                Let <del class="muted">my people</del> <br>
                <del class="muted">go surfing</del> <br>
                me in</h1>
            </div>
            <phone-input
              @update="updateLoginPhone"
              @keyup.enter.native="getConfirmationCode"
              v-model="localPhoneNumber"
              fetch-country
              no-flags
              size="sm"
              v-if="!codeInputActive"
              autofocus
            ></phone-input>
            <form id="login-form" autocomplete="off">
              <template v-if="!loginSuccessful">
                <label for="#" class="email_field" autocomplete="off">
                  <input
                    id="inputcode" ref="inputcode"
                    v-if="codeInputActive"
                    type="text"
                    name="code"
                    placeholder="code from SMS"
                    v-model="confirmationCode"
                  />
                </label>

                <button
                  class="auth-btn"
                  name="code"
                  v-if="!codeInputActive"
                  v-bind:disabled="phoneNumber == ''"
                  v-on:click.prevent="getConfirmationCode"
                >(3) ok. go</button>
                <button
                  class="auth-btn"
                  name="confirm"
                  v-if="codeInputActive"
                  :disabled="confirmationCode == ''"
                  @click.prevent="confirmAccount"
                >{{ newUser ? 'say my name' : 'let me in' }}</button>
              </template>

              <div v-if="newUser && profile">
                <profileEdit
                  @user-details-change="onChangeUserDetails"
                  activeTab="profile"
                  :profile="profile"
                  isBare="true"
                />
                <button
                  class="auth-btn"
                  name="confirm"
                  :disabled="!isProfileReady"
                  @click.prevent="loginEnd"
                >let me in</button>
              </div>
            </form>

            <p id="sorryNOTsorry">
              sometimes SMS gateway breaks  <br>
              or doesn't work in certain countries <br>
               ¯\_(ツ)_/¯ try <a class="hm link" href="https://t.me/grintender">@grintender</a> on tg</p>
        </div>



    </div>


</div>

</template>

<script>
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';

import profileEdit from '@/02identity/_profile/profileEdit'

import { holonsHeaders } from '@/utils/globals'

export default {
  name: 'LoginForm',
  components: {
    PhoneInput: VuePhoneNumberInput,
    profileEdit
  },
  props: {
    isBare: {
      type: Boolean,
      default: function() {
        return false;
      },
    },
  },
  data() {
    return {
      loader: null,

      codeInputActive: false,
      invitationActive: false,

      phoneNumber: '',
      localPhoneNumber: '', // wtf?
      confirmationCode: '',
      country: '',
      fetchCountry: false,
      noFlasgs: false,
      countries: [],
      loginSuccessful: false,

      profile: null,
      isProfileReady: false,
      newUser: false
    };
  },
  watch: {
    confirmationCode: function(newValue) {
      if (newValue.length >= 4) {
        this.confirmAccount();
      }
      return newValue;
    },
  },
  methods: {
    getConfirmationCode: function() {
      this.$http.post(
        '/api/accounts/login',
        {
          phone_number: this.phoneNumber,
          country: this.country,
        }
      ).then(response => {
        this.codeInputActive = true;

        this.newUser = response.data.is_registered
      })
    },
    confirmAccount: function() {
      this.$http.post('/api/accounts/confirmation', {
        phone_number: this.phoneNumber,
        confirmation_code: this.confirmationCode,
      }).then(response => {
        var tokenStorage;
        var wsUserMeta = response.data['workspaces_user_meta'];
        tokenStorage = window.localStorage;
        tokenStorage.setItem('token', '"' + wsUserMeta['auth_token'] + '"');
        tokenStorage.setItem('wsUserInfo', JSON.stringify(wsUserMeta));

        this.invitationActive = true;
        this.storeTeleportToken(response.data['teleport_user_meta']);

        this.profile = response.data.profile

        this.loginSuccessful = true;

        if(!this.newUser) this.loginEnd()
      })
    },
    loginEnd(){
      if (this.isBare) {
        this.$emit('user-logged-in');
      } else {
        this.$router.push('/agenda')
      }
    },

    onChangeUserDetails({ display_name, userpic }){
      if(!display_name.match(/holons[_-\s]soul/) && userpic)
        this.isProfileReady = true
    },

    storeTeleportToken: function(teleportData) {
      // set localStorage items
      var tokenStorage;
      tokenStorage = window.localStorage;
      tokenStorage.setItem('mx_access_token', teleportData.access_token);
      tokenStorage.setItem('mx_user_id', teleportData.user_id);
      tokenStorage.setItem('mx_crypto_initialised', 'false');
      tokenStorage.setItem('mx_device_id', teleportData.device_id);
      tokenStorage.setItem('mx_hs_url', 'https://' + teleportData.home_server);
      tokenStorage.setItem('mx_is_guest', 'false');
      tokenStorage.setItem('mx_is_url', 'https://' + teleportData.home_server);
      tokenStorage.setItem(
        'mxjssdk_memory_filter_FILTER_SYNC_' + teleportData.user_id,
        '0',
      );
      return;
    },

    updateLoginPhone(data) {
      if (data.countryCallingCode && data.phoneNumber) {
        this.phoneNumber = data.countryCallingCode + data.nationalNumber;

        const countryObj = this.countries.find(
          c => `+${data.countryCallingCode}` == c.dial_code,
        );
        this.country = countryObj ? countryObj.name : '';
      }
    },
    updateRegPhone(data) {
      if (data.countryCallingCode && data.phoneNumber) {
        this.regPhoneNumber = data.countryCallingCode + data.nationalNumber;

        const countryObj = this.countries.find(
          c => `+${data.countryCallingCode}` == c.dial_code,
        );
        this.regCountry = countryObj ? countryObj.name : '';
      }
    },
  },
  mounted() {
    this.$http.defaults.headers.common['X-CSRFToken'] = holonsHeaders['X-CSRFToken'];
    fetch(window.location.origin + '/static/app/countries.json')
      .then(res => {
        if (!res.ok) {
          throw res;
        } else {
          return res.json();
        }
      })
      .then(res => {
        this.countries = res ? res : [];
      });
  },
  updated() {
    // This function detects when the SMS Code Input window appears and autofocuses it -
    // this prevents you from accidentally hitting a hotkey (1-6) and dropping out of Auth window
    if (this.codeInputActive && typeof this.$refs.inputcode !== 'undefined') {
      this.$nextTick(function() {
        this.$refs.inputcode.focus();
      });
    }
  },
}
</script>

<style scoped>
.auth-wrapper,
.auth.container {
  min-height: 100%;
}
.no-gutter {
  padding: 0 !important;
}
.no-gutter .column {
  padding: 0 !important;
}
.phone-input__cont * {
  width: unset !important;
}
.country-selector__list {
  overflow: auto;
  max-height: 150px;
}
.country-selector__input {
  width: 40px;
}
.is-bare .auth.container {
  background: none !important;
}
</style>
