<template>
      <canvas class="illo"></canvas>
</template>

<script>
/* eslint-disable */
import  Zdog from 'zdog'

export default {
  name: "foraAnimation",

  mounted() {
    this.startAnimation()
  },

  methods: {
    startAnimation() {

        // ----- solids setup ----- //

        var illoElem = document.querySelector('.illo');
        var sceneSize = 96;
        var TAU = Zdog.TAU;
        var ROOT3 = Math.sqrt(3);
        var isSpinning = true;
        var viewRotation = new Zdog.Vector();
        var displaySize;

        // colors
        var eggplant = '#23272B';
        var garnet = '#7b8f50';
        var orange = '#23272B';
        var gold = '#FFCF4A';
        var yellow = '#fff';

        var illo = new Zdog.Illustration({
        element: illoElem,
        scale: 40,
        resize: 'fullscreen',
        onResize: function( width, height ) {
          displaySize = Math.min( width, height );
          this.zoom = Math.floor( displaySize / sceneSize );
        },
        });

        var solids = [];


        // ----- isocahedron ----- //

          ( function() {

          var isocahedron = new Zdog.Anchor({
            addTo: illo,
            //translate: { x: 4, y: 4 },
            scale: 1.2,
          });

          solids.push( isocahedron );

          // geometry
          // radius of triangle with side length = 1
          var faceRadius = ROOT3/2 * 2/3;
          var faceHeight = faceRadius * 3/2;
          var capApothem = 0.5 / Math.tan( TAU/10 );
          var capRadius = 0.5 / Math.sin( TAU/10 );
          var capTilt = Math.asin( capApothem / faceHeight );
          var capSagitta = capRadius - capApothem;
          var sideTilt = Math.asin( capSagitta / faceHeight );
          var sideHeight = Math.sqrt( faceHeight*faceHeight - capSagitta*capSagitta );

          // var colorWheel = [ eggplant, garnet, orange, gold, yellow ];

          [ -1, 1 ].forEach( function( ySide ) {
            var capColors = {
              '-1': [ garnet, gold, yellow, gold, orange ],
              1: [ gold, garnet, eggplant, garnet, orange ],
            }[ ySide ];

            var sideColors = {
              '-1': [ garnet, gold, yellow, orange, garnet ],
              1: [ gold, garnet, eggplant, orange, orange ],
            }[ ySide ];

            for ( var i=0; i < 5; i++ ) {
              var rotor = new Zdog.Anchor({
                addTo: isocahedron,
                rotate: { y: TAU/5 * -i },
                translate: { y: sideHeight/2 * ySide },
              });

              var capRotateX = -capTilt;
              var isYPos = ySide > 0;
              capRotateX += isYPos ? TAU/2 : 0;

              var capAnchor = new Zdog.Anchor({
                addTo: rotor,
                translate: { z: capApothem * ySide },
                rotate: { x: capRotateX },
              });

              // cap face
              var face = new Zdog.Polygon({
                sides: 3,
                radius: faceRadius,
                addTo: capAnchor,
                translate: { y: -faceRadius/2 },
                stroke: false,
                fill: true,
                color: capColors[i],
                // backface: false,
              });

              var sideRotateX = -sideTilt;
              sideRotateX += isYPos ? 0 : TAU/2;
              var sideAnchor = capAnchor.copy({
                rotate: { x: sideRotateX },
              });

              face.copy({
                addTo: sideAnchor,
                translate: { y: -faceRadius/2 },
                rotate: { y: TAU/2 },
                color: sideColors[i]
              });

            }
          });

          })();

          // -- animate --- //

          var keyframes = [
          { x:   0, y:   0 },
          { x:   0, y: TAU },
          { x: TAU, y: TAU },
          ];

          var ticker = 0;
          var cycleCount = 360;
          var turnLimit = keyframes.length - 1;

          function animate() {
          update();
          illo.renderGraph();
          requestAnimationFrame( animate );
          }

          animate();

          function update() {

          if ( isSpinning ) {
            var progress = ticker / cycleCount;
            var tween = Zdog.easeInOut( progress % 1, 4 );
            var turn = Math.floor( progress % turnLimit );
            var keyA = keyframes[ turn ];
            var keyB = keyframes[ turn + 1 ];
            viewRotation.x = Zdog.lerp( keyA.x, keyB.x, tween );
            viewRotation.y = Zdog.lerp( keyA.y, keyB.y, tween );
            ticker++;
          }

          solids.forEach( function( solid ) {
            solid.rotate.set( viewRotation );
          });

          illo.updateGraph();
          }
    }
  }
}
</script>
