var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"deeds_holder"},[_c('div',{staticClass:"hm menu inverted",staticStyle:{"zoom":"0.85","margin-top":"-3rem","padding-left":"1rem"}},_vm._l((_vm.roles),function(role){return _c('a',{key:role,class:['item', {active: role == _vm.activeRole}],on:{"click":function () { return _vm.chooseRole(role); }}},[_vm._v(_vm._s(role || 'all'))])}),0),(_vm.profile.is_owner)?_c('a',{staticClass:"hm button core mrgn0",attrs:{"id":"draftDeedBtn"},on:{"click":function($event){$event.stopPropagation();return _vm.showDeedDraftModal()}}},[_vm._v("+ draft new deed")]):_vm._e(),(_vm.deedsByViews.tile.length)?_c('draggable',{class:['deeds__tile', {dropzone: _vm.dragTo == 'tile'}],attrs:{"group":"deed","draggable":".item","value":_vm.deedsByViews.tile},on:{"start":_vm.startDrag,"change":function (evt) { return _vm.endDrag(evt, 'tile'); },"end":function () {
          _vm.dragTo = '';
        }},nativeOn:{"dragenter":function($event){return (function () {
          _vm.dragTo = 'tile';
        }).apply(null, arguments)}}},_vm._l((_vm.deedsByViews.tile),function(deed){return _c('div',{key:deed.id,class:['item', deed.cover && deed.cover.view_type, deed.deed_type],attrs:{"id":("deed-" + (deed.id))},on:{"click":function($event){$event.stopPropagation();return _vm.showDeedViewModal(deed)}}},[(deed.deed_type == 'magnum opus')?_c('div',{staticClass:"b-item-inner noclickout"},[(deed.logo)?_c('img',{staticClass:"deedLogo",attrs:{"src":deed.logo}}):_vm._e(),(deed.cover && !deed.cover.is_video)?_c('img',{attrs:{"src":deed.cover.file,"width":"100%"}}):_vm._e(),(deed.cover && deed.cover.is_video)?_c('video',{attrs:{"src":deed.cover.file,"width":"100%","autoplay":"","muted":""},domProps:{"muted":true}}):_vm._e(),_c('h2',[_vm._v(_vm._s(deed.title))]),_c('p',{staticClass:"muted"},[_vm._v(_vm._s(deed.excerpt))]),_c('p',{staticClass:"muted"},[_vm._v("highlight: "+_vm._s(deed.highlight))]),_vm._l((deed.contributions.filter(
              _vm.filterContributions
            )),function(contribution){return _c('div',{key:contribution.id},[_vm._v(" "+_vm._s(contribution.role)+" ")])})],2):_vm._e(),(deed.deed_type == 'featured')?_c('div',{staticClass:"b-item-inner noclickout"},[(deed.logo)?_c('img',{staticClass:"deedLogo",attrs:{"src":deed.logo}}):_vm._e(),(deed.cover && !deed.cover.is_video)?_c('img',{attrs:{"src":deed.cover.file,"width":"100%"}}):_vm._e(),(deed.cover && deed.cover.is_video)?_c('video',{attrs:{"src":deed.cover.file,"width":"100%","autoplay":"","muted":""},domProps:{"muted":true}}):_vm._e(),_c('h2',[_vm._v(_vm._s(deed.title))]),_c('p',{staticClass:"muted"},[_vm._v(_vm._s(deed.excerpt))]),_c('p',{staticClass:"muted"},[_vm._v(_vm._s(deed.highlight))]),_vm._l((deed.contributions.filter(
              _vm.filterContributions
            )),function(contribution){return _c('div',{key:contribution.id},[_c('div',{staticClass:"role"},[_vm._v(" "+_vm._s(contribution.role)+" ")])])})],2):_vm._e()])}),0):_vm._e(),(_vm.deedsByViews.table.length)?_c('table',{staticClass:"hm table plain",staticStyle:{"margin":"0 2rem"}},[_c('draggable',{class:['deeds__table', {dropzone: _vm.dragTo == 'table'}],attrs:{"tag":"tBody","group":"deed","value":_vm.deedsByViews.table},on:{"start":_vm.startDrag,"change":function (evt) { return _vm.endDrag(evt, 'table'); },"end":function () {
            _vm.dragTo = '';
          }},nativeOn:{"dragenter":function($event){return (function () {
            _vm.dragTo = 'table';
          }).apply(null, arguments)}}},_vm._l((_vm.deedsByViews.table),function(deed){return _c('tr',{key:deed.id,attrs:{"id":("deed-" + (deed.id))},on:{"click":function($event){$event.stopPropagation();return (function () {
              _vm.showDeedEditModal(deed);
            }).apply(null, arguments)}}},[_c('td',[_c('h4',[_vm._v(_vm._s(deed.title))])]),_c('td',[_c('p',{staticClass:"mrgn0 muted",domProps:{"innerHTML":_vm._s(deed.excerpt)}})])])}),0)],1):_vm._e(),(!_vm.existingDeeds.length && !_vm.deedsLoading)?_c('div',[_vm._v(" Here's no deeds we know about ")]):_vm._e(),(_vm.deedsLoading)?_c('div',[_vm._m(0)]):_vm._e(),(_vm.profile.is_owner)?_c('div',{attrs:{"id":"private"}},[_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6)]):_vm._e(),(_vm.profile.is_owner && _vm.deedPostModalActive)?_c('post-modal',{attrs:{"profile":_vm.profile,"deed":_vm.deedToEdit},on:{"deed-saved":_vm.loadDeeds,"deed-created":_vm.deedCreated}}):_vm._e(),(_vm.deedViewModalActive)?_c('view-modal',{ref:"deedViewModal",attrs:{"deed":_vm.selectedDeed,"profile":_vm.profile},on:{"deed-deleted":_vm.deedDeleted}}):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"deeds__tile"},[_c('div',{staticClass:"item"}),_c('div',{staticClass:"item"}),_c('div',{staticClass:"item"}),_c('div',{staticClass:"item"}),_c('div',{staticClass:"item"}),_c('div',{staticClass:"item"}),_c('div',{staticClass:"item"}),_c('div',{staticClass:"item"}),_c('div',{staticClass:"item"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"item"},[_c('div',{staticClass:"inner"},[_c('h5',[_vm._v("can't comb a deed in a way you want?")]),_c('p',{staticClass:"mrgn0"},[_vm._v("talk to "),_c('a',{attrs:{"href":"/grintender"}},[_vm._v("@grintender")])])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"item"},[_c('div',{staticClass:"inner"},[_c('h5',[_vm._v("want a nice design?")]),_c('p',{staticClass:"mrgn0"},[_vm._v("talk to "),_c('a',{attrs:{"href":"/pheme"}},[_vm._v("@pheme")])])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"item"},[_c('div',{staticClass:"inner"},[_c('h5',[_vm._v("drag-n-drop to re-order items")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"item"},[_c('div',{staticClass:"inner"},[_c('h5',[_vm._v("get your deeds verified")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"item"},[_c('div',{staticClass:"inner"},[_c('h5',[_vm._v("ad slot #1")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"item"},[_c('div',{staticClass:"inner"},[_c('h5',[_vm._v("ad slot #2")])])])}]

export { render, staticRenderFns }