<template>
  <div>
    <div
      id="createAccount"
      class="flex"
      v-if="!userIsLoggedIn && $router.currentRoute.fullPath !== '/auth'"
    >
      <div class="vaHack">
        <p class="mrgn0">
          No need for account to go around. Try pressing
          <span class="top">→</span><span class="bot">→</span> on keyboard.
          Press 'F' to pay respects.
        </p>
      </div>
      <div class="vaHack">
        <a id="letMeIn" class="hm button core grin mrgn0" href="/auth"
          >let me in</a
        >
      </div>
    </div>

    <!-- bare layout -->
    <router-view v-if="layoutStyle == 'bare'" />

    <div
      class="flex layout-core"
      :class="holonsNodeName"
      v-else-if="layoutStyle == 'core'"
    >
      <!-- core layout -->

      <div class="main-working">
        <top-navigation />
        <layers-navigation v-if="currentRouteNameIsLayers" />
        <router-view />
      </div>
      <mercury-sidebar />
    </div>

    <!-- fora layout -->
    <div
      class="flex layout-fora"
      :class="holonsNodeName"
      v-else-if="layoutStyle == 'fora'"
    >
      <div class="main-working">
        <router-view ref="view" />
      </div>
      <mercury-sidebar />
    </div>

    <!-- faith layout -->
    <div
      class="flex layout-faith"
      :class="holonsNodeName"
      v-else-if="layoutStyle == 'faith'"
    >
      <div class="main-working">
        <router-view ref="view" />
      </div>
      <mercury-sidebar />
    </div>

    <!-- root layout -->
    <div
      class="flex layout-root"
      :class="holonsNodeName"
      v-else-if="layoutStyle == 'root'"
    >
      <div class="main-working">
        <router-view ref="view" />
      </div>
      <mercury-sidebar />
    </div>

    <!-- root layout -->
    <div
      class="layout-word-single"
      :class="holonsNodeName"
      v-else-if="layoutStyle == 'word-single'"
    >
      <router-view ref="view" />
    </div>

    <short-keys-navigation />
    <broker />
    <functions />
    <sayTeleport />
    <how-to-play-modal v-if="showHowToPlay" />
    <invoice-modal v-if="invoiceModalActive" :tip-meta="invoiceProps" />
  </div>
</template>

<script>
import topNavigation from './topNavigation';
import layersNavigation from '@/3layers/layersNavigation';
import shortKeysNavigation from '../shortKeysNavigation';
import mercurySidebar from './mercurySidebar';
import broker from '@/x_foraPublic/broker';
import functions from '@/x_foraPublic/functions';
import sayTeleport from '@/mercuryCommunication/02teleport/say';
import howToPlayModal from '@/global/howToPlayModal';
import invoiceModal from '@/global/moneta/invoiceModal';

export default {
  name: 'app',
  components: {
    topNavigation,
    layersNavigation,
    shortKeysNavigation,
    mercurySidebar,
    broker,
    functions,
    sayTeleport,
    howToPlayModal,
    invoiceModal,
  },
  data() {
    return {
      loader: null,
      wsPrefix: '',
      wsTitle: '',
      mxCall: null,
      magicLink: `https://magic1.teleport.holons.me/${this.generateRandomPhrase()}`,
      showTopNavigation: true,
      layoutStyle: 'bare',
      isOwner: window.is_owner,
      showHowToPlay: false,
      userIsLoggedIn: window.user_logged_in,
      invoiceModalActive: false,
      invoiceProps: {},
    };
  },
  watch: {
    $route() {
      this.getLayoutStyle();
    },
  },
  computed: {
    holonsNodeName: function() {
      if (this.$route.name) return '' + this.$route.name.toLowerCase();
      return '';
    },
    bare() {
      // force Bare app template for certain paths
      return window.bare;
    },
    wordSingle: {
      get: function() {
        return window.is_word_single;
      },
      set: function(newVal) {
        return newVal;
      },
    },
    special() {
      return window.special;
    },
    userpic() {
      return window.userpic_scaled;
    },
    currentRouteName() {
      return this.$route.name;
    },
    currentRouteNameIsLayers() {
      let layerNames = [
        'Layers',
        'TimeTracker',
        'Talent',
        'TalentPayroll',
        'TalentGigs',
      ];
      return layerNames.includes(this.$route.name);
    },
    publicProfile() {
      return window.profile_status && !this.isOwner;
    },
  },
  created() {
    if (this.special) {
      this.startPreloader();
    }
  },
  mounted() {
    this.$http.defaults.xsrfCookieName = 'csrftoken';
    this.$http.defaults.xsrfHeaderName = 'X-CSRFToken';
    this.$root.$on('set-layout', layoutStyle => {
      this.layoutStyle = layoutStyle;
    });
    this.$root.$on('toggle-how-to-play', () => {
      this.showHowToPlay = true;
    });
    this.$root.$on('close-modals', this.closeModals);
    this.$root.$on('issue-an-invoice', this.showInvoiceModal);

    this.getLayoutStyle();
  },
  methods: {
    showInvoiceModal(invoiceProps) {
      this.invoiceModalActive = true;
      this.invoiceProps = invoiceProps;
    },
    closeModals() {
      this.showHowToPlay = false;
      this.invoiceModalActive = false;
    },
    getLayoutStyle(force = '') {
      if (force !== '') {
        this.layoutStyle = force;
        return;
      }
      let layoutStyle = 'core';
      let rtN = this.$route.name;
      var pathsBare = new Array('DigestUi');
      var pathsRoot = new Array('Root', 'Runbook', 'RootCreate', 'RootSearch');
      var pathsFora = new Array('Fora', 'Communities', 'directoryMain');
      var pathsFaith = new Array(
        'Digest',
        'FaithVc',
        'faithWatch',
        'faithGo',
        'faithListen',
        'faithPlay',
        'faithRead',
        'faithShop',
      );
      if (this.bare && pathsBare.indexOf(rtN) !== -1) {
        layoutStyle = 'bare';
      }
      /*
      if (typeof this.publicProfile !== 'undefined') {
        layoutStyle = 'public-profile';
      }
      */
      if (this.wordSingle) {
        layoutStyle = 'word-single';
      }
      if (pathsFora.indexOf(rtN) !== -1) {
        this.setSpecialStyles('fora');
        this.setShowTopNavigation('fora');
        layoutStyle = 'fora';
      }
      if (pathsFaith.indexOf(rtN) !== -1) {
        this.setSpecialStyles('faith-vc');
        layoutStyle = 'faith';
      }
      if (pathsRoot.indexOf(rtN) !== -1) {
        layoutStyle = 'root';
      }
      this.layoutStyle = layoutStyle;
    },
    setShowTopNavigation(scope) {
      if (scope == 'fora') {
        this.showTopNavigation = false;
      }
    },
    setSpecialStyles(prefix) {
      document.body.className = 'special-' + prefix;
    },
    startPreloader() {
      this.loader = this.$loading.show(
        {
          zIndex: 90,
        },
        {
          default: this.$createElement('custom-loader', {
            props: {
              entry: 'created',
            },
          }),
        },
      );
      setTimeout(() => {
        this.loader.hide();
      }, 7000);
    },
    copyMagicLink(link) {
      const pathName = document.createElement('input');
      pathName.type = 'text';
      pathName.value = link;
      document.body.appendChild(pathName);
      pathName.focus();
      pathName.select();
      document.execCommand('copy');
      document.body.removeChild(pathName);
      return link;
    },
    generateRandomPhrase() {
      return '';
    },
  },
};
</script>
