<template>
  <div class="vaHack" v-if="!isRecurrent">
    <a
      v-if="currency !== 'RUR'"
      class="hm button core moneta mrgn0"
      @click.prevent="showInvoiceModal"
      >{{ currencySign }}&nbsp;{{ amount }}</a
    >
    <a
      v-else
      class="hm button core moneta mrgn0"
      @click.prevent="showInvoiceModal"
      >{{ amount }}&nbsp;{{ currencySign }}</a
    >
  </div>
  <div class="vaHack" v-else>
    <a
      v-if="currency !== 'RUR'"
      class="hm button core moneta mrgn0"
      @click.prevent="showInvoiceModal"
      >{{ currencySign }}&nbsp;{{ amount }} {{ billingPeriod }}</a
    >
    <a
      v-else
      class="hm button core moneta mrgn0"
      @click.prevent="showInvoiceModal"
      >{{ amount }}&nbsp;{{ currencySign }}&nbsp;{{ billingPeriod }}</a
    >
  </div>
</template>

<script>
export default {
  name: 'payButton',
  props: {
    title: {
      type: String,
      default: 'pay',
    },
    amount: {
      type: Number,
      default: 1,
    },
    currency: {
      type: String,
      default: 'USD',
    },
    currencySign: {
      type: String,
      default: '$',
    },
    isRecurrent: {
      type: Boolean,
      default: false,
    },
    billingPeriod: {
      type: String,
      default: 'one-time',
    },
    billingItem: {
      type: Object,
      default: function() {
        return {
          billingItemType: 'N/A',
          billingItemId: 'N/A',
          billingItemDescription: 'No such billing item',
        };
      },
    },
  },
  computed: {
    paymentMeta() {
      return Object.keys(this.$options.props).reduce(
        (acc, key) =>
          Object.assign(acc, this[key] !== undefined ? {[key]: this[key]} : {}),
        {},
      );
    },
  },
  data() {
    return {};
  },
  methods: {
    showInvoiceModal() {
      this.$root.$emit('issue-an-invoice', this.paymentMeta);
    },
  },
};
</script>
