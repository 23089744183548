export default {
  createJobMeta: () => ({
    jobId: 0,

    jobType: '',
    jobContext: '',
    jobName: '',
    jobDescription: '',

    jobLocation: '',
    jobCity: '',
    jobRhythm: '',

    jobBudget: '',
    jobOwnershipEquity: false,
    jobOwnershipProfitSharing: false,
    jobNDA: false,
    jobContributorsFame: false,

    jobCensus: '',
    jobAskNetworkIntro: false,
    jobNetworkIntroType: '',

    jobSendToPlato: false,

    jobTalentAugmentation: false,
    jobTalentBounty: '',
    jobTalentOutstaffing: false,
    jobTalentAgencies: false,
  }),

  clearJobMeta: (jobMeta) => {
    jobMeta.jobType = 'full_time'
    jobMeta.jobContext = ''
    jobMeta.jobName = ''
    jobMeta.jobDescription = ''

    jobMeta.jobLocation = 'remote'
    jobMeta.jobCity = ''
    jobMeta.jobRhythm = 'one_time'

    jobMeta.jobBudget = ''
    jobMeta.jobOwnershipEquity = false
    jobMeta.jobOwnershipProfitSharing = false
    jobMeta.jobNDA = false
    jobMeta.jobContributorsFame = false

    jobMeta.jobCensus = ''
    jobMeta.jobAskNetworkIntro = false
    jobMeta.jobNetworkIntroType = ''

    jobMeta.jobSendToPlato = false

    jobMeta.jobTalentAugmentation = false
    jobMeta.jobTalentBounty = ''
    jobMeta.jobTalentOutstaffing = false
    jobMeta.jobTalentAgencies = false
  },

  serializeJobMeta: (jobMeta) => ({
    jobType: jobMeta.jobType,
    contextId: jobMeta.jobContext.id,
    jobName: jobMeta.jobName,
    jobDescription: jobMeta.jobDescription,

    location: jobMeta.jobLocation,
    city: jobMeta.jobCity,
    rhythm: jobMeta.jobRhythm,

    budget: jobMeta.jobBudget || 0,
    ownershipEquity: jobMeta.jobOwnershipEquity,
    ownershipProfitSharing: jobMeta.jobOwnershipProfitSharing,
    nda: jobMeta.jobNDA,
    contributorsFame: jobMeta.jobContributorsFame,

    census: jobMeta.jobCensus,
    askNetworkIntro: jobMeta.jobAskNetworkIntro,
    networkIntroType: jobMeta.jobNetworkIntroType,

    sendToPlato: jobMeta.jobSendToPlato,
    talentAugmentation: jobMeta.jobTalentAugmentation,
    talentBounty: jobMeta.jobTalentBounty || 0,
    talentOutstaffing: jobMeta.jobTalentOutstaffing,
    talentAgencies: jobMeta.jobTalentAgencies,
  }),

  deserializeJobMeta: (jobData, jobMeta) => {
    jobMeta.jobId = jobData.id
    jobMeta.jobType = jobData.type
    jobMeta.jobContext = jobData.context
    jobMeta.jobName = jobData.name
    jobMeta.jobDescription = jobData.description

    jobMeta.jobLocation = jobData.location
    jobMeta.jobCity = jobData.city
    jobMeta.jobRhythm = jobData.rhythm

    jobMeta.jobBudget = jobData.budget
    jobMeta.jobOwnershipEquity = jobData.ownershipEquity
    jobMeta.jobOwnershipProfitSharing = jobData.ownershipProfitSharing
    jobMeta.jobNDA = jobData.nda
    jobMeta.jobContributorsFame = jobData.contributorsFame

    jobMeta.jobCensus = jobData.census

    jobMeta.jobAskNetworkIntro = jobData.askNetworkIntro
    jobMeta.jobNetworkIntroType = jobData.networkIntroType
    jobMeta.jobSendToPlato = jobData.sendToPlato
    jobMeta.jobTalentAugmentation = jobData.talentAugmentation
    jobMeta.jobTalentBounty = jobData.talentBounty
    jobMeta.jobTalentOutstaffing = jobData.talentOutstaffing
    jobMeta.jobTalentAgencies = jobData.talentAgencies
  }
}
