<template>
  <div>
    <div id="profileWords" v-if="words.length">
      <table class="hm table plain" style="margin: 5rem auto">
        <tbody>
          <tr v-for="word in sortedWords" :key="word.id">
            <td :rowspan="word.rowspan + 1" :class="{draft: word.isDraft}" @click.stop="activeWord = word">

                <h4>{{ word.title }}</h4>
                <br />
                <template v-if="word.contributions">
                  <div
                    v-for="contribution in word.contributions.filter(
                      filterContributions,
                    )"
                    :key="contribution.id"
                  >
                    <div class="role">{{ contribution.role }}</div>
                  </div>
                </template>
                <br />
                <!-- roles end -->
                <p class="muted mrgn0">{{ word.excerpt }}</p>

            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="" v-if="!words.length && !wordsLoading">
      <table class="hm table grand" style="border-top: none">
        <thead>
          <tr>
            <td><!-- tool --></td>
            <td><!-- format --></td>
            <td>notable examples</td>
            <td>census</td>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td><h2>channels @teleport</h2></td>
            <td>short form, feed, instant</td>
            <td>TBA</td>
            <td>
              <p>anyone can start the channel;</p>

              <p>discovery across platform requires:</p>
              <ul>
                <li>certain subscribers threshold</li>
                <li>editorial review &amp; approval</li>
              </ul>
            </td>
          </tr>

          <tr>
            <td>
              <h2>
                Words <br />
                &amp; media entities
              </h2>
            </td>
            <td>long form, editorial</td>
            <td>TBA</td>
            <td>
              <p>self service is available for Classic or Gold account</p>
              <p>
                self service is enabled for <b>notable</b><sup>*</sup> Core
                accounts
              </p>

              <p>* i.e. having n+1 of the following:</p>

              <ul>
                <li>
                  authors established elsewhere – this list currently includes
                  only eight people tho
                </li>
                <li>authors of popular channels @teleport</li>
                <li>contributors to established media @holons</li>
                <li>contributors @fora communities</li>
                <li>people w/ notable words</li>
              </ul>
            </td>
          </tr>

          <tr>
            <td><h2>v2 podcasts</h2></td>
            <td></td>
            <td></td>
            <td>to be built; see #concession #1509</td>
          </tr>
        </tbody>
      </table>
    </div>

    <table
      v-if="wordsLoading"
      class="hm table plain"
      style="max-width: 800px; margin: 5rem auto"
    >
      <tbody>
        <tr>
          <td>hi, I'm word</td>
          <td></td>
        </tr>
        <tr>
          <td>hi, I'm word</td>
          <td></td>
        </tr>
        <tr>
          <td>hi, I'm word</td>
          <td></td>
        </tr>
        <tr>
          <td>hi, I'm word</td>
          <td></td>
        </tr>
        <tr>
          <td>hi, I'm word</td>
          <td></td>
        </tr>
        <tr>
          <td>hi, I'm word</td>
          <td></td>
        </tr>
        <tr>
          <td>hi, I'm word</td>
          <td></td>
        </tr>
        <tr>
          <td>hi, I'm word</td>
          <td></td>
        </tr>
        <tr>
          <td>hi, I'm word</td>
          <td></td>
        </tr>
        <tr>
          <td>hi, I'm word</td>
          <td></td>
        </tr>
        <tr>
          <td>hi, I'm word</td>
          <td></td>
        </tr>
        <tr>
          <td>hi, I'm word</td>
          <td></td>
        </tr>
      </tbody>
    </table>
    <view-modal v-if="activeWord != null" :word="activeWord"/>
  </div>
</template>

<script>
import viewModal from './viewModal';
import {identityBus} from '../bus';

export default {
  name: 'wordsTab',
  components: {
    viewModal,
  },
  data() {
    return {
      words: [],
      wordsLoading: true,
      sortedWords: [],
      activeWord: null,

      bus: identityBus,
    };
  },
  computed: {},
  methods: {
    getWords() {
      this.wordsLoading = true;
      return this.$http
        .get(
          // @TODO: think about improvement - we don't need paste slug twice into URL
          `/api/words/${this.bus.activeProfile.slug}/` +
            `?profile_slug=${this.bus.activeProfile.slug}`,
        )
        .then(res => {
          if (!res?.data) return;
          this.words = res.data;
        })
        .then(() => {
          this.sortWords();
        })
        .finally(() => {
          this.wordsLoading = false;
        });
    },

    sortWords() {
      let prepared = this.words.map(w => {
        w.rowspan = this.getRowspanWords(w).length;
        return w;
      });

      let grouped = [];
      prepared.forEach(w => {
        if (!w.parent_id) {
          this.appendWordChildren(grouped, w);
        }
      });
      this.sortedWords = grouped;
    },

    checkWord(w, arr) {
      w.isDraft = w.excerpt.match(/^\/\//);
      return (
        !(w.isDraft && !this.bus.activeProfile.is_owner) &&
        (!w.parent_id || arr.find(_w => _w.id == w.parent_id))
      );
    },

    getRowspanWords(word) {
      return this.words
        .filter(w => w.parent_id == word.id)
        .reduce((ret, childWord) => {
          if (!ret.find(w => w.id == childWord.id)) ret.push(childWord);
          ret.push(...this.getRowspanWords(childWord));

          return ret;
        }, []);
    },
    appendWordChildren(arr, word) {
      const children = this.words.filter(w => w.parent_id == word.id);
      if (this.checkWord(word, arr)) arr.push(word);
      if (children.length) {
        children.forEach(w => this.appendWordChildren(arr, w));
      } else return;
    },

    filterContributions(contribution) {
      return (
        contribution.profile_id == this.bus.activeProfile.id &&
        contribution.role
      );
    },
    closeModals() {
      this.activeWord = null;
    },
  },
  mounted() {
    this.getWords();
    this.$root.$on('close-modals', this.closeModals);
  },
};
</script>
