<!--<template src="./templates/functions.html"></template>-->

<template>
  <div>
    <div id="teleportPreview" class="drawer">
        <div class="wrapper">


                <div class="center">
                  <div class="header">
                    <img src="https://holons.me/usg/uploads/also/olymp2.jpeg">

                  </div>

                  <div id="chatWin">

                    <div class="input flex">
                      <div class="vaHack">
                        <span class="holder">h.me/$</span>
                      </div>
                      <input type="text" name="" value="">
                    </div>
                  </div>

                  <div class="flex" style="margin-top: 3rem;margin-right: 3rem">
                    <div class="meta">
                      <h2>chat #2355</h2>
                    </div>
                    <div class="">
                      <a class="hm button core grin mrgn0">ok. post</a>
                    </div>
                  </div>
                </div>


        </div> <!-- wrapper ends -->

    </div> <!-- drawer ends -->

  </div>
</template>

<script>

export default {
  name: "sayTeleport",

}

</script>

<style scoped>

</style>
