<template>
  <div>
    <div id="journal-holder">
      <!-- display year header in loop √ -->
      <div class="year" v-for="year in filteredWps" v-bind:key="year.year">
        <h1 v-if="!checkCurrentYear(year.year)" class="wp-title__year">
          {{ year.year }}
        </h1>
        <!-- display months for each year √ -->
        <div class="months">
          <div
            class="month-holder"
            v-for="month in year.months"
            :key="month.month_title"
          >
            <table class="hm table plain journal">
              <thead>
                <tr>
                  <td></td>
                  <td>entity</td>
                  <td>commitment</td>
                  <td>
                    <h2
                      v-if="!checkCurrentMonth(year.year, month.month)"
                      class="wp-title__month"
                    >
                      {{ month.month_title }}
                    </h2>
                  </td>
                </tr>
              </thead>

              <!-- display wps for each month -->
              <tr v-for="(wp, i) in month.wps" v-bind:key="i">
                <td
                  :rowspan="month.wps.filter(_wp => _wp.week == wp.week).length"
                  v-if="
                    !month.wps.find(
                      (_wp, checkI) => checkI < i && _wp.week == wp.week,
                    )
                  "
                >
                  <h4>week #{{ wp.weekObj.week }}</h4>
                  <p class="mrgn0">
                    {{ wp.weekObj.week_start }} - {{ wp.weekObj.week_end }}
                  </p>
                  <p class="week-total muted mrgn0">
                    {{ wp.weekObj.week_duration }} total
                  </p>
                </td>
                <td
                  :rowspan="
                    month.wps.filter(_wp => _wp.week == wp.week && _wp.context)
                      .length
                  "
                  v-if="
                    !month.wps.find(
                      (_wp, checkI) =>
                        checkI < i && _wp.week == wp.week && _wp.context,
                    )
                  "
                >
                  {{ wp.context }}
                </td>
                <td
                  :rowspan="
                    month.wps.filter(
                      _wp =>
                        _wp.week == wp.week &&
                        _wp.context &&
                        _wp.commitment == wp.commitment,
                    ).length
                  "
                  v-if="
                    !month.wps.find(
                      (_wp, checkI) =>
                        checkI < i &&
                        _wp.week == wp.week &&
                        _wp.context &&
                        _wp.commitment == wp.commitment,
                    )
                  "
                >
                  {{ wp.commitment }}
                </td>
                <td class="entry">
                  <template v-if="wp.billableCount">
                    <i style="color: #7b8f50" class="dollar sign icon"></i>
                    {{ wp.billableHours }} = {{ wp.comment }}
                    <span class="muted" style="font-size: 10px"
                      >x {{ wp.billableCount }}</span
                    >
                  </template>
                  <template v-if="wp.nonbillableCount">
                    {{ wp.nonbillableHours }} = {{ wp.comment }}
                    <span class="muted" style="font-size: 10px"
                      >x {{ wp.nonbillableCount }}</span
                    >
                  </template>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
      <div v-if="!isLoaded">loading</div>
      <div v-if="isLoaded && !workPeriods.length">no time tracked yet</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'timeTrackerJournal',
  props: {
    workPeriods: {
      type: Array,
      default: () => [],
    },
    isLoaded: {
      type: Boolean,
      default: () => false,
    }
  },
  data() {
    return {
    };
  },
  mounted() {
    let offer_token = this.$route.params.id;
    if (offer_token) {
      this.$http
        .get(
          `/api/timer/work-periods?user_id=${window.user_id}&offer_token=${offer_token}`,
        )
        .then(res => {
          this.workPeriods = res.data;
          this.isLoaded = true;
        });
    }
  },
  computed: {
    currentDate() {
      return new Date();
    },
    filteredWps() {
      const result = [...(this.workPeriods || [])];

      result.forEach(year => {
        year.months.forEach(month => {
          month.wps = [];

          month.weeks.forEach(week =>
            week.work_periods.forEach(wp => {
              if (wp.duration_seconds <= 10) return;

              let existingWp = month.wps.find(_wp => _wp.comment === wp.comment);

              if (!existingWp) {
                existingWp = wp;
                existingWp.weekObj = week;
                const propTypes = ['billable', 'nonbillable'];
                propTypes.forEach(billablePrefix => {
                  // eslint-disable-next-line no-prototype-builtins
                  if (!existingWp.hasOwnProperty(billablePrefix + 'Hours')) {
                    Object.defineProperty(existingWp, billablePrefix + 'Hours', {
                      get() {
                        const hours = Math.floor(
                          existingWp[billablePrefix + 'Seconds'] / 3600,
                        );
                        const minutes = Math.round(
                          (existingWp[billablePrefix + 'Seconds'] % 3600) / 60,
                        );
                        existingWp[billablePrefix + 'Seconds'] = 0;
                        existingWp[billablePrefix + 'Seconds'] = 0;
                        return `${hours}H ${('0' + minutes).slice(-2)}M`;
                      },
                    });
                  }
                });
                month.wps.push(existingWp);
              }

              const billablePrefix = (wp.is_billable ? '' : 'non') + 'billable';

              existingWp[billablePrefix + 'Count'] =
                (existingWp[billablePrefix + 'Count'] || 0) + 1;
              existingWp[billablePrefix + 'Seconds'] =
                (existingWp[billablePrefix + 'Seconds'] || 0) +
                (wp.duration_seconds || 0);
            }),
          );

          const grouped = [];
          const groups = [];

          month.wps.forEach(wp => {
            if (
              !groups.find(
                _wp =>
                  _wp.week === wp.week &&
                  _wp.context &&
                  _wp.commitment === wp.commitment,
              )
            ) {
              groups.push(wp);
            }
          });

          for (let group of groups.sort((a, b) =>
            a.week === b.week ? 0 : 1,
          )) {
            grouped.push(
              ...month.wps.filter(
                wp =>
                  group.week === wp.week &&
                  group.context &&
                  group.commitment === wp.commitment,
              ),
            );
          }

          month.wps = grouped;
        });
      });

      return result;
    },
  },
  methods: {
    checkCurrentMonth(year, month) {
      return (
        this.currentDate.getMonth() + 1 === month && this.checkCurrentYear(year)
      );
    },
    checkCurrentYear(year) {
      return this.currentDate.getFullYear() === year;
    },
  },
};
</script>

<style scoped>
.wp-title__year:first-of-type,
.wp-title__month:first-of-type {
  display: block !important;
}
.ui.table:last-child {
  margin-bottom: 100px !important;
}
.wp-week h4 {
  margin-bottom: 1em;
}
.week-total {
  text-transform: uppercase;
}
/*
    .spanned {
        border: 1px solid red
    }
    */
</style>
