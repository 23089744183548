<template>
  <div
    :class="[{ 'active': dragover }, 'attachment']"
    style="z-index: 1"
    @dragover.prevent="() => { dragover = true }"
    @dragleave="() => { dragover = false }"
    @drop="emitChange"
  >
    <!-- input field fits the parent div -->
    <input
      type="file"
      :id="inputName + 'FileInput'"
      @input="emitChange"
      style="opacity: 0; position: absolute; width: 0px;"
    >
    <!-- this input doesn't work w\o label -->
    <label :for="inputName + 'FileInput'">
        <slot>
          <a class="ui button mrgn0">upload file</a>
        </slot>
    </label>
  </div>
</template>

<script>
export default {
  props: {
    inputName: { type: String, default: '' },
  },
  data(){
    return {
      dragover: false
    }
  },
  methods: {
    emitChange(evt){
      evt.preventDefault?.()

      this.$emit(
        'change',
        {
          files: (
            evt.dataTransfer ?
            evt.dataTransfer.files :
            evt.target.files
          ) || []
        }
      )
    }
  }
}
</script>
