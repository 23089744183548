<template>
  <div id="postModal" class="hm deed post modal active">
    <div class="content" v-on-clickaway="cancelUpdate" @click.stop="">
      <h2 class="title">praise the deed</h2>

      <file-loader
        :formData="coverUploadFormData"
        uploadUrl="/api/accounts/profiles/deed-covers/"
        @uploaded="coverUploaded"
      />
      <div class="flex">
        <div id="editr" class="deed__outline">
          <h3 class="optional" style="margin-bottom: 23vh">required</h3>

          <div style="position: relative">
            <span class="limit">{{ countTitle }}</span>
            <input
              type="text" placeholder="title"
              class="title"   v-model="innerDeed.title"
              :maxlength="countTitleDefault"
            />
          </div>

          <div style="position: relative">
            <span class="limit">{{ countExcerpt }}</span>
            <textarea
              id="excerpt"
              placeholder="excerpt – type // to save item as DRAFT"
              v-model="innerDeed.excerpt"
              :maxlength="countExcerptDefault"
            ></textarea>
          </div>

          <!--
              <div class="highlight flex start">

                <textarea class="mrgn0" placeholder="hightlight"></textarea>
              </div>
            -->

          <div class="hm menu" style="zoom: 0.7;justify-content: end;">
            <a class="item active"> overview</a>
            <a class="item classic">process</a>
            <a class="item classic">tech</a>
            <a class="item gold">budget</a>
          </div>

          <div class="details">
            <wysiwyg v-model="innerDeed.body" />
          </div>
        </div>

        <div class="meta">
          <h3 class="optional">optional</h3>

          <p class="muted">
            don't overdo it – not every deed needs cover, client logo,
            highlights, etc
          </p>

          <!--

              featured square we use as an WYSIWG example -->

          <div id="deedCover">
            <cover-handler
              :deed="innerDeed"
              :editable="true"
              @cover-changed="coverChanged"
            />

            <img class="deedLogo" v-if="logoUrl" :src="logoUrl" />
          </div>
          <!-- square ends -->

          <!--

              controls -->

          <file-input class="" inputName="deedLogo" @change="onLoadImageLogo">
            <a class="ui button mrgn0">upload logo</a>
          </file-input>

          <div
            class="highlight flex"
            style="margin:2rem 0;position: relative;margin-left: -3.35rem"
          >
            <span
              class="limit"
              style="left: auto; right: -28px; top: calc(50% - 10px)"
              >{{ countHighlight }}</span
            >
            <div class="vaHack">
              <span
                style="zoom:2;padding: 5px;
    background: #F7F8FA"
                >🤘</span
              >
            </div>
            <div class="vaHack" style="width: 100%">
              <input
                type="text"
                placeholder="highlight"
                v-model="innerDeed.highlight"
                :maxlength="countHighlightDefault"
              />
            </div>
          </div>

          <input
            id="deedLiveLink"
            type="text"
            placeholder="live link"
            v-model="innerDeed.live_link"
          />

          <div id="deedContributors" style="width: 100%">
            <mentionable :items="allProfiles" :itemKeys="['slug']">
              <textarea
                placeholder="@contributor AS role, @contributor AS role, et al – to share the fame"
                v-model="innerDeed.contributors_meta"
              ></textarea>
              <template v-slot:item="{item}">
                <div v-if="item.user" class="flex">
                  {{ item.user.display_name || item.user.username }}
                  <div class="muted">human</div>
                </div>
                <span v-else-if="item.entity">
                  {{ item.entity.name }}
                  <span class="muted">entity</span>
                </span>
              </template>
            </mentionable>
          </div>

          <div class="button-holder">
            <button-settings-block
              :button="innerDeed.button"
              :block-name="'deedButton'"
            />
          </div>
          <div class="form__radios" style="width: 100%">
            <div class="form__radio">
              <label for="deed-type-3">magnum opus</label>
              <input
                id="deed-type-3"
                value="magnum opus"
                name="type"
                type="radio"
                v-model="innerDeed.deed_type"
              />
            </div>

            <div class="form__radio active">
              <label for="deed-type-1">featured</label>
              <input
                id="deed-type-1"
                value="featured"
                name="type"
                type="radio"
                v-model="innerDeed.deed_type"
              />
            </div>
            <div class="form__radio">
              <label for="deed-type-2">footnote</label>
              <input
                id="deed-type-2"
                value="footnote"
                name="type"
                type="radio"
                v-model="innerDeed.deed_type"
              />
            </div>
          </div>

          <div class="post">
            <button
              class="hm button core mrgn0"
              @click.prevent="saveDeed()"
              :disabled="disabledPost"
            >
              post
            </button>
          </div>
        </div>
        <!-- meta sidebar ends -->
      </div>
      <!-- flex ends -->

      <div class="flex">
        <div
          class="flex"
          style="width: 100%; border: 1px solid red; padding: 2rem;"
        >
          <div class="w50">
            <h3>purpose</h3>

            > get more projects like that\clients\feature some service\product >
            amusement alone
          </div>
          <div class="w50">
            <h3>verification</h3>
          </div>
        </div>

        <h2 style="font-size: 150%;margin: 4rem;text-align: right;">
          examples <br />
          for those <br />
          who can read ↓
        </h2>
      </div>

      <table class="hm table plain">
        <thead>
          <tr>
            <td></td>
            <td>file formats</td>
            <td>size</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td><h4>rectangle</h4></td>
            <td>
              <ul class="pdng0">
                <li>png, jpeg, gif</li>
                <li>Animated gifs (max file size: 3MB)</li>
              </ul>
            </td>
            <td>1600x1200px</td>
          </tr>

          <tr>
            <td><h4>video</h4></td>
            <td>
              <ul class="pdng0">
                <li>mp4</li>
              </ul>
            </td>
            <td>whatever</td>
          </tr>

          <tr>
            <td><h4>square</h4></td>
            <td>
              <ul class="pdng0">
                <li>png, jpeg, gif</li>
                <li>Animated gifs (max file size: 3MB)</li>
              </ul>
            </td>
            <td>1200x1200px</td>
          </tr>

          <tr>
            <td><h4>hacked square</h4></td>
            <td>
              <ul class="pdng0">
                <li>png, jpeg, gif</li>
                <li>Animated gifs (max file size: 3MB)</li>
              </ul>
            </td>
            <td>1202x1202px</td>
          </tr>
        </tbody>
      </table>

      <div class="deeds__tile">
        <div class="item magnum">
          <div class="b-item-inner">
            <h2>magnum opus</h2>
            <p>
              Keep your title length around 60 characters. For your meta
              description length, keep it around 160 characters. Although title
              tags and meta title tags and meta
            </p>
          </div>
        </div>

        <div class="item">
          <div class="b-item-inner">
            <h2>featured</h2>
            <p>excerpt</p>
            <p>highlight</p>
          </div>
        </div>

        <div class="item visual">
          <div class="b-item-inner">
            <img
              src="https://cdn.dribbble.com/userupload/2986877/file/original-338602e796dd39345b4be2ba15ac0250.png"
              width="100%"
            />
            <h2>rectangle cover (1600x1200)</h2>
          </div>
        </div>

        <div class="item visual">
          <div class="b-item-inner">
            <img
              src="https://holons.me/static/img/deed-square.jpeg"
              width="100%"
            />
          </div>
        </div>

        <div class="item easterHack">
          <div class="b-item-inner">
            <img
              src="https://holons.me/static/img/deed-hacked-square.png"
              width="100%"
            />
          </div>
        </div>

        <div class="item info">
          <div class="b-item-inner">
            <h2>
              have <br />
              a different <br />
              format <br />
              in mind?
            </h2>
            <br /><br />
            <p>
              Ping
              <a href="/grintender" target="_blank" class="hm link"
                >@grintender</a
              >
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mixin as clickaway} from 'vue-clickaway';

import mentionable from '@/global/mention';
import wysiwyg from '@/_editr/wysiwyg';
import CoverHandler from './coverHandler.vue';
import {createBase64Image} from '../../utils/images.js';
import FileInput from '../../global/fileInput.vue';
import FileLoader from '../../global/fileLoader.vue';
import buttonSettingsBlock from '@/_editr/partials/buttonSettingsBlock';

const API_URL = '/api/accounts/profiles/deeds';

const defaultNewDeed = {
  title: '',
  body: '',
  excerpt: '',
  logo: null,
  cover: null,
  highlight: '',
  live_link: '',
  deed_type: 'featured',
  contributors_meta: '',

  // internal
  logoUrl: '',
  oldCover: null,
};
const deedRequiredFields = ['title', 'excerpt', 'body'];
const deedFileFields = ['logo'];

export default {
  props: ['profile', 'deed'],
  mixins: [clickaway],
  components: {
    mentionable,
    wysiwyg,
    CoverHandler,
    FileInput,
    FileLoader,
    buttonSettingsBlock,
  },
  data() {
    FileInput;
    return {
      innerDeed: {...defaultNewDeed},
      oldDeed: null,

      countTitleDefault: 60,
      countTitle: 60,
      countExcerptDefault: 160,
      countExcerpt: 160,
      countHighlightDefault: 60,
      countHighlight: 60,

      allProfiles: [],

      needToEdit: false,

      logoUrl: '',

      coverUploadFormData: null,

      coverSaved: true,
      saved: false,
    };
  },
  computed: {
    /**@returns {Boolean} */
    disabledPost() {
      return deedRequiredFields.some(f => !this.innerDeed[f]);
    },
  },
  watch: {
    deed: {
      handler() {
        if (this.deed) {
          this.innerDeed = this.deed;
          this.oldDeed = {...this.deed};
          this.needToEdit = true;
        } else {
          this.innerDeed = {...defaultNewDeed};
          this.needToEdit = false;
        }
        this.logoUrl = this.innerDeed.logo;
      },
      immediate: true,
    },
    'innerDeed.title': function(newVal) {
      // count number of symbols left for the title field
      this.countTitle = this.countTitleDefault - newVal.length;
    },
    'innerDeed.excerpt': function(newVal) {
      // count number of symbols left for the excerpt field
      this.countExcerpt = this.countExcerptDefault - newVal.length;
    },
    'innerDeed.highlight': function(newVal) {
      // count number of symbols left for the highlight field
      this.countHighlight = this.countHighlightDefault - newVal.length;
    },
  },
  methods: {
    async onLoadImageLogo({files}) {
      this.innerDeed.logo = files[0]; // get first file
      this.logoUrl = await createBase64Image(this.innerDeed.logo);
    },

    saveDeed() {
      const data = {
        title: this.innerDeed.title,
        body: this.innerDeed.body,
        button: JSON.stringify(this.innerDeed.button),
        excerpt: this.innerDeed.excerpt,
        highlight: this.innerDeed.highlight,
        live_link: this.innerDeed.live_link,
        deed_type: this.innerDeed.deed_type,
        contributors_meta: this.innerDeed.contributors_meta,
        published_at_id: this.profile?.id,

        cover_id: this.innerDeed.cover?.id || null,
      };

      const formData = new FormData();
      Object.entries(data).forEach(([k, v]) => {
        if (v != null) formData.append(k, v);
      });

      deedFileFields.forEach(fName => {
        const value = this.innerDeed[fName];
        if (value instanceof File) {
          formData.append(fName, value, value.name);
        }
      });

      let createdDeed;
      if (!this.needToEdit) {
        createdDeed = {
          ...this.innerDeed,
          logo: this.logoUrl,
          contributions: [],
        };
        this.$emit('deed-created', createdDeed);
      }

      this.$http[this.needToEdit ? 'patch' : 'post'](
        `${API_URL}/${this.needToEdit ? this.deed.id : ''}`,
        formData,
      ).then(res => {
        this.innerDeed = {...defaultNewDeed};
        try {
          Object.assign(createdDeed, res.data);
        } catch (e) {
          createdDeed = {};
        }
        this.$emit('deed-saved');
      });

      if (this.innerDeed.oldCover) {
        this.$http.delete(
          `/api/accounts/profiles/deed-covers/${this.innerDeed.oldCover.id}`,
        );
      }
      this.saved = true;
      this.$root.$emit('close-modals');
    },

    async coverChanged(coverInfo) {
      const formData = new FormData();
      Object.entries(coverInfo).forEach(([k, v]) => formData.append(k, v));

      this.coverUploadFormData = formData;
    },
    coverUploaded(response) {
      this.innerDeed.cover = response.data;

      this.coverUploadFormData = null;

      this.coverSaved = false;
    },

    cancelUpdate() {
      if (!this.coverSaved) {
        this.$http.delete(
          `/api/accounts/profiles/deed-covers/${this.innerDeed.cover.id}`,
        );
      }

      try {
        Object.assign(this.deed, this.oldDeed);
      } catch (e) {
        this.deed = {};
      }
      this.$root.$emit('close-modals');
    },
  },
  mounted() {
    this.$http.get('/api/accounts/profiles/all/').then(res => {
      this.allProfiles = res?.data || [];
    });

    // window.addEventListener('keyup', evt => {
    //   if (evt.code == 'Escape') this.cancelUpdate();
    // });
  },

  beforeDestroy() {
    if (!this.saved) this.cancelUpdate();
  },
};
</script>
