import { format as formatDate } from 'timeago.js'

const getCookie = (name) =>  {
  let matches = document.cookie.match(new RegExp(
    // eslint-disable-next-line
    "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
  ));
  return matches ? decodeURIComponent(matches[1]) : undefined;
}

const holonsHeaders = {
  'Content-Type': 'application/json',
  'X-CSRFToken': window.csrftoken || getCookie('csrftoken') // TODO: get it from cookies
}

export { holonsHeaders, formatDate, getCookie }
