<template>
  <div id="viewModal" class="hm item word view modal active">
    <div class="content" v-on-clickaway="close" @click.stop="" v-if="word">
      <div id="" v-if="isEditable">
        <a class="ui button inverted mrgn0" @click="emitEdit">edit</a>

        <br /><br />

        <a
          id="word-destroy"
          class="ui button destroy mrgn0"
          @click.prevent="deleteWord(word.id)"
        >
          delete
        </a>
      </div>

      <div id="itemSingle">
        <h1>{{ word.title }}</h1>

        <p id="excerpt">{{ word.excerpt }}</p>
        <div class="word-body" v-html="word.word_html"></div>
      </div>

      <div id="ctaHolder" class="flex">
        <div class="vaHack">
          {{ word.button.explainer }}
        </div>

        <div class="vaHack">
          <a :class="['hm', 'button', 'core', 'mrgn0', word.button.class]">{{
            word.button.text
          }}</a>
        </div>
      </div>

      <tips-block />
      <!---

      <div class="left__col">

        <div v-if="word.logo">
          <h3 class="collab">x</h3>

          <a href="#">
            <img id="word_logo"
              class="word_logo"
              :src="word.logo"
            />
          </a>
        </div>

        <div id="highlight" v-if="word.highlight">
          <span class="yo">🤘</span>
          {{ word.highlight }}
        </div>


        <div v-if="word.live_link"
        style="text-align: center">

          <a :href="word.live_link" id="wordLiveLink"
          target="_blank">{{ word.live_link }}</a>
        </div>

      </div>  left column ends

      <div class="right__col" v-if="false">

        <div class="word__meta">
          <span class="check">✓</span>
          <span class="verified">verified word</span>
        </div>

        <div id="contributors__fame">

          <div class="contributor flex start">
            <div class="vaHack">
              <a href="#">
                <img src="/usg/usg/soulspics/2021-06-21_14.00.17_dEB3gSQ.jpeg">
              </a>
            </div>
            <div class="vaHack">
              Dmitrii B. – lead developer
            </div>
          </div>

          <p class="muted">legacy:</p>
          {{ word.contributors_meta }}
        </div>
      </div>  right column ends -->
    </div>
  </div>
</template>

<script>
import {mixin as clickaway} from 'vue-clickaway';

import {identityBus} from '../bus';
import tipsBlock from '@/global/moneta/tips/tipsBlock'

export default {
  name: 'wordViewModal',
  mixins: [clickaway],
  components: {
    tipsBlock,
  },
  props: {
    word: Object,
  },
  data() {
    return {
      bus: identityBus,
      isEditable: false,
    };
  },
  computed: {
    /**@returns {String} */
    apiUrl() {
      return '/api/accounts/words/';
    },
  },
  mounted() {},
  methods: {
    deleteword() {
      const url = `/api/accounts/profiles/words/${this.word.id}/`;

      this.$http.delete(url).then(response => {
        if (response) {
          this.close();
          this.$emit('word-deleted');
        }
      });
    },
    close() {
      this.$root.$emit('close-modals');
    },
    emitEdit() {
      this.bus.$emit('edit-word', this.word);
    },
  },
};
</script>
