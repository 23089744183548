<template>
  <div class="deeds_holder">
    <div
      class="hm menu inverted"
      style="zoom: 0.85; margin-top: -3rem; padding-left: 1rem;"
    >
      <a
        :class="['item', {active: role == activeRole}]"
        v-for="role in roles"
        :key="role"
        @click="() => chooseRole(role)"
        >{{ role || 'all' }}</a
      >
      <!-- <a class="item">art director</a>
      <a class="item">product owner</a>
      <a class="item">entrepreneur</a>
      <a class="item">role #4</a>
      <a class="item">role #5</a>
      <a class="item">role #6</a> -->
    </div>

    <a
      id="draftDeedBtn"
      class="hm button core mrgn0"
      @click.stop="showDeedDraftModal()"
      v-if="profile.is_owner"
      >+ draft new deed</a
    >

    <!--{{ profile.name }}-->

    <draggable
      :class="['deeds__tile', {dropzone: dragTo == 'tile'}]"
      group="deed"
      draggable=".item"
      :value="deedsByViews.tile"
      v-if="deedsByViews.tile.length"
      @start="startDrag"
      @change="evt => endDrag(evt, 'tile')"
      @end="
        () => {
          dragTo = '';
        }
      "
      @dragenter.native="
        () => {
          dragTo = 'tile';
        }
      "
    >
      <!--<div class="">
          <h3 class="crimson">convo starters</h3>

          <a class="hm button core rayme">message</a> <br>
          <a class="hm button core meet mrgn0">meet</a>
          <a class="hm button core teleport">chat</a>
          <a class="hm button core moneta">$100 book a session</a>

        </div>-->

      <div
        :class="['item', deed.cover && deed.cover.view_type, deed.deed_type]"
        v-for="deed in deedsByViews.tile"
        :key="deed.id"
        @click.stop="showDeedViewModal(deed)"
        :id="`deed-${deed.id}`"
      >
        <!-- magnum opus deed starts -->
        <div
          class="b-item-inner noclickout"
          v-if="deed.deed_type == 'magnum opus'"
        >
          <!--<div class="muted">{{ deed.deed_type }}</div>
          <div class="muted" v-if="!deed.is_contributed">owned</div>-->

          <img v-if="deed.logo" class="deedLogo" :src="deed.logo" />

          <img
            v-if="deed.cover && !deed.cover.is_video"
            :src="deed.cover.file"
            width="100%"
          />
          <video
            v-if="deed.cover && deed.cover.is_video"
            :src="deed.cover.file"
            width="100%"
            autoplay
            muted
          />
          <h2>{{ deed.title }}</h2>
          <p class="muted">{{ deed.excerpt }}</p>
          <p class="muted">highlight: {{ deed.highlight }}</p>

          <!-- roles -->
          <div
            v-for="contribution in deed.contributions.filter(
              filterContributions,
            )"
            :key="contribution.id"
          >
            {{ contribution.role }}
          </div>
          <!-- roles end -->
        </div>

        <!-- featured deed starts -->
        <div
          class="b-item-inner noclickout"
          v-if="deed.deed_type == 'featured'"
        >
          <!--<div class="muted">featured</div>
            <div class="muted" v-if="!deed.is_contributed">owned</div>-->

          <img v-if="deed.logo" :src="deed.logo" class="deedLogo" />

          <img
            v-if="deed.cover && !deed.cover.is_video"
            :src="deed.cover.file"
            width="100%"
          />
          <video
            v-if="deed.cover && deed.cover.is_video"
            :src="deed.cover.file"
            width="100%"
            autoplay
            muted
          />
          <h2>{{ deed.title }}</h2>
          <p class="muted">{{ deed.excerpt }}</p>
          <p class="muted">{{ deed.highlight }}</p>

          <!-- roles -->
          <div
            v-for="contribution in deed.contributions.filter(
              filterContributions,
            )"
            :key="contribution.id"
          >
            <div class="role">
              {{ contribution.role }}
            </div>
          </div>
          <!-- roles end -->
        </div>
      </div>
    </draggable>
    <!--

        deeds tile ends -->

    <!-- footnote deeds starts -->
    <table
      class="hm table plain"
      style="margin: 0 2rem"
      v-if="deedsByViews.table.length"
    >
      <draggable
        tag="tBody"
        :class="['deeds__table', {dropzone: dragTo == 'table'}]"
        group="deed"
        :value="deedsByViews.table"
        @start="startDrag"
        @change="evt => endDrag(evt, 'table')"
        @end="
          () => {
            dragTo = '';
          }
        "
        @dragenter.native="
          () => {
            dragTo = 'table';
          }
        "
      >
        <tr
          v-for="deed in deedsByViews.table"
          :key="deed.id"
          :id="`deed-${deed.id}`"
          @click.stop="
            () => {
              showDeedEditModal(deed);
            }
          "
        >
          <td>
            <h4>{{ deed.title }}</h4>
          </td>
          <td><p class="mrgn0 muted" v-html="deed.excerpt"></p></td>
        </tr>
      </draggable>
    </table>
    <!-- footnote deeds ends -->

    <div v-if="!existingDeeds.length && !deedsLoading">
      Here's no deeds we know about
    </div>

    <div v-if="deedsLoading">
      <!--Deeds loading...-->

      <div class="deeds__tile">
        <div class="item"></div>
        <div class="item"></div>
        <div class="item"></div>

        <div class="item"></div>
        <div class="item"></div>
        <div class="item"></div>

        <div class="item"></div>
        <div class="item"></div>
        <div class="item"></div>
      </div>
    </div>

    <div id="private" v-if="profile.is_owner">
      <div class="item">
        <div class="inner">
          <h5>can't comb a deed in a way you want?</h5>
          <p class="mrgn0">talk to <a href="/grintender">@grintender</a></p>
        </div>
      </div>

      <div class="item">
        <div class="inner">
          <h5>want a nice design?</h5>
          <p class="mrgn0">talk to <a href="/pheme">@pheme</a></p>
        </div>
      </div>

      <div class="item">
        <div class="inner">
          <h5>drag-n-drop to re-order items</h5>
        </div>
      </div>

      <div class="item">
        <div class="inner">
          <h5>get your deeds verified</h5>
        </div>
      </div>

      <div class="item">
        <div class="inner">
          <h5>ad slot #1</h5>
        </div>
      </div>

      <div class="item">
        <div class="inner">
          <h5>ad slot #2</h5>
        </div>
      </div>
    </div>

<!--
    <div id="identityIntro" style="margin: 2rem">
      <p data-v-ef8f0b64="">
        Родом и сердцем из Петербурга, получил образование в Финляндии \Швеции,
        гражданство в Израиле, работал сначала офлайн в Барселоне, Панаме, а
        позже и людьми со всех концов света когда пришел в диджитал.
      </p>
      <p data-v-ef8f0b64="">✍️ Руками умею в</p>
      <ul data-v-ef8f0b64="">
        <li data-v-ef8f0b64="">
          дизайн и продукт (верстаю сразу руками, без макетов),
        </li>
        <li data-v-ef8f0b64="">
          маркетинг (в основном SEO, paid и всякие нетривиальные штуки –
          например распарсил Редит и строю свою Кембридж Аналитику)
        </li>
        <li data-v-ef8f0b64="">
          продажи (оч люблю встречи, хоть они теперь и в зуме)
        </li>
        <li data-v-ef8f0b64="">
          🤙 Диджитал – ван лав – люблю продуктовую разработку и агентский
          бизнес.
        </li>
      </ul>
      <p data-v-ef8f0b64="">
        Сделал агентство GRIN tech и ряд productised сервисов вроде аутрич
        платформы, приуныл что с количеством заказов растет количество хаоса, и
        решил сделать платформу для управления проектами вместо закидывания
        проблемы людьми.
      </p>
      <p data-v-ef8f0b64="">
        Поэтому сейчас занимаюсь собственной продуктовой разработкой, работаю
        проджектом в одном израильском агентстве, делаю несколько клиентских
        проектов и в целом наслаждаюсь нашей индустрией.
      </p>
      <p data-v-ef8f0b64="">За пределами работы:</p>
      <ul data-v-ef8f0b64="">
        <li data-v-ef8f0b64="">👉 КМС по гребле на байдарках,</li>
        <li data-v-ef8f0b64="">
          👉 люблю серф и хорошую литературу (на ум сразу приходят: Смерть
          Артемио Круса, Атлант Расправил Плечи, Сироп Макса Бари и другие его
          книги, Задача Трех Тел Лю Цысиня, Возращение со Звезд Лема)
        </li>
      </ul>
      <p data-v-ef8f0b64="">🤘Рад помочь и делом и советом (:</p>
    </div>
-->
    <post-modal
      v-if="profile.is_owner && deedPostModalActive"
      :profile="profile"
      :deed="deedToEdit"
      @deed-saved="loadDeeds"
      @deed-created="deedCreated"
    />

    <view-modal
      v-if="deedViewModalActive"
      :deed="selectedDeed"
      :profile="profile"
      ref="deedViewModal"
      @deed-deleted="deedDeleted"
    />
  </div>
</template>
<script>
import draggable from 'vuedraggable';

import viewModal from './viewModal.vue';
import postModal from './postModal.vue';
import {identityBus} from '../bus';

const API_URL = '/api/accounts/profiles/deeds';

const deedViews = {
  tile: ['magnum opus', 'featured'],
  table: ['footnote'],
};
const invertedDeedViews = {};
Object.entries(deedViews).forEach(([view, types]) =>
  types.forEach(t => (invertedDeedViews[t] = view)),
);

export default {
  name: 'deedsTab',
  components: {
    viewModal,
    postModal,
    draggable,
  },
  props: {
    profile: {
      type: Object,
    },
  },
  data() {
    return {
      existingDeeds: [],
      deedsLoading: true,

      byContribution: false,

      activeRole: null,

      bus: identityBus,

      selectedDeed: {},
      deedToEdit: null,

      dragTo: '',

      deedPostModalActive: false,
      deedViewModalActive: false,
    };
  },
  computed: {
    /**@returns {Array} */
    roles() {
      // if activeRole is null it means that all deeds should be showed
      const roles = [null];

      this.existingDeeds.map(d =>
        d.contributions?.filter(this.filterContributions).forEach(c => {
          if (!roles.includes(c.role)) roles.push(c.role);
        }),
      );
      return roles;
    },
    /**@returns {Array} */
    filteredDeeds() {
      if (!this.activeRole) return this.existingDeeds;

      return this.existingDeeds.filter(d =>
        d.contributions?.find(
          c => c.role == this.activeRole && this.filterContributions(c),
        ),
      );
    },
    /**@returns {Object} */
    deedsByViews() {
      const ret = {};
      Object.entries(deedViews).forEach(([viewName, types]) => {
        ret[viewName] = this.filteredDeeds
          .filter(d => types.includes(d.deed_type))
          .sort((a, b) => a.order - b.order);
      });

      return ret;
    },
  },
  watch: {
    profile: {
      handler() {
        this.deedsLoading = true;
        this.existingDeeds = [];
        this.activeRole = null;
        this.loadDeeds();
      },
      immediate: true,
    },
    byContribution: {
      handler() {
        this.loadDeeds();
      },
    },
  },
  mounted() {
    this.$root.$on('close-modals', this.closeModals);
    this.bus.$on('edit-deed', deed => {
      this.deedToEdit = deed;
      this.showDeedPostModal();
      this.deedViewModalActive = false;
    });

    document.addEventListener('dragend', () => {
      this.dragTo = '';
    });
  },
  methods: {
    loadDeeds() {
      if (!this.profile.handle && !this.profile.slug) return;

      let slug = this.profile.handle ? this.profile.handle : this.profile.slug;
      let url = `${API_URL}/?profile_slug=${slug}`;
      return this.$http
        .get(url)
        .then(response => {
          if (response) {
            this.existingDeeds = response.data;
            // this.bus.$emit('edit-deed', this.existingDeeds[0])

            if (this.bus.activeTab === 'deeds' && this.bus.activeItem) {
              this.showDeedEditModal(
                this.existingDeeds.find(el => {
                  return el.slug === this.bus.activeItem;
                }),
              );
            }
          }
        })
        .finally(() => {
          this.deedsLoading = false;
        });
    },

    filterContributions(contribution) {
      return contribution.profile_id == this.profile.id && contribution.role;
    },

    showDeedEditModal(deed) {
      this.selectedDeed = deed;
      this.deedPostModalActive = true;
    },
    showDeedDraftModal() {
      this.deedToEdit = null;
      this.showDeedPostModal();
    },
    showDeedViewModal(deed) {
      this.selectedDeed = deed;
      this.deedViewModalActive = true;
    },
    showDeedPostModal() {
      this.deedPostModalActive = true;
      this.deedViewModalActive = true;
    },
    closeModals() {
      this.deedPostModalActive = false;
      this.deedViewModalActive = false;
    },
    deedCreated(deed) {
      this.existingDeeds.unshift(deed);
    },
    deedDeleted() {
      this.loadDeeds().then(() => {
        if (!this.roles.includes(this.activeRole)) this.activeRole = null;
      });
    },
    chooseRole(role) {
      this.activeRole = role;
    },
    startDrag(evt) {
      evt;
    },
    endDrag({moved, added}, view) {
      const deed = (moved || added)?.element;
      if (!deed) return;

      if (added) {
        deed.deed_type = deedViews[view][0];
        this.$http.patch(`${API_URL}/${deed.id}/`, {
          deed_type: deed.deed_type,
        });
      }

      const oldArr = [...this.deedsByViews[view]];

      const newIndex = (moved || added).newIndex;

      oldArr.splice(oldArr.indexOf(deed), 1);
      oldArr.splice(newIndex, 0, deed);
      oldArr.forEach((d, i) => {
        d.order = i + 1;
      });
      this.$http.patch(`${API_URL}/bulk-update-order/`, {
        orders: oldArr.reduce((orders, d) => {
          orders[d.id] = d.order;
          return orders;
        }, {}),
      });
    },
  },
};
</script>

<style scoped>
.deeds_holder {
  width: 100%;
}
</style>
