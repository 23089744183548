<template>
  <div>
    <!-- errors -->
    <div class="crimson" v-if="coverFileError == 'Dimensions'">
      size is wrong, see table below
    </div>
    <div class="crimson" v-if="coverFileError == 'Size'">
      file is too big, max size is {{ maxFileSize }} MB
    </div>
    <div class="crimson" v-if="coverFileError == 'Extension'">
      this file format is NOT supported, see table below
    </div>
    <div class="crimson" v-if="coverFileError == 'ExtensionDimensions'">
      both file format AND size are wrong, see table below
    </div>
    <!-- errors end -->
    <img
      v-if="deed.cover && !deed.cover.is_video"
      :src="deed.cover.file" alt="" width="100%"
    >
    <video
      v-if="deed.cover && deed.cover.is_video"
      :src="deed.cover.file" width="100%"
      autoplay  muted
    />
    <file-input
      v-if="editable"
      :inputName="deed.id + 'DeedCover'"
      @change="changeCover"
    >
      <template>
        <a id="uploadCoverBtn"
        class="ui button mrgn0">upload cover</a>
      </template>
    </file-input>

  </div>
</template>

<script>
import { createBase64Image } from '../../utils/images.js'
import FileInput from '../../global/fileInput.vue'

const coverTypes = [
  {
    name: 'easterHack',
    types: ['png', 'gif'],
    resolutions: [{ w: 1202, h: 1202 }]
  },
  {
    name: 'sqImage',
    types: ['png', 'jpeg', 'jpg', 'gif'],
    resolutions: [{ w: 1200, h: 1200 }]
  },
  {
    name: 'sqImage',
    types: ['gif'],
    resolutions: [{ w: 338, h: 338 }]
  },
  {
    name: 'visual',
    types: ['png', 'jpeg', 'jpg', 'gif', 'video'],
    resolutions: [{ w: 1600, h: 1200 }]
  },
]

export default {
  props: {
    editable: { type: Boolean, default: false },
    deed: { type: Object }
  },
  components: { FileInput },
  data(){
    return {
      dragover: false,
      coverFile: null,

      coverFileError: '',

      maxFileSize: 30 // MB
    }
  },
  watch: {
    deed: {
      handler(){
        this.coverFileError = ''
        this.coverFile = null
      }
    },
  },
  methods: {
    async changeCover({ files }){
      this.coverFileError = ''

      this.coverFile = files[0]
      if(this.coverFile.size > this.maxFileSize * (1024 * 1024)) {
        this.coverFileError = 'Size'
        return
      }

      if(!coverTypes.find(ct => ct.types.find(t => this.coverFile.type.includes(t)))) {
        this.coverFileError = 'Extension'
        return
      }

      let coverType
      let coverIsVideo = false
      if(this.coverFile.type.includes('video')){
        coverType = await this.checkVideo(this.coverFile)
        if(coverType) coverIsVideo = true
      }

      if(!coverType){
        const { fileW, fileH } = await this.getImageDimensions()


        for(let { name, types, resolutions } of coverTypes){
          if(
            types.find(t => this.coverFile.type.includes(t)) &&
            resolutions.find(({ h, w }) => h == fileH && w == fileW)
          ){
            coverType = name
            continue
          }
        }
      }

      if(coverType){
        this.$emit('cover-changed', {
          view_type: coverType,
          is_video: coverIsVideo,
          file: this.coverFile,
        })
      }else{
        this.coverFileError = 'Dimensions'
      }
    },

    getImageDimensions(){
      return new Promise(async resolve => {
        const img = new Image()
        img.onload = () => {
          resolve({
            fileW: img.width,
            fileH: img.height
          })
        }

        img.src = await createBase64Image(this.coverFile)
      })
    },

    /**@param {File} video */
    async checkVideo(video){
      if(!video.type.includes('mp4')){
        this.coverFileError = 'Extension'
        return
      }

      const coverType = coverTypes.find(({ types }) => types.includes('video'))?.name

      if(coverType){
        return coverType
      }else{
        this.coverFileError = 'Extension'
        return
      }
    }
  }
}
</script>
