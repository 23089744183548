<template>
  <button
    class="hotkeys-handler"
    v-shortkey="{
      closeModals: ['esc'],
      agenda: ['a'],
      agendaCyr: ['ф'],
      relations: ['r'],
      relationsCyr: ['к'],
      layers: ['l'],
      layersCyr: ['д'],
      moneta: ['m'],
      monetaCyr: ['ь'],
      identity: ['i'],
      identityCyr: ['ш'],
      purpose: ['p'],
      purposeCyr: ['з'],
      rays: ['1'],
      teleport: ['2'],
      mail: ['3'],
      schedule: ['4'],
      magic: ['5'],
      digest: ['6'],
      platos: ['7'],
      forac: ['0'],
      rootCreate: ['§'],
      rootCreateCyr: ['ё'],
      fullscreen: ['f'],
      fullscreenCyr: ['а'],
      startTimer: ['t'],
      startTimerCyr: ['е'],
      logTimerEntry: ['k'],
      logTimerEntryCyr: ['л'],

      directory: ['d'],
      directoryCyr: ['в'],

      connections: ['c'],
      connectionsCyr: ['с'],

      fora: ['o'],
      foraCyr: ['щ'],
      faith: ['v'],
      faithCyr: ['м'],

      broker: ['b'],
      brokerCyr: ['и'],
      functions: ['/'],
      functionsCyr: ['.'],
      //say: ['s'],
      //sayCyr: ['ы'],
      arrowUp: ['arrowup'],
      arrowDown: ['arrowdown'],
      arrowRight: ['arrowright'],
      arrowLeft: ['arrowleft'],
    }"
    @shortkey="shortkeyGoTo"
  />
</template>

<script>
export default {
  name: 'shortKeysNavigation',
  mounted() {
    window.addEventListener('keyup', ev => {
      this.keyCheck(ev);
    });
  },
  methods: {
    keyCheck(event) {
      if (event.key == '+') {
        if (this.$router.currentRoute.fullPath == '/rays') {
          // @TODO: unfuck this.. use single flow of events enstead of raw javascript etc
          document
            .querySelector('#raysWriteMessageModal')
            .classList.add('active');
        }
      }
    },
    navigate(path) {
      // navigate across Vue router paths, process errors,
      // avoid redundant navigation events duplication
      this.$router.push(path).catch(err => {
        if (
          err.name !== 'NavigationDuplicated' &&
          !err.message.includes(
            'Avoided redundant navigation to current location',
          )
        ) {
          console.error(err);
        }
      });
    },
    shortkeyGoTo(event) {
      switch (event.srcKey) {
        case 'closeModals':
          this.$root.$emit('close-modals');
          break;
        case 'arrowUp':
          this.$root.$emit('arrow-navigate-vert', 1);
          break;
        case 'arrowDown':
          this.$root.$emit('arrow-navigate-vert', -1);
          break;
        case 'arrowRight':
          this.$root.$emit('arrow-navigate', 1);
          break;
        case 'arrowLeft':
          this.$root.$emit('arrow-navigate', -1);
          break;

        case 'fullscreen':
        case 'fullscreenCyr':
          var screenfull = window.screenfull;
          if (screenfull.isEnabled) {
            screenfull.request();
          }
          break;
        case 'agenda':
        case 'agendaCyr':
          this.navigate('/agenda');
          break;

        case 'relations':
        case 'relationsCyr':
          this.navigate('/relations/offers');
          break;
        case 'layers':
        case 'layersCyr':
          this.navigate('/layers');
          break;
        case 'moneta':
        case 'monetaCyr':
          this.navigate('/moneta');
          break;
        case 'purpose':
        case 'purposeCyr':
          this.navigate('/purpose/');
          break;
        case 'identity':
        case 'identityCyr':
          this.navigate(`/${this.$handle}`);
          break;
        case 'rays':
          this.navigate('/rays');
          break;
        case 'teleport':
          this.navigate('/teleport');
          break;
        case 'magic':
          location.href = this.copyMagicLink(this.magicLink) + '#copied';
          break;
        case 'mail':
          this.navigate('/mail');
          break;
        case 'schedule':
          this.navigate('/schedule');
          break;
        case 'digest':
          this.navigate('/digest');
          break;
        case 'platos':
          this.navigate('/platos-flywheel');
          break;
        case 'rootCreate':
        case 'rootCreateCyr':
          this.navigate('/root/create');
          break;
        case 'connections':
        case 'connectionsCyr':
          this.navigate('/connections');
          break;
        case 'dial':
          location.pathname = '/dial';
          break;
        case 'hearsay':
          location.pathname = '/hearsay';
          break;
        case 'startTimer':
        case 'startTimerCyr':
          window.bus.$emit('start-timer');
          break;
        case 'logTimerEntry':
        case 'logTimerEntryCyr':
          window.bus.$emit('log-timer-entry');
          break;

        case 'fora':
        case 'foraCyr':
          if (
            this.$router.currentRoute.fullPath !== '/platos-flywheel' &&
            this.$router.currentRoute.fullPath !== '/digest'
          ) {
            this.navigate('/fora');
          }

          break;

        case 'forac':
          this.navigate('/fora/communities');
          break;

        case 'directory':
        case 'directoryCyr':
          this.navigate('/fora/directory');
          break;

        case 'faith':
        case 'faithCyr':
          this.navigate('/faith-VC');
          break;

        case 'broker':
        case 'brokerCyr':
          this.$root.$emit('toggle-broker-drawer');
          break;

        case 'functions':
        case 'functionsCyr':
          document
            .querySelector('#functionsPreview')
            .classList.toggle('is-open');
          break;

        case 'say':
        case 'sayCyr':
          document
            .querySelector('#teleportPreview')
            .classList.toggle('is-open');
          break;
      }
    },
  },
};
</script>
