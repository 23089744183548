<template>
  <div class="file-loader__cont" v-show="needShowLoader">
    <div
        class="file-loader__bar"
        :style="{ width: `${progress}%` }"
        ref='bar'
    />
  </div>
</template>

<script>
const MIN_FILE_SIZE_FOR_LOADER = 1.5 * 1024 * 1024

export default {
    props: {
        uploadUrl: String,
        uploadMethod: { default: 'post' },
        formData: {}
    },
    data(){
        return {
            progress: 0,
            fileRes: null,
            transitionEnded: true
        }
    },
    computed: {
        /**@returns {Boolean} */
        isFormDataValid(){
            return this.formData && this.formData instanceof FormData
        },
        /**@returns {Boolean} */
        needShowLoader(){
            if(!this.isFormDataValid) return false

            const allFileSize = Array.from(this.formData.values()).reduce((allFilesSize, f) => {
                if(f instanceof File) allFilesSize += f.size

                return allFilesSize
            }, 0)

            return allFileSize > MIN_FILE_SIZE_FOR_LOADER
        }
    },
    watch: {
        formData: {
            handler(){
                if(this.isFormDataValid) this.startUpload()

                this.fileRes = null
                this.progress = 0
                this.transitionEnded = true
            },
            immediate: true
        },
    },
    mounted(){
        this.$refs.bar.addEventListener('transitionstart', () => {
            this.transitionEnded = false
        })
        this.$refs.bar.addEventListener('transitionend', () => {
            this.transitionEnded = true

            this.finishUpload()
        })
    },
    methods: {
        async startUpload(){
            this.$http[this.uploadMethod](this.uploadUrl, this.formData, {
                onUploadProgress: (evt) => {
                    const totalLength = evt.lengthComputable ?
                        evt.total :
                        (
                            evt.target.getResponseHeader('content-length') ||
                            evt.target.getResponseHeader('x-decompressed-content-length')
                        )

                    if(totalLength){
                        this.progress = Math.round( (evt.loaded * 100) / totalLength )
                    }

                    this.finishUpload()
                }
            }).then(r => {
                this.fileRes = r

                this.finishUpload()
            })
        },
        finishUpload(){
            if(
                this.progress == 100 &&
                this.fileRes
                && this.transitionEnded
            )
                this.$emit('uploaded', this.fileRes)
        }
    }
}
</script>

<style>
    .file-loader__cont {
        width: 100%;
        height: 15px;
        border-bottom: 1px solid #23272B;
    }
    .file-loader__bar{
        height: 100%;
        transition: .05s linear;
        background-color: #23272B;
    }

    #postModal .content { position: relative }
    #postModal .file-loader__cont {
      position: absolute;
      top:0; left: 0; right: 0
    }
</style>
