<template>
  <div id="functionsPreview" class="drawer">
      <div class="wrapper">

          <div id="tabz" class="flex">
            <div class="vaHack">
              <h2 class="left">boxes</h2>
            </div>
            <div class="vaHack">
              <h2>markets</h2>
            </div>
            <div class="vaHack">
              <h2 class="active">functions!</h2>
            </div>
            <div class="vaHack">
              <h2 class="right">chatty</h2>
            </div>
          </div>

          <div class="flex">
            <div class="main">
              <span class="infinity">∞</span>
              <div class="functions flex">
                <div class="hm menu vertical">
                  <a class="item active">all</a>
                  <a class="item">design</a>
                  <a class="item">marketing</a>
                  <a class="item disabled soon">atoms</a>
                  <a class="item">code</a>
                  <a class="item">else</a>
                </div>

                <div style="width: 100%">
                  <div class="func flex">
                    <div class="vaHack">
                      typography, as in print
                    </div>
                    <a class="hm button core grin mrgn0">ok. go</a>
                  </div>
                  <div class="func flex">
                    <div class="vaHack">
                      build website
                    </div>
                    <a class="hm button core grin mrgn0">ok. go</a>
                  </div>
                  <div class="func flex">
                    <div class="vaHack">
                      do marketing
                    </div>
                    <a class="hm button core grin mrgn0">ok. go</a>
                  </div>
                  <div class="func flex">
                    <div class="vaHack">
                      typography, as in print
                    </div>
                    <a class="hm button core grin mrgn0">ok. go</a>
                  </div>
                  <div class="func flex">
                    <div class="vaHack">
                      build website
                    </div>
                    <a class="hm button core grin mrgn0">ok. go</a>
                  </div>
                  <div class="func flex">
                    <div class="vaHack">
                      do marketing
                    </div>
                    <a class="hm button core grin mrgn0">ok. go</a>
                  </div>
                  <div class="func flex">
                    <div class="vaHack">
                      typography, as in print
                    </div>
                    <a class="hm button core grin mrgn0">ok. go</a>
                  </div>
                  <div class="func flex">
                    <div class="vaHack">
                      build website
                    </div>
                    <a class="hm button core grin mrgn0">ok. go</a>
                  </div>
                  <div class="func flex">
                    <div class="vaHack">
                      do marketing
                    </div>
                    <a class="hm button core grin mrgn0">ok. go</a>
                  </div>
                  <div class="func flex">
                    <div class="vaHack">
                      typography, as in print
                    </div>
                    <a class="hm button core grin mrgn0">ok. go</a>
                  </div>
                  <div class="func flex">
                    <div class="vaHack">
                      build website
                    </div>
                    <a class="hm button core grin mrgn0">ok. go</a>
                  </div>
                  <div class="func flex">
                    <div class="vaHack">
                      do marketing
                    </div>
                    <a class="hm button core grin mrgn0">ok. go</a>
                  </div>
                  <div class="func flex">
                    <div class="vaHack">
                      typography, as in print
                    </div>
                    <a class="hm button core grin mrgn0">ok. go</a>
                  </div>
                  <div class="func flex">
                    <div class="vaHack">
                      build website
                    </div>
                    <a class="hm button core grin mrgn0">ok. go</a>
                  </div>
                  <div class="func flex">
                    <div class="vaHack">
                      do marketing
                    </div>
                    <a class="hm button core grin mrgn0">ok. go</a>
                  </div>
                </div>
              </div>
            </div>

          <!--   <div id="bbExplainer">
              <h4>how is it different from Black Boxes tho?</h4>

                  <p>Black Box is also a function of sorts,
                  but </p>

                  <ul>
                    <li>it has narrowly defined scope</li>
                    <li>it's a process, rather than a project</li>
                    <li>it yields sort of direct relation w/ supplier </li>
                  </ul>

                  <h4>ok, so</h4>

                  <p>Functions are a much higher level abstraction.</p>
                  <p>It's sort of conversational UI; one step closer to headless UI</p>

            </div> bb explainer ends -->
          </div>

      </div>
    </div>

</template>
<script>

export default {
  name: "functions",

}

</script>

<style scoped>

</style>
