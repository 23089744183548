<template>
  <div
    id="howtoModal"
    class="hm modal awhat active"
  >
    <div class="content"
      v-on-clickaway="closeModals"
      >
      <h2 class="title">how to play</h2>
      <div class="tabs">
        <input
          type="radio"
          name="howtoTabs"
          id="tabone1"
          checked="hotkeys"
          value="media buying"
          class="tabHackInput"
        />
        <label for="tabone1" class="tabHackLabel">hotkeys</label>
        <div class="tab">
          <p class="crimson">press H &amp; '-' to zoom out to canvas</p>

          <div id="hotkeys">
            <div class="hotkey flex">
              <div class="flex start">
                <div class="key">A</div>
                <div class="vaHack">
                  <div class="what">is for <b>A</b>genda</div>
                </div>
              </div>

              <div class="vaHack">
                <div class="memo">
                  tickets queue
                </div>
              </div>
            </div>
            <div class="hotkey flex">
              <div class="flex start">
                <div class="key">B</div>
                <div class="vaHack">
                  <div class="what"><b>B</b>roker</div>
                </div>
              </div>

              <div class="vaHack">
                <div class="memo">
                  quick buy
                </div>
              </div>
            </div>
            <div class="hotkey flex">
              <div class="flex start">
                <div class="key">C</div>
                <div class="vaHack">
                  <div class="what"><b>C</b>onnections</div>
                </div>
              </div>

              <div class="vaHack">
                <div class="memo">
                  sort of address book
                </div>
              </div>
            </div>
            <div class="hotkey flex">
              <div class="flex start">
                <div class="key">D</div>
                <div class="vaHack">
                  <div class="what"><b>D</b>irectory</div>
                </div>
              </div>

              <div class="vaHack">
                <div class="memo">
                  quick search & lists
                </div>
              </div>
            </div>
            <div class="hotkey flex">
              <div class="flex start">
                <div class="key special">F</div>
                <div class="vaHack">
                  <div class="what"><b>Full</b> screen mode</div>
                </div>
              </div>

              <div class="vaHack">
                <div class="memo" style="font-family: 'Rubik Mono One'">
                  press 'F' <br />
                  to pay respects
                </div>
              </div>
            </div>
            <div class="hotkey flex">
              <div class="flex start">
                <div class="key">I</div>
                <div class="vaHack">
                  <div class="what"><b>I</b>dentity</div>
                </div>
              </div>

              <div class="vaHack">
                <div class="memo">
                  everything about you
                </div>
              </div>
            </div>
            <div class="hotkey flex">
              <div class="flex start">
                <div class="key">L</div>
                <div class="vaHack">
                  <div class="what"><b>L</b>ayers -&gt; Workspaces</div>
                </div>
              </div>

              <div class="vaHack">
                <div class="memo">
                  Workspaces + Talent + Time
                </div>
              </div>
            </div>
            <div class="hotkey flex">
              <div class="flex start">
                <div class="key">M</div>
                <div class="vaHack">
                  <div class="what"><b>M</b>oneta</div>
                </div>
              </div>

              <div class="vaHack">
                <div class="memo">
                  coin mastering
                </div>
              </div>
            </div>
            <div class="hotkey flex">
              <div class="flex start">
                <div class="key">P</div>
                <div class="vaHack">
                  <div class="what"><b>P</b>urpose</div>
                </div>
              </div>

              <div class="vaHack">
                <div class="memo">
                  <span class="grin">do you play?</span>
                </div>
              </div>
            </div>
            <div class="hotkey flex">
              <div class="flex start">
                <div class="key">R</div>
                <div class="vaHack">
                  <div class="what"><b>R</b>elations</div>
                </div>
              </div>

              <div class="vaHack">
                <div class="memo">
                  Offers + Commitments + Invoices
                </div>
              </div>
            </div>
            <div class="hotkey flex">
              <div class="flex start">
                <div class="key special">T</div>
                <div class="vaHack">
                  <div class="what">Starts &amp; stops <b>T</b>ime tracker</div>
                </div>
              </div>

              <div class="vaHack">
                <div class="memo">
                  btw, when it's ON – notifications are disabled for deep,
                  uninterrupted work
                </div>
              </div>
            </div>
            <div class="hotkey flex">
              <div class="flex start">
                <div class="key">V</div>
                <div class="vaHack">
                  <div class="what">Faith <b>V</b>C</div>
                </div>
              </div>

              <div class="vaHack">
                <div class="memo">
                  hey, do you happen to have an invite? :(
                </div>
              </div>
            </div>
            <div class="hotkey flex">
              <div class="flex start">
                <div class="vaHack">
                  <div class="key">ESC</div>
                </div>
                <div class="vaHack">
                  <div class="what">
                    to close modals
                  </div>
                </div>
              </div>

              <div class="vaHack">
                <div class="memo" style="width: 420px">
                  <span class="crimson">CAUTION:</span> <br />
                  it exits full-screen mode * crying * <br />
                  and there's nothing we can do untill we get our own browser :D
                </div>
              </div>
            </div>
            <div class="hotkey flex">
              <div class="flex start">
                <div class="key special">0-9</div>
                <div class="vaHack">
                  <div class="what">
                    for Mercury Communication nodes
                  </div>
                </div>
              </div>

              <div class="vaHack">
                <div class="memo">
                  i.e. all sorts of messaging stuff at sidebar
                </div>
              </div>
            </div>

            <p class="muted">There's also node specific hotkeys.</p>
            <p class="muted">e.g. 'D' to delete threads, etc</p>
          </div>
        </div>

        <input
          type="radio"
          name="howtoTabs"
          id="tabtwo2"
          value="support"
          class="tabHackInput"
        />
        <label for="tabtwo2" class="tabHackLabel">support</label>
        <div class="tab">
          <div class="ui grid">
            <div class="eight wide column">
              <h2>SLA</h2>
              <p>
                Core accounts: <br /><br />
                reasonable reply time (12-36 hours), <br />
                prioritised bug fixing &amp; appreciated feature requests
              </p>

              <p>
                Classic &amp; Gold: <br /><br />
                near-instant replies (~1.6 mins avg.), <br />
                highly prioritised bug fixing & feature requests
              </p>
            </div>
            <div class="eight wide column">
              <h2>how</h2>
              Ray @grintender
            </div>
          </div>
        </div>

        <input
          type="radio"
          name="howtoTabs"
          id="tabthree3"
          value="rules"
          class="tabHackInput"
        />
        <label for="tabthree3" class="tabHackLabel">rules</label>
        <div class="tab">
          <div class="ui grid">
            <div class="ten wide column">
              <p>
                &gt; We don't play Gestapo here chasing people, but if you cheat
                the system or anyone – at the very minimum it's a permanent ban
                w/o funds withdrawal, content export, etc.
              </p>

              <p>
                &gt; we reserve a right to suspend accounts without identity
                verification
              </p>

              <p>
                &gt; SLAs and support is guaranteed only to Classic, Coin &amp;
                Gold accounts. We're nice &amp; responsive to anyone, but reseve
                a right to ignore crazy and cheap fucks. Just to re-iterate:
                even when you paid for Core Experience – you're using the system
                as-is.
              </p>
            </div>

            <div class="six wide column">
              <p class="muted">
                A boring legacy legal paper version scheduled to be written,
                like, never.
              </p>

              <p class="mited mrgn0">Stay tuned &amp; curios.</p>
            </div>
          </div>
        </div>

        <input
          type="radio"
          name="howtoTabs"
          id="privacy"
          value="support"
          class="tabHackInput"
        />
        <label for="privacy" class="tabHackLabel">privacy</label>
        <div class="tab">
          <p>
            (1.1) We share your data with no one – <br />
            there's no 3rd party trackers whatsoever.
          </p>

          <p class="muted">
            (1.2) We do have 1st party (i.e. hosted on our own servers)
            trackers: analytics, bug tracking, etc
          </p>

          <p>
            (2.1) We don't access your data for product development or
            debugging, <br />
            UNLESS you give us an explicit permission to do so.
          </p>

          <p class="muted">
            (2.2) We do screen recording of some sessions on unpaid CORE
            accounts for the sake of front-end debugging
          </p>

          <p>
            (3) We're playing with w/ our own ads service, <br />
            BUT we'll never sell the inventory to outsiders &amp; lame drones.
          </p>
        </div>

        <input
          type="radio"
          name="howtoTabs"
          id="policy"
          value="support"
          class="tabHackInput"
        />
        <label for="privacy" class="tabHackLabel">policy</label>
        <div class="tab">
          <p>
            By using the system you acknoweldge that we have VERY subjective
            opinions on good vs. bad
          </p>

          <p>
            To make it even worse: they are not explicitly written, coz keeping
            a nice
            <a class="word" href="/odyssey/about/walled-garden"
              >walled garden</a
            >
            is more important over arguments with strangers.
          </p>

          <p>Some examples:</p>

          <ul>
            <li>No politics on the outsiders facing nodes</li>
            <li>No gurus</li>
            <li>No wokism</li>
            <li>No investing advice</li>
            <li>No info products</li>
          </ul>
        </div>
      </div>
    </div>
  </div></template
>

<script>
export default {
  name: 'howToPlayModal',
  methods: {
    closeModals() {
      this.$root.$emit('close-modals');
    },
  },
};
</script>
