<template>
  <div>
    <tips-onetime :buttons="buttonsOnetime" :billing-item="billingItem" />
    <tips-recurrent :buttons="buttonsRecurrent" :billing-item="billingItem" />
  </div>
</template>

<script>
import tipsOnetime from './tipsOnetime';
import tipsRecurrent from './tipsRecurrent';
export default {
  name: 'tipsBlock',
  components: {
    tipsOnetime,
    tipsRecurrent,
  },
  data() {
    return {
      billingItem: {
        billingItemType: 'sample word tip',
        billingItemId: 'sample word hashid',
        billingItemDescription: '<strong>Sample description</strong>',
        beneficiaryProfile: {
          beneficiaryId: 'Non existant id',
          beneficiaryDisplayName: 'Noname',
        },
      },
      buttonsOnetime: [
        {
          currency: 'USD',
          currencySign: '$',
          amount: 1,
        },
        {
          currency: 'USD',
          currencySign: '$',
          amount: 5,
        },
        {
          currency: 'DENARIUS',
          currencySign: '𐆖',
          amount: 1,
        },
        {
          currency: 'RUR',
          currencySign: '₽',
          amount: 100,
        },
      ],
      buttonsRecurrent: [
        {
          currency: 'USD',
          currencySign: '$',
          amount: 1,
          isRecurrent: true,
          billingPeriod: 'weekly',
        },
        {
          title: 'pay',
          currency: 'DENARIUS',
          currencySign: '𐆖',
          amount: 1,
          isRecurrent: true,
          billingPeriod: 'weekly',
        },
        {
          title: 'pay',
          currency: 'RUR',
          currencySign: '₽',
          amount: 100,
          isRecurrent: true,
          billingPeriod: 'в неделю',
        },
      ],
    };
  },
};
</script>
