<template>
  <div class="content" v-on-clickaway="closeModals">
      <h2 class="title">shop some phame</h2>


      <div class="tabs">
        <input
            class="tabHackInput"
            type="radio"
            name="tabs"
            id="tabone"
            checked="checked"
            value="media buying"
            v-model="requestCategory"
        />
        <label class="tabHackLabel" for="tabone">media buying</label>
        <div class="tab">
          <div class="flex">
            <div class="form__radios">
              <div class="form__radio active">
                <label for="mb1">paid social</label>
                <input
                    id="mb1"
                    name="type"
                    type="radio"
                    value="paid social"
                    v-model="requestOptions[requestCategory]"
                    selected
                />
              </div>
              <div class="form__radio">
                <label for="mb2">google ads</label>
                <input
                    id="mb2"
                    name="type"
                    type="radio"
                    value="paid social"
                    v-model="requestOptions[requestCategory]"
                />
              </div>
              <div class="form__radio">
                <label for="mb3">video</label>
                <input
                    id="mb3"
                    name="type"
                    type="radio"
                    value="video"
                    v-model="requestOptions[requestCategory]"
                />
              </div>
              <div class="form__radio disabled">
                <label for="mb4"><i class="clock outline icon"></i> print</label>
                <input
                    id="mb4"
                    name="type"
                    type="radio"
                    disabled="disabled"
                />
              </div>
              <div class="form__radio disabled">
                <label for="mb5"><i class="clock outline icon"></i> TV</label>
                <input
                    id="mb5"
                    name="type"
                    type="radio"
                    disabled="disabled"
                />
              </div>
              <div class="form__radio disabled">
                <label for="mb6"><i class="clock outline icon"></i> radio</label>
                <input
                    id="mb6"
                    name="type"
                    type="radio"
                    disabled="disabled"
                />
              </div>
            </div>

            <div class="form">
              <input
                  type="text"
                  placeholder="title"
                  v-model="messageTitle"
              >
              <textarea
                  name="name"
                  placeholder="details"
                  v-model="messageDescription"
              ></textarea>
              <a
                  class="hm button core mrgn0"
                  @click.prevent="rayPheme"
              >ok. go</a>
            </div>
          </div>
        </div>

        <input
            class="tabHackInput"
            type="radio"
            name="tabs"
            id="tabtwo"
            value="seeding"
            v-model="requestCategory"
        />
        <label class="tabHackLabel" for="tabtwo">seeding</label>
        <div class="tab">
          <div class="flex">

            <div class="form">
              <input
                  type="text"
                  placeholder="title"
                  v-model="messageTitle"
              >
              <textarea
                  name="name"
                  placeholder="details"
                  v-model="messageDescription"
              ></textarea>

              <a
                  class="hm button core mrgn0"
                  style="float: none"
                  @click.prevent="rayPheme"
              >ok. go</a>
            </div>

            <div class="form__radios" style="margin-right:0;margin-left:2rem">
              <div class="form__radio active">
                <label for="seeding1">newsletters</label>
                <input
                    id="seeding1"
                    name="type"
                    type="radio"
                    selected
                    value="newsletters"
                    v-model="requestOptions[requestCategory]"
                />
              </div>
              <div class="form__radio">
                <label for="seeding2">podcasts</label>
                <input
                    id="seeding2"
                    name="type"
                    type="radio"
                    value="podcasts"
                    v-model="requestOptions[requestCategory]"
                />
              </div>
              <div class="form__radio">
                <label for="seeding3">influencers</label>
                <input
                    id="seeding3"
                    name="type"
                    type="radio"
                    value="influencers"
                    v-model="requestOptions[requestCategory]"
                />
              </div>
              <div class="form__radio">
                <label for="seeding4">publishers &amp; blogs</label>
                <input
                    id="seeding4"
                    name="type"
                    type="radio"
                    value="publishers & blogs"
                    v-model="requestOptions[requestCategory]"
                />
              </div>
              <div class="form__radio">
                <label for="seeding5">link building</label>
                <input
                    id="seeding5"
                    name="type"
                    type="radio"
                    value="link building"
                    v-model="requestOptions[requestCategory]"
                />
              </div>
            </div>
          </div>
        </div>

        <input
            class="tabHackInput"
            type="radio"
            name="tabs"
            id="tabthree"
            value="production"
            v-model="requestCategory"
        />
        <label class="tabHackLabel" for="tabthree">production</label>
        <div class="tab">
          <div class="flex">
            <div class="form__radios" style="width: 300px">
              <div class="form__radio active">
                <label for="prod1">brand journalism</label>
                <input
                    id="prod1"
                    name="type"
                    type="radio"
                    selected
                    value="brand journalism"
                    v-model="requestOptions[requestCategory]"
                />
              </div>
              <div class="form__radio">
                <label for="prod2">video</label>
                <input
                    id="prod2"
                    name="type"
                    type="radio"
                    value="video"
                    v-model="requestOptions[requestCategory]"
                />
              </div>
              <div class="form__radio">
                <label for="prod3">photography</label>
                <input
                    id="prod3"
                    name="type"
                    type="radio"
                    value="photography"
                    v-model="requestOptions[requestCategory]"
                />
              </div>
              <div class="form__radio">
                <label for="prod4">illustrations</label>
                <input
                    id="prod4"
                    name="type"
                    type="radio"
                    value="illustrations"
                    v-model="requestOptions[requestCategory]"
                />
              </div>
              <div class="form__radio">
                <label for="prod5">writing</label>
                <input
                    id="prod5"
                    name="type"
                    type="radio"
                    value="writing"
                    v-model="requestOptions[requestCategory]"
                />
              </div>
            </div>

            <div class="form">
              <input
                  type="text"
                  placeholder="title"
                  v-model="messageTitle"
              >
              <textarea
                  name="name"
                  placeholder="details"
                  v-model="messageDescription"
              ></textarea>

              <a
                  class="hm button core grin mrgn0"
                  @click.prevent="rayPheme"
              >ok. go</a>
            </div>
          </div>

        </div>
      </div> <!-- tabs end -->

      <div class="broker__explainer flex start">
        <h2 style="color: gray; font-size:300%;margin-right:2rem">FAQ</h2>
        <div class="">
          <p>pricing...</p>
          <p class="mrgn0">review process...</p>
        </div>
      </div>
  </div> <!-- content ends -->
<div class="content" v-on-clickaway="closeModals">
    <h2 class="title">shop some phame</h2>


    <div class="tabs">
      <input
          class="tabHackInput"
          type="radio"
          name="tabs"
          id="tabone"
          checked="checked"
          value="media buying"
          v-model="requestCategory"
      />
      <label class="tabHackLabel" for="tabone">media buying</label>
      <div class="tab">
        <div class="flex">
          <div class="form__radios">
            <div class="form__radio active">
              <label for="mb1">paid social</label>
              <input
                  id="mb1"
                  name="type"
                  type="radio"
                  value="paid social"
                  v-model="requestOptions[requestCategory]"
                  selected
              />
            </div>
            <div class="form__radio">
              <label for="mb2">google ads</label>
              <input
                  id="mb2"
                  name="type"
                  type="radio"
                  value="paid social"
                  v-model="requestOptions[requestCategory]"
              />
            </div>
            <div class="form__radio">
              <label for="mb3">video</label>
              <input
                  id="mb3"
                  name="type"
                  type="radio"
                  value="video"
                  v-model="requestOptions[requestCategory]"
              />
            </div>
            <div class="form__radio disabled">
              <label for="mb4"><i class="clock outline icon"></i> print</label>
              <input
                  id="mb4"
                  name="type"
                  type="radio"
                  disabled="disabled"
              />
            </div>
            <div class="form__radio disabled">
              <label for="mb5"><i class="clock outline icon"></i> TV</label>
              <input
                  id="mb5"
                  name="type"
                  type="radio"
                  disabled="disabled"
              />
            </div>
            <div class="form__radio disabled">
              <label for="mb6"><i class="clock outline icon"></i> radio</label>
              <input
                  id="mb6"
                  name="type"
                  type="radio"
                  disabled="disabled"
              />
            </div>
          </div>

          <div class="form">
            <input
                type="text"
                placeholder="title"
                v-model="messageTitle"
            >
            <textarea
                name="name"
                placeholder="details"
                v-model="messageDescription"
            ></textarea>
            <a
                class="hm button core mrgn0"
                @click.prevent="rayPheme"
            >ok. go</a>
          </div>
        </div>
      </div>

      <input
          class="tabHackInput"
          type="radio"
          name="tabs"
          id="tabtwo"
          value="seeding"
          v-model="requestCategory"
      />
      <label class="tabHackLabel" for="tabtwo">seeding</label>
      <div class="tab">
        <div class="flex">

          <div class="form">
            <input
                type="text"
                placeholder="title"
                v-model="messageTitle"
            >
            <textarea
                name="name"
                placeholder="details"
                v-model="messageDescription"
            ></textarea>

            <a
                class="hm button core mrgn0"
                style="float: none"
                @click.prevent="rayPheme"
            >ok. go</a>
          </div>

          <div class="form__radios" style="margin-right:0;margin-left:2rem">
            <div class="form__radio active">
              <label for="seeding1">newsletters</label>
              <input
                  id="seeding1"
                  name="type"
                  type="radio"
                  selected
                  value="newsletters"
                  v-model="requestOptions[requestCategory]"
              />
            </div>
            <div class="form__radio">
              <label for="seeding2">podcasts</label>
              <input
                  id="seeding2"
                  name="type"
                  type="radio"
                  value="podcasts"
                  v-model="requestOptions[requestCategory]"
              />
            </div>
            <div class="form__radio">
              <label for="seeding3">influencers</label>
              <input
                  id="seeding3"
                  name="type"
                  type="radio"
                  value="influencers"
                  v-model="requestOptions[requestCategory]"
              />
            </div>
            <div class="form__radio">
              <label for="seeding4">publishers &amp; blogs</label>
              <input
                  id="seeding4"
                  name="type"
                  type="radio"
                  value="publishers & blogs"
                  v-model="requestOptions[requestCategory]"
              />
            </div>
            <div class="form__radio">
              <label for="seeding5">link building</label>
              <input
                  id="seeding5"
                  name="type"
                  type="radio"
                  value="link building"
                  v-model="requestOptions[requestCategory]"
              />
            </div>
          </div>
        </div>
      </div>

      <input
          class="tabHackInput"
          type="radio"
          name="tabs"
          id="tabthree"
          value="production"
          v-model="requestCategory"
      />
      <label class="tabHackLabel" for="tabthree">production</label>
      <div class="tab">
        <div class="flex">
          <div class="form__radios" style="width: 300px">
            <div class="form__radio active">
              <label for="prod1">brand journalism</label>
              <input
                  id="prod1"
                  name="type"
                  type="radio"
                  selected
                  value="brand journalism"
                  v-model="requestOptions[requestCategory]"
              />
            </div>
            <div class="form__radio">
              <label for="prod2">video</label>
              <input
                  id="prod2"
                  name="type"
                  type="radio"
                  value="video"
                  v-model="requestOptions[requestCategory]"
              />
            </div>
            <div class="form__radio">
              <label for="prod3">photography</label>
              <input
                  id="prod3"
                  name="type"
                  type="radio"
                  value="photography"
                  v-model="requestOptions[requestCategory]"
              />
            </div>
            <div class="form__radio">
              <label for="prod4">illustrations</label>
              <input
                  id="prod4"
                  name="type"
                  type="radio"
                  value="illustrations"
                  v-model="requestOptions[requestCategory]"
              />
            </div>
            <div class="form__radio">
              <label for="prod5">writing</label>
              <input
                  id="prod5"
                  name="type"
                  type="radio"
                  value="writing"
                  v-model="requestOptions[requestCategory]"
              />
            </div>
          </div>

          <div class="form">
            <input
                type="text"
                placeholder="title"
                v-model="messageTitle"
            >
            <textarea
                name="name"
                placeholder="details"
                v-model="messageDescription"
            ></textarea>

            <a
                class="hm button core grin mrgn0"
                @click.prevent="rayPheme"
            >ok. go</a>
          </div>
        </div>

      </div>
    </div> <!-- tabs end -->

    <div class="broker__explainer flex start">
      <h2 style="color: gray; font-size:300%;margin-right:2rem">FAQ</h2>
      <div class="">
        <p>pricing...</p>
        <p class="mrgn0">review process...</p>
      </div>
    </div>
</div> <!-- content ends -->

</template>
<script>
import Vue from 'vue';
import Loading from 'vue-loading-overlay';

Vue.use(Loading, {zIndex: 9999});

export default {
  name: 'phemeBuyModal',
  data() {
    return {
      requestCategory: 'media buying',
      requestOptions: {
        'media buying': 'paid social',
        seeding: 'newsletters',
        production: 'brand journalism',
      },
      messageTitle: '',
      messageDescription: '',
      phemeId: '0NqpLl2',
    };
  },
  computed: {
    apiUrl: function() {
      var url = window.apiRoot + '/api/rays/direct/send/';
      return url;
    },
  },
  methods: {
    closeModals() {
      this.$root.$emit('close-modals');
    },
    rayPheme() {
      if (this.messageTitle == '') {
        return;
      }
      this.loader = this.$loading.show();
      var data = {
        user_to: this.phemeId,
        subject: this.messageTitle + ' @broker',
        body:
          `<b>${this.requestCategory} &gt; ` +
          `${this.requestOptions[this.requestCategory]}` +
          ` </b><br/> ${this.messageDescription}`,
      };
      this.$http
        .post(this.apiUrl, data)
        .then(() => {
          this.closeModals();
          this.loader.hide();
        })
        .catch(error => {
          console.error('Rays api is unavailable at the moment', error);
          this.closeModals();
          this.loader.hide();
        });
    },
  },
};
</script>
